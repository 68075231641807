/**
 * Custom variables followed by theme variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */
.LandingPage {
  /*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
}
.LandingPage :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #869ab8;
  --gray-dark: #384c74;
  --primary: #335eea;
  --secondary: #506690;
  --success: #42ba96;
  --info: #7c69ef;
  --warning: #fad776;
  --danger: #df4759;
  --light: #f9fbfd;
  --dark: #1b2a4e;
  --primary-desat: #6c8aec;
  --black: #161c2d;
  --white: #ffffff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
.LandingPage *,
.LandingPage *::before,
.LandingPage *::after {
  box-sizing: border-box;
}
.LandingPage .html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(22, 28, 45, 0);
}
.LandingPage article,
.LandingPage aside,
.LandingPage figcaption,
.LandingPage figure,
.LandingPage footer,
.LandingPage header,
.LandingPage hgroup,
.LandingPage main,
.LandingPage nav,
.LandingPage section {
  display: block;
}
.LandingPage .body {
  margin: 0;
  font-family: 'HKGroteskPro', serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
  background-color: #ffffff;
}
.LandingPage [tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
.LandingPage hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.LandingPage h1,
.LandingPage h2,
.LandingPage h3,
.LandingPage h4,
.LandingPage h5,
.LandingPage h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.LandingPage p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.LandingPage abbr[title],
.LandingPage abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
.LandingPage address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.LandingPage ol,
.LandingPage ul,
.LandingPage dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.LandingPage ol ol,
.LandingPage ul ul,
.LandingPage ol ul,
.LandingPage ul ol {
  margin-bottom: 0;
}
.LandingPage dt {
  font-weight: 600;
}
.LandingPage dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.LandingPage blockquote {
  margin: 0 0 1rem;
}
.LandingPage b,
.LandingPage strong {
  font-weight: bolder;
}
.LandingPage small {
  font-size: 80%;
}
.LandingPage sub,
.LandingPage sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.LandingPage sub {
  bottom: -0.25em;
}
.LandingPage sup {
  top: -0.5em;
}
.LandingPage a {
  color: #335eea;
  text-decoration: none;
  background-color: transparent;
}
.LandingPage a:hover {
  color: #133bbd;
  text-decoration: underline;
}
.LandingPage a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
.LandingPage a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.LandingPage pre,
.LandingPage code,
.LandingPage kbd,
.LandingPage samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}
.LandingPage pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
.LandingPage figure {
  margin: 0 0 1rem;
}
.LandingPage img {
  vertical-align: middle;
  border-style: none;
}
.LandingPage svg {
  overflow: hidden;
  vertical-align: middle;
}
.LandingPage table {
  border-collapse: collapse;
}
.LandingPage caption {
  padding-top: 1.5rem 2rem;
  padding-bottom: 1.5rem 2rem;
  color: #869ab8;
  text-align: left;
  caption-side: bottom;
}
.LandingPage th {
  text-align: inherit;
}
.LandingPage label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.LandingPage button {
  border-radius: 0;
}
.LandingPage button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.LandingPage input,
.LandingPage button,
.LandingPage select,
.LandingPage optgroup,
.LandingPage textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.LandingPage button,
.LandingPage input {
  overflow: visible;
}
.LandingPage button,
.LandingPage select {
  text-transform: none;
}
.LandingPage [role='button'] {
  cursor: pointer;
}
.LandingPage select {
  word-wrap: normal;
}
.LandingPage button,
.LandingPage [type='button'],
.LandingPage [type='reset'],
.LandingPage [type='submit'] {
  -webkit-appearance: button;
}
.LandingPage button:not(:disabled),
.LandingPage [type='button']:not(:disabled),
.LandingPage [type='reset']:not(:disabled),
.LandingPage [type='submit']:not(:disabled) {
  cursor: pointer;
}
.LandingPage button::-moz-focus-inner,
.LandingPage [type='button']::-moz-focus-inner,
.LandingPage [type='reset']::-moz-focus-inner,
.LandingPage [type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.LandingPage input[type='radio'],
.LandingPage input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
.LandingPage textarea {
  overflow: auto;
  resize: vertical;
}
.LandingPage fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.LandingPage legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.LandingPage progress {
  vertical-align: baseline;
}
.LandingPage [type='number']::-webkit-inner-spin-button,
.LandingPage [type='number']::-webkit-outer-spin-button {
  height: auto;
}
.LandingPage [type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
.LandingPage [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
.LandingPage ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.LandingPage output {
  display: inline-block;
}
.LandingPage summary {
  display: list-item;
  cursor: pointer;
}
.LandingPage template {
  display: none;
}
.LandingPage [hidden] {
  display: none !important;
}
.LandingPage h1,
.LandingPage h2,
.LandingPage h3,
.LandingPage h4,
.LandingPage h5,
.LandingPage h6,
.LandingPage .h1,
.LandingPage .h2,
.LandingPage .h3,
.LandingPage .h4,
.LandingPage .h5,
.LandingPage .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1;
}
.LandingPage h1,
.LandingPage .h1 {
  font-size: 2.3125rem;
}
.LandingPage h2,
.LandingPage .h2 {
  font-size: 1.9375rem;
}
.LandingPage h3,
.LandingPage .h3 {
  font-size: 1.3125rem;
}
.LandingPage h4,
.LandingPage .h4 {
  font-size: 1.1875rem;
}
.LandingPage h5,
.LandingPage .h5 {
  font-size: 1.0625rem;
}
.LandingPage h6,
.LandingPage .h6 {
  font-size: 0.75rem;
}
.LandingPage .lead {
  font-size: 1.3125rem;
  font-weight: 400;
}
.LandingPage .display-1 {
  font-size: 4.375rem;
  font-weight: 400;
  line-height: 1;
}
.LandingPage .display-2 {
  font-size: 3.8125rem;
  font-weight: 400;
  line-height: 1;
}
.LandingPage .display-3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1;
}
.LandingPage .display-4 {
  font-size: 2.6875rem;
  font-weight: 400;
  line-height: 1;
}
.LandingPage hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #f1f4f8;
}
.LandingPage small,
.LandingPage .small {
  font-size: 88.2%;
  font-weight: 400;
}
.LandingPage mark,
.LandingPage .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.LandingPage .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.LandingPage .list-inline {
  padding-left: 0;
  list-style: none;
}
.LandingPage .list-inline-item {
  display: inline-block;
}
.LandingPage .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.LandingPage .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.LandingPage .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.LandingPage .blockquote-footer {
  display: block;
  font-size: 0.75rem;
  color: #869ab8;
}
.LandingPage .blockquote-footer::before {
  content: '\2014\00A0';
}
.LandingPage .img-fluid {
  max-width: 100%;
  height: auto;
}
.LandingPage .img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #d9e2ef;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(22, 28, 45, 0.075);
  max-width: 100%;
  height: auto;
}
.LandingPage .figure {
  display: inline-block;
}
.LandingPage .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.LandingPage .figure-caption {
  font-size: 88.2%;
  color: #869ab8;
}
.LandingPage code {
  font-size: 0.8125rem;
  color: #e83e8c;
  word-wrap: break-word;
}
a > .LandingPage code {
  color: inherit;
}
.LandingPage kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.8125rem;
  color: #ffffff;
  background-color: #1b2a4e;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(22, 28, 45, 0.25);
}
.LandingPage kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
  box-shadow: none;
}
.LandingPage pre {
  display: block;
  font-size: 0.8125rem;
  color: #1b2a4e;
}
.LandingPage pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.LandingPage .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.LandingPage .container,
.LandingPage .container-fluid,
.LandingPage .container-sm,
.LandingPage .container-md,
.LandingPage .container-lg,
.LandingPage .container-xl {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .LandingPage .container,
  .LandingPage .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .LandingPage .container,
  .LandingPage .container-sm,
  .LandingPage .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .LandingPage .container,
  .LandingPage .container-sm,
  .LandingPage .container-md,
  .LandingPage .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .LandingPage .container,
  .LandingPage .container-sm,
  .LandingPage .container-md,
  .LandingPage .container-lg,
  .LandingPage .container-xl {
    max-width: 1040px;
  }
}
.LandingPage .row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}
.LandingPage .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.LandingPage .no-gutters > .col,
.LandingPage .no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.LandingPage .col-1,
.LandingPage .col-2,
.LandingPage .col-3,
.LandingPage .col-4,
.LandingPage .col-5,
.LandingPage .col-6,
.LandingPage .col-7,
.LandingPage .col-8,
.LandingPage .col-9,
.LandingPage .col-10,
.LandingPage .col-11,
.LandingPage .col-12,
.LandingPage .col,
.LandingPage .col-auto,
.LandingPage .col-sm-1,
.LandingPage .col-sm-2,
.LandingPage .col-sm-3,
.LandingPage .col-sm-4,
.LandingPage .col-sm-5,
.LandingPage .col-sm-6,
.LandingPage .col-sm-7,
.LandingPage .col-sm-8,
.LandingPage .col-sm-9,
.LandingPage .col-sm-10,
.LandingPage .col-sm-11,
.LandingPage .col-sm-12,
.LandingPage .col-sm,
.LandingPage .col-sm-auto,
.LandingPage .col-md-1,
.LandingPage .col-md-2,
.LandingPage .col-md-3,
.LandingPage .col-md-4,
.LandingPage .col-md-5,
.LandingPage .col-md-6,
.LandingPage .col-md-7,
.LandingPage .col-md-8,
.LandingPage .col-md-9,
.LandingPage .col-md-10,
.LandingPage .col-md-11,
.LandingPage .col-md-12,
.LandingPage .col-md,
.LandingPage .col-md-auto,
.LandingPage .col-lg-1,
.LandingPage .col-lg-2,
.LandingPage .col-lg-3,
.LandingPage .col-lg-4,
.LandingPage .col-lg-5,
.LandingPage .col-lg-6,
.LandingPage .col-lg-7,
.LandingPage .col-lg-8,
.LandingPage .col-lg-9,
.LandingPage .col-lg-10,
.LandingPage .col-lg-11,
.LandingPage .col-lg-12,
.LandingPage .col-lg,
.LandingPage .col-lg-auto,
.LandingPage .col-xl-1,
.LandingPage .col-xl-2,
.LandingPage .col-xl-3,
.LandingPage .col-xl-4,
.LandingPage .col-xl-5,
.LandingPage .col-xl-6,
.LandingPage .col-xl-7,
.LandingPage .col-xl-8,
.LandingPage .col-xl-9,
.LandingPage .col-xl-10,
.LandingPage .col-xl-11,
.LandingPage .col-xl-12,
.LandingPage .col-xl,
.LandingPage .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
.LandingPage .col {
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
}
.LandingPage .row-cols-1 > * {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}
.LandingPage .row-cols-2 > * {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.LandingPage .row-cols-3 > * {
  -webkit-box-flex: 0;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.LandingPage .row-cols-4 > * {
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
}
.LandingPage .row-cols-5 > * {
  -webkit-box-flex: 0;
  flex: 0 0 20%;
  max-width: 20%;
}
.LandingPage .row-cols-6 > * {
  -webkit-box-flex: 0;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.LandingPage .col-auto {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.LandingPage .col-1 {
  -webkit-box-flex: 0;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.LandingPage .col-2 {
  -webkit-box-flex: 0;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.LandingPage .col-3 {
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
}
.LandingPage .col-4 {
  -webkit-box-flex: 0;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.LandingPage .col-5 {
  -webkit-box-flex: 0;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.LandingPage .col-6 {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.LandingPage .col-7 {
  -webkit-box-flex: 0;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.LandingPage .col-8 {
  -webkit-box-flex: 0;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.LandingPage .col-9 {
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
}
.LandingPage .col-10 {
  -webkit-box-flex: 0;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.LandingPage .col-11 {
  -webkit-box-flex: 0;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.LandingPage .col-12 {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}
.LandingPage .order-first {
  -webkit-box-ordinal-group: 0;
  order: -1;
}
.LandingPage .order-last {
  -webkit-box-ordinal-group: 14;
  order: 13;
}
.LandingPage .order-0 {
  -webkit-box-ordinal-group: 1;
  order: 0;
}
.LandingPage .order-1 {
  -webkit-box-ordinal-group: 2;
  order: 1;
}
.LandingPage .order-2 {
  -webkit-box-ordinal-group: 3;
  order: 2;
}
.LandingPage .order-3 {
  -webkit-box-ordinal-group: 4;
  order: 3;
}
.LandingPage .order-4 {
  -webkit-box-ordinal-group: 5;
  order: 4;
}
.LandingPage .order-5 {
  -webkit-box-ordinal-group: 6;
  order: 5;
}
.LandingPage .order-6 {
  -webkit-box-ordinal-group: 7;
  order: 6;
}
.LandingPage .order-7 {
  -webkit-box-ordinal-group: 8;
  order: 7;
}
.LandingPage .order-8 {
  -webkit-box-ordinal-group: 9;
  order: 8;
}
.LandingPage .order-9 {
  -webkit-box-ordinal-group: 10;
  order: 9;
}
.LandingPage .order-10 {
  -webkit-box-ordinal-group: 11;
  order: 10;
}
.LandingPage .order-11 {
  -webkit-box-ordinal-group: 12;
  order: 11;
}
.LandingPage .order-12 {
  -webkit-box-ordinal-group: 13;
  order: 12;
}
.LandingPage .offset-1 {
  margin-left: 8.33333%;
}
.LandingPage .offset-2 {
  margin-left: 16.66667%;
}
.LandingPage .offset-3 {
  margin-left: 25%;
}
.LandingPage .offset-4 {
  margin-left: 33.33333%;
}
.LandingPage .offset-5 {
  margin-left: 41.66667%;
}
.LandingPage .offset-6 {
  margin-left: 50%;
}
.LandingPage .offset-7 {
  margin-left: 58.33333%;
}
.LandingPage .offset-8 {
  margin-left: 66.66667%;
}
.LandingPage .offset-9 {
  margin-left: 75%;
}
.LandingPage .offset-10 {
  margin-left: 83.33333%;
}
.LandingPage .offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .LandingPage .col-sm {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .LandingPage .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .LandingPage .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-sm-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .LandingPage .col-sm-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .LandingPage .col-sm-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-sm-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .col-sm-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .col-sm-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .LandingPage .col-sm-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .col-sm-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .LandingPage .col-sm-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .LandingPage .col-sm-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .LandingPage .col-sm-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .LandingPage .col-sm-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .LandingPage .col-sm-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .order-sm-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .LandingPage .order-sm-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .LandingPage .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .LandingPage .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .LandingPage .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .LandingPage .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .LandingPage .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .LandingPage .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .LandingPage .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .LandingPage .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .LandingPage .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .LandingPage .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .LandingPage .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .LandingPage .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .LandingPage .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
  .LandingPage .offset-sm-0 {
    margin-left: 0;
  }
  .LandingPage .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .LandingPage .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .LandingPage .offset-sm-3 {
    margin-left: 25%;
  }
  .LandingPage .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .LandingPage .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .LandingPage .offset-sm-6 {
    margin-left: 50%;
  }
  .LandingPage .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .LandingPage .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .LandingPage .offset-sm-9 {
    margin-left: 75%;
  }
  .LandingPage .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .LandingPage .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .LandingPage .col-md {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .LandingPage .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .LandingPage .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-md-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .LandingPage .col-md-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .LandingPage .col-md-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-md-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .col-md-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .col-md-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .LandingPage .col-md-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .col-md-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .LandingPage .col-md-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .LandingPage .col-md-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .LandingPage .col-md-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .LandingPage .col-md-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .LandingPage .col-md-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .order-md-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .LandingPage .order-md-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .LandingPage .order-md-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .LandingPage .order-md-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .LandingPage .order-md-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .LandingPage .order-md-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .LandingPage .order-md-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .LandingPage .order-md-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .LandingPage .order-md-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .LandingPage .order-md-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .LandingPage .order-md-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .LandingPage .order-md-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .LandingPage .order-md-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .LandingPage .order-md-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .LandingPage .order-md-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
  .LandingPage .offset-md-0 {
    margin-left: 0;
  }
  .LandingPage .offset-md-1 {
    margin-left: 8.33333%;
  }
  .LandingPage .offset-md-2 {
    margin-left: 16.66667%;
  }
  .LandingPage .offset-md-3 {
    margin-left: 25%;
  }
  .LandingPage .offset-md-4 {
    margin-left: 33.33333%;
  }
  .LandingPage .offset-md-5 {
    margin-left: 41.66667%;
  }
  .LandingPage .offset-md-6 {
    margin-left: 50%;
  }
  .LandingPage .offset-md-7 {
    margin-left: 58.33333%;
  }
  .LandingPage .offset-md-8 {
    margin-left: 66.66667%;
  }
  .LandingPage .offset-md-9 {
    margin-left: 75%;
  }
  .LandingPage .offset-md-10 {
    margin-left: 83.33333%;
  }
  .LandingPage .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 992px) {
  .LandingPage .col-lg {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .LandingPage .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .LandingPage .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-lg-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .LandingPage .col-lg-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .LandingPage .col-lg-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-lg-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .col-lg-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .col-lg-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .LandingPage .col-lg-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .col-lg-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .LandingPage .col-lg-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .LandingPage .col-lg-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .LandingPage .col-lg-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .LandingPage .col-lg-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .LandingPage .col-lg-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .order-lg-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .LandingPage .order-lg-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .LandingPage .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .LandingPage .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .LandingPage .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .LandingPage .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .LandingPage .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .LandingPage .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .LandingPage .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .LandingPage .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .LandingPage .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .LandingPage .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .LandingPage .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .LandingPage .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .LandingPage .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
  .LandingPage .offset-lg-0 {
    margin-left: 0;
  }
  .LandingPage .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .LandingPage .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .LandingPage .offset-lg-3 {
    margin-left: 25%;
  }
  .LandingPage .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .LandingPage .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .LandingPage .offset-lg-6 {
    margin-left: 50%;
  }
  .LandingPage .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .LandingPage .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .LandingPage .offset-lg-9 {
    margin-left: 75%;
  }
  .LandingPage .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .LandingPage .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .LandingPage .col-xl {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .LandingPage .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .LandingPage .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-xl-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .LandingPage .col-xl-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .LandingPage .col-xl-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .LandingPage .col-xl-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .LandingPage .col-xl-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .LandingPage .col-xl-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .LandingPage .col-xl-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .LandingPage .col-xl-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .LandingPage .col-xl-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .LandingPage .col-xl-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .LandingPage .col-xl-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .LandingPage .col-xl-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .LandingPage .col-xl-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LandingPage .order-xl-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .LandingPage .order-xl-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  .LandingPage .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  .LandingPage .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .LandingPage .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .LandingPage .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  .LandingPage .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  .LandingPage .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  .LandingPage .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  .LandingPage .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  .LandingPage .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  .LandingPage .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  .LandingPage .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  .LandingPage .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  .LandingPage .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
  .LandingPage .offset-xl-0 {
    margin-left: 0;
  }
  .LandingPage .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .LandingPage .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .LandingPage .offset-xl-3 {
    margin-left: 25%;
  }
  .LandingPage .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .LandingPage .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .LandingPage .offset-xl-6 {
    margin-left: 50%;
  }
  .LandingPage .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .LandingPage .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .LandingPage .offset-xl-9 {
    margin-left: 75%;
  }
  .LandingPage .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .LandingPage .offset-xl-11 {
    margin-left: 91.66667%;
  }
}
.LandingPage .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #161c2d;
  background-color: #ffffff;
}
.LandingPage .table th,
.LandingPage .table td {
  padding: 1.5rem 2rem;
  vertical-align: top;
  border-top: 1px solid #f1f4f8;
}
.LandingPage .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #f1f4f8;
}
.LandingPage .table tbody + tbody {
  border-top: 2px solid #f1f4f8;
}
.LandingPage .table-sm th,
.LandingPage .table-sm td {
  padding: 0.3rem;
}
.LandingPage .table-bordered {
  border: 1px solid #f1f4f8;
}
.LandingPage .table-bordered th,
.LandingPage .table-bordered td {
  border: 1px solid #f1f4f8;
}
.LandingPage .table-bordered thead th,
.LandingPage .table-bordered thead td {
  border-bottom-width: 2px;
}
.LandingPage .table-borderless th,
.LandingPage .table-borderless td,
.LandingPage .table-borderless thead th,
.LandingPage .table-borderless tbody + tbody {
  border: 0;
}
.LandingPage .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9fbfd;
}
.LandingPage .table-hover tbody tr:hover {
  color: #161c2d;
  background-color: rgba(22, 28, 45, 0.075);
}
.LandingPage .table-primary,
.LandingPage .table-primary > th,
.LandingPage .table-primary > td {
  background-color: #c6d2f9;
}
.LandingPage .table-primary th,
.LandingPage .table-primary td,
.LandingPage .table-primary thead th,
.LandingPage .table-primary tbody + tbody {
  border-color: #95abf4;
}
.LandingPage .table-hover .table-primary:hover {
  background-color: #afc0f7;
}
.LandingPage .table-hover .table-primary:hover > td,
.LandingPage .table-hover .table-primary:hover > th {
  background-color: #afc0f7;
}
.LandingPage .table-secondary,
.LandingPage .table-secondary > th,
.LandingPage .table-secondary > td {
  background-color: #ced4e0;
}
.LandingPage .table-secondary th,
.LandingPage .table-secondary td,
.LandingPage .table-secondary thead th,
.LandingPage .table-secondary tbody + tbody {
  border-color: #a4afc5;
}
.LandingPage .table-hover .table-secondary:hover {
  background-color: #bec6d6;
}
.LandingPage .table-hover .table-secondary:hover > td,
.LandingPage .table-hover .table-secondary:hover > th {
  background-color: #bec6d6;
}
.LandingPage .table-success,
.LandingPage .table-success > th,
.LandingPage .table-success > td {
  background-color: #caece2;
}
.LandingPage .table-success th,
.LandingPage .table-success td,
.LandingPage .table-success thead th,
.LandingPage .table-success tbody + tbody {
  border-color: #9ddbc8;
}
.LandingPage .table-hover .table-success:hover {
  background-color: #b7e5d8;
}
.LandingPage .table-hover .table-success:hover > td,
.LandingPage .table-hover .table-success:hover > th {
  background-color: #b7e5d8;
}
.LandingPage .table-info,
.LandingPage .table-info > th,
.LandingPage .table-info > td {
  background-color: #dad5fb;
}
.LandingPage .table-info th,
.LandingPage .table-info td,
.LandingPage .table-info thead th,
.LandingPage .table-info tbody + tbody {
  border-color: #bbb1f7;
}
.LandingPage .table-hover .table-info:hover {
  background-color: #c5bef9;
}
.LandingPage .table-hover .table-info:hover > td,
.LandingPage .table-hover .table-info:hover > th {
  background-color: #c5bef9;
}
.LandingPage .table-warning,
.LandingPage .table-warning > th,
.LandingPage .table-warning > td {
  background-color: #fef4d9;
}
.LandingPage .table-warning th,
.LandingPage .table-warning td,
.LandingPage .table-warning thead th,
.LandingPage .table-warning tbody + tbody {
  border-color: #fceab8;
}
.LandingPage .table-hover .table-warning:hover {
  background-color: #fdedc0;
}
.LandingPage .table-hover .table-warning:hover > td,
.LandingPage .table-hover .table-warning:hover > th {
  background-color: #fdedc0;
}
.LandingPage .table-danger,
.LandingPage .table-danger > th,
.LandingPage .table-danger > td {
  background-color: #f6cbd1;
}
.LandingPage .table-danger th,
.LandingPage .table-danger td,
.LandingPage .table-danger thead th,
.LandingPage .table-danger tbody + tbody {
  border-color: #ee9fa9;
}
.LandingPage .table-hover .table-danger:hover {
  background-color: #f2b5be;
}
.LandingPage .table-hover .table-danger:hover > td,
.LandingPage .table-hover .table-danger:hover > th {
  background-color: #f2b5be;
}
.LandingPage .table-light,
.LandingPage .table-light > th,
.LandingPage .table-light > td {
  background-color: #fdfefe;
}
.LandingPage .table-light th,
.LandingPage .table-light td,
.LandingPage .table-light thead th,
.LandingPage .table-light tbody + tbody {
  border-color: #fcfdfe;
}
.LandingPage .table-hover .table-light:hover {
  background-color: #ecf6f6;
}
.LandingPage .table-hover .table-light:hover > td,
.LandingPage .table-hover .table-light:hover > th {
  background-color: #ecf6f6;
}
.LandingPage .table-dark,
.LandingPage .table-dark > th,
.LandingPage .table-dark > td {
  background-color: #bfc3cd;
}
.LandingPage .table-dark th,
.LandingPage .table-dark td,
.LandingPage .table-dark thead th,
.LandingPage .table-dark tbody + tbody {
  border-color: #8890a3;
}
.LandingPage .table-hover .table-dark:hover {
  background-color: #b1b6c2;
}
.LandingPage .table-hover .table-dark:hover > td,
.LandingPage .table-hover .table-dark:hover > th {
  background-color: #b1b6c2;
}
.LandingPage .table-primary-desat,
.LandingPage .table-primary-desat > th,
.LandingPage .table-primary-desat > td {
  background-color: #d6defa;
}
.LandingPage .table-primary-desat th,
.LandingPage .table-primary-desat td,
.LandingPage .table-primary-desat thead th,
.LandingPage .table-primary-desat tbody + tbody {
  border-color: #b3c2f5;
}
.LandingPage .table-hover .table-primary-desat:hover {
  background-color: #bfccf7;
}
.LandingPage .table-hover .table-primary-desat:hover > td,
.LandingPage .table-hover .table-primary-desat:hover > th {
  background-color: #bfccf7;
}
.LandingPage .table-black,
.LandingPage .table-black > th,
.LandingPage .table-black > td {
  background-color: #bebfc4;
}
.LandingPage .table-black th,
.LandingPage .table-black td,
.LandingPage .table-black thead th,
.LandingPage .table-black tbody + tbody {
  border-color: #868992;
}
.LandingPage .table-hover .table-black:hover {
  background-color: #b1b2b8;
}
.LandingPage .table-hover .table-black:hover > td,
.LandingPage .table-hover .table-black:hover > th {
  background-color: #b1b2b8;
}
.LandingPage .table-white,
.LandingPage .table-white > th,
.LandingPage .table-white > td {
  background-color: white;
}
.LandingPage .table-white th,
.LandingPage .table-white td,
.LandingPage .table-white thead th,
.LandingPage .table-white tbody + tbody {
  border-color: white;
}
.LandingPage .table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.LandingPage .table-hover .table-white:hover > td,
.LandingPage .table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}
.LandingPage .table-active,
.LandingPage .table-active > th,
.LandingPage .table-active > td {
  background-color: rgba(22, 28, 45, 0.075);
}
.LandingPage .table-hover .table-active:hover {
  background-color: rgba(14, 17, 28, 0.075);
}
.LandingPage .table-hover .table-active:hover > td,
.LandingPage .table-hover .table-active:hover > th {
  background-color: rgba(14, 17, 28, 0.075);
}
.LandingPage .table .thead-dark th {
  color: #ffffff;
  background-color: #384c74;
  border-color: #445d8e;
}
.LandingPage .table .thead-light th {
  color: #161c2d;
  background-color: #ffffff;
  border-color: #f1f4f8;
}
.LandingPage .table-dark {
  color: #ffffff;
  background-color: #384c74;
}
.LandingPage .table-dark th,
.LandingPage .table-dark td,
.LandingPage .table-dark thead th {
  border-color: #445d8e;
}
.LandingPage .table-dark.table-bordered {
  border: 0;
}
.LandingPage .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.LandingPage .table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .LandingPage .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .LandingPage .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .LandingPage .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .LandingPage .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .LandingPage .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .LandingPage .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .LandingPage .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.LandingPage .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.LandingPage .table-responsive > .table-bordered {
  border: 0;
}
.LandingPage .form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.LandingPage .form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #161c2d;
}
.LandingPage .form-control:focus {
  color: #161c2d;
  background-color: #ffffff;
  border-color: #a7b9f6;
  outline: 0;
}
.LandingPage .form-control::-webkit-input-placeholder {
  color: #869ab8;
  opacity: 1;
}
.LandingPage .form-control::-moz-placeholder {
  color: #869ab8;
  opacity: 1;
}
.LandingPage .form-control:-ms-input-placeholder {
  color: #869ab8;
  opacity: 1;
}
.LandingPage .form-control::-ms-input-placeholder {
  color: #869ab8;
  opacity: 1;
}
.LandingPage .form-control::placeholder {
  color: #869ab8;
  opacity: 1;
}
.LandingPage .form-control:disabled,
.LandingPage .form-control[readonly] {
  background-color: #f1f4f8;
  opacity: 1;
}
.LandingPage input[type='date'].form-control,
.LandingPage input[type='time'].form-control,
.LandingPage input[type='datetime-local'].form-control,
.LandingPage input[type='month'].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.LandingPage select.form-control:focus::-ms-value {
  color: #161c2d;
  background-color: #ffffff;
}
.LandingPage .form-control-file,
.LandingPage .form-control-range {
  display: block;
  width: 100%;
}
.LandingPage .col-form-label {
  padding-top: calc(0.8125rem + 1px);
  padding-bottom: calc(0.8125rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}
.LandingPage .col-form-label-lg {
  padding-top: calc(1.122rem + 1px);
  padding-bottom: calc(1.122rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.6;
}
.LandingPage .col-form-label-sm {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.6;
}
.LandingPage .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.8125rem 0;
  margin-bottom: 0;
  font-size: 1.0625rem;
  line-height: 1.6;
  color: #161c2d;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.LandingPage .form-control-plaintext.form-control-sm,
.LandingPage .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.LandingPage .form-control-sm {
  height: calc(1.6em + 1.125rem + 2px);
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
}
.LandingPage .form-control-lg {
  height: calc(1.6em + 2.244rem + 2px);
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
}
.LandingPage select.form-control[size],
.LandingPage select.form-control[multiple] {
  height: auto;
}
.LandingPage textarea.form-control {
  height: auto;
}
.LandingPage .form-group {
  margin-bottom: 1rem;
}
.LandingPage .form-text {
  display: block;
  margin-top: 0.25rem;
}
.LandingPage .form-row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.LandingPage .form-row > .col,
.LandingPage .form-row > [class*='col-'] {
  padding-right: 10px;
  padding-left: 10px;
}
.LandingPage .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.LandingPage .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.LandingPage .form-check-input[disabled] ~ .form-check-label,
.LandingPage .form-check-input:disabled ~ .form-check-label {
  color: #869ab8;
}
.LandingPage .form-check-label {
  margin-bottom: 0;
}
.LandingPage .form-check-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.LandingPage .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.LandingPage .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 88.2%;
  color: #42ba96;
}
.LandingPage .valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #ffffff;
  background-color: rgba(66, 186, 150, 0.9);
  border-radius: 0.375rem;
}
.was-validated .LandingPage:valid ~ .valid-feedback,
.was-validated .LandingPage:valid ~ .valid-tooltip,
.LandingPage.is-valid ~ .valid-feedback,
.LandingPage.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .LandingPage .form-control:valid,
.LandingPage .form-control.is-valid {
  border-color: #42ba96;
}
.was-validated .LandingPage .form-control:valid:focus,
.LandingPage .form-control.is-valid:focus {
  border-color: #42ba96;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}
.was-validated .LandingPage .custom-select:valid,
.LandingPage .custom-select.is-valid {
  border-color: #42ba96;
}
.was-validated .LandingPage .custom-select:valid:focus,
.LandingPage .custom-select.is-valid:focus {
  border-color: #42ba96;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}
.was-validated .LandingPage .form-check-input:valid ~ .form-check-label,
.LandingPage .form-check-input.is-valid ~ .form-check-label {
  color: #42ba96;
}
.was-validated .LandingPage .form-check-input:valid ~ .valid-feedback,
.was-validated .LandingPage .form-check-input:valid ~ .valid-tooltip,
.LandingPage .form-check-input.is-valid ~ .valid-feedback,
.LandingPage .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .LandingPage .custom-control-input:valid ~ .custom-control-label,
.LandingPage .custom-control-input.is-valid ~ .custom-control-label {
  color: #42ba96;
}
.was-validated .LandingPage .custom-control-input:valid ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #42ba96;
}
.was-validated .LandingPage .custom-control-input:valid:checked ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #66c9ab;
  background-color: #66c9ab;
}
.was-validated .LandingPage .custom-control-input:valid:focus ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}
.was-validated .LandingPage .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #42ba96;
}
.was-validated .LandingPage .custom-file-input:valid ~ .custom-file-label,
.LandingPage .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #42ba96;
}
.was-validated .LandingPage .custom-file-input:valid:focus ~ .custom-file-label,
.LandingPage .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #42ba96;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25);
}
.LandingPage .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 88.2%;
  color: #df4759;
}
.LandingPage .invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #ffffff;
  background-color: rgba(223, 71, 89, 0.9);
  border-radius: 0.375rem;
}
.was-validated .LandingPage:invalid ~ .invalid-feedback,
.was-validated .LandingPage:invalid ~ .invalid-tooltip,
.LandingPage.is-invalid ~ .invalid-feedback,
.LandingPage.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .LandingPage .form-control:invalid,
.LandingPage .form-control.is-invalid {
  border-color: #df4759;
}
.was-validated .LandingPage .form-control:invalid:focus,
.LandingPage .form-control.is-invalid:focus {
  border-color: #df4759;
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}
.was-validated .LandingPage .custom-select:invalid,
.LandingPage .custom-select.is-invalid {
  border-color: #df4759;
}
.was-validated .LandingPage .custom-select:invalid:focus,
.LandingPage .custom-select.is-invalid:focus {
  border-color: #df4759;
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}
.was-validated .LandingPage .form-check-input:invalid ~ .form-check-label,
.LandingPage .form-check-input.is-invalid ~ .form-check-label {
  color: #df4759;
}
.was-validated .LandingPage .form-check-input:invalid ~ .invalid-feedback,
.was-validated .LandingPage .form-check-input:invalid ~ .invalid-tooltip,
.LandingPage .form-check-input.is-invalid ~ .invalid-feedback,
.LandingPage .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .LandingPage .custom-control-input:invalid ~ .custom-control-label,
.LandingPage .custom-control-input.is-invalid ~ .custom-control-label {
  color: #df4759;
}
.was-validated .LandingPage .custom-control-input:invalid ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #df4759;
}
.was-validated .LandingPage .custom-control-input:invalid:checked ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e77280;
  background-color: #e77280;
}
.was-validated .LandingPage .custom-control-input:invalid:focus ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}
.was-validated .LandingPage .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.LandingPage .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #df4759;
}
.was-validated .LandingPage .custom-file-input:invalid ~ .custom-file-label,
.LandingPage .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #df4759;
}
.was-validated .LandingPage .custom-file-input:invalid:focus ~ .custom-file-label,
.LandingPage .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #df4759;
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25);
}
.LandingPage .form-inline {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
}
.LandingPage .form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .LandingPage .form-inline label {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .LandingPage .form-inline .form-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .LandingPage .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .LandingPage .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .LandingPage .form-inline .input-group,
  .LandingPage .form-inline .custom-select {
    width: auto;
  }
  .LandingPage .form-inline .form-check {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .LandingPage .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .LandingPage .form-inline .custom-control {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .LandingPage .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.LandingPage .btn {
  display: inline-block;
  font-weight: 600;
  color: #161c2d;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .btn:hover {
  color: #161c2d;
  text-decoration: none;
}
.LandingPage .btn:focus,
.LandingPage .btn.focus {
  outline: 0;
  box-shadow: none;
}
.LandingPage .btn.disabled,
.LandingPage .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.LandingPage .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.LandingPage .btn:not(:disabled):not(.disabled):active,
.LandingPage .btn:not(:disabled):not(.disabled).active {
  box-shadow: none;
}
.LandingPage a.btn.disabled,
.LandingPage fieldset:disabled a.btn {
  pointer-events: none;
}
.LandingPage .btn-primary {
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
  box-shadow: none;
}
.LandingPage .btn-primary:hover {
  color: #ffffff;
  background-color: #1746e0;
  border-color: #1643d4;
}
.LandingPage .btn-primary:focus,
.LandingPage .btn-primary.focus {
  color: #ffffff;
  background-color: #1746e0;
  border-color: #1643d4;
  box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5);
}
.LandingPage .btn-primary.disabled,
.LandingPage .btn-primary:disabled {
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .btn-primary:not(:disabled):not(.disabled):active,
.LandingPage .btn-primary:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #1643d4;
  border-color: #153fc9;
}
.LandingPage .btn-primary:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5);
}
.LandingPage .btn-secondary {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
  box-shadow: none;
}
.LandingPage .btn-secondary:hover {
  color: #ffffff;
  background-color: #425577;
  border-color: #3e4f6f;
}
.LandingPage .btn-secondary:focus,
.LandingPage .btn-secondary.focus {
  color: #ffffff;
  background-color: #425577;
  border-color: #3e4f6f;
  box-shadow: 0 0 0 0 rgba(106, 125, 161, 0.5);
}
.LandingPage .btn-secondary.disabled,
.LandingPage .btn-secondary:disabled {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
}
.LandingPage .btn-secondary:not(:disabled):not(.disabled):active,
.LandingPage .btn-secondary:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #3e4f6f;
  border-color: #394967;
}
.LandingPage .btn-secondary:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(106, 125, 161, 0.5);
}
.LandingPage .btn-success {
  color: #ffffff;
  background-color: #42ba96;
  border-color: #42ba96;
  box-shadow: none;
}
.LandingPage .btn-success:hover {
  color: #ffffff;
  background-color: #389e7f;
  border-color: #359478;
}
.LandingPage .btn-success:focus,
.LandingPage .btn-success.focus {
  color: #ffffff;
  background-color: #389e7f;
  border-color: #359478;
  box-shadow: 0 0 0 0 rgba(94, 196, 166, 0.5);
}
.LandingPage .btn-success.disabled,
.LandingPage .btn-success:disabled {
  color: #ffffff;
  background-color: #42ba96;
  border-color: #42ba96;
}
.LandingPage .btn-success:not(:disabled):not(.disabled):active,
.LandingPage .btn-success:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #359478;
  border-color: #318b70;
}
.LandingPage .btn-success:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(94, 196, 166, 0.5);
}
.LandingPage .btn-info {
  color: #ffffff;
  background-color: #7c69ef;
  border-color: #7c69ef;
  box-shadow: none;
}
.LandingPage .btn-info:hover {
  color: #ffffff;
  background-color: #5e46eb;
  border-color: #543bea;
}
.LandingPage .btn-info:focus,
.LandingPage .btn-info.focus {
  color: #ffffff;
  background-color: #5e46eb;
  border-color: #543bea;
  box-shadow: 0 0 0 0 rgba(144, 128, 241, 0.5);
}
.LandingPage .btn-info.disabled,
.LandingPage .btn-info:disabled {
  color: #ffffff;
  background-color: #7c69ef;
  border-color: #7c69ef;
}
.LandingPage .btn-info:not(:disabled):not(.disabled):active,
.LandingPage .btn-info:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #543bea;
  border-color: #4a2fe9;
}
.LandingPage .btn-info:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(144, 128, 241, 0.5);
}
.LandingPage .btn-warning {
  color: #1b2a4e;
  background-color: #fad776;
  border-color: #fad776;
  box-shadow: none;
}
.LandingPage .btn-warning:hover {
  color: #1b2a4e;
  background-color: #f9cc51;
  border-color: #f8c945;
}
.LandingPage .btn-warning:focus,
.LandingPage .btn-warning.focus {
  color: #1b2a4e;
  background-color: #f9cc51;
  border-color: #f8c945;
  box-shadow: 0 0 0 0 rgba(217, 189, 112, 0.5);
}
.LandingPage .btn-warning.disabled,
.LandingPage .btn-warning:disabled {
  color: #1b2a4e;
  background-color: #fad776;
  border-color: #fad776;
}
.LandingPage .btn-warning:not(:disabled):not(.disabled):active,
.LandingPage .btn-warning:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-warning.dropdown-toggle {
  color: #1b2a4e;
  background-color: #f8c945;
  border-color: #f8c538;
}
.LandingPage .btn-warning:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 189, 112, 0.5);
}
.LandingPage .btn-danger {
  color: #ffffff;
  background-color: #df4759;
  border-color: #df4759;
  box-shadow: none;
}
.LandingPage .btn-danger:hover {
  color: #ffffff;
  background-color: #d9263c;
  border-color: #cf2438;
}
.LandingPage .btn-danger:focus,
.LandingPage .btn-danger.focus {
  color: #ffffff;
  background-color: #d9263c;
  border-color: #cf2438;
  box-shadow: 0 0 0 0 rgba(228, 99, 114, 0.5);
}
.LandingPage .btn-danger.disabled,
.LandingPage .btn-danger:disabled {
  color: #ffffff;
  background-color: #df4759;
  border-color: #df4759;
}
.LandingPage .btn-danger:not(:disabled):not(.disabled):active,
.LandingPage .btn-danger:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #cf2438;
  border-color: #c42235;
}
.LandingPage .btn-danger:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(228, 99, 114, 0.5);
}
.LandingPage .btn-light {
  color: #1b2a4e;
  background-color: #f9fbfd;
  border-color: #f9fbfd;
  box-shadow: none;
}
.LandingPage .btn-light:hover {
  color: #1b2a4e;
  background-color: #dce8f3;
  border-color: #d3e2f0;
}
.LandingPage .btn-light:focus,
.LandingPage .btn-light.focus {
  color: #1b2a4e;
  background-color: #dce8f3;
  border-color: #d3e2f0;
  box-shadow: 0 0 0 0 rgba(216, 220, 227, 0.5);
}
.LandingPage .btn-light.disabled,
.LandingPage .btn-light:disabled {
  color: #1b2a4e;
  background-color: #f9fbfd;
  border-color: #f9fbfd;
}
.LandingPage .btn-light:not(:disabled):not(.disabled):active,
.LandingPage .btn-light:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-light.dropdown-toggle {
  color: #1b2a4e;
  background-color: #d3e2f0;
  border-color: #c9dbed;
}
.LandingPage .btn-light:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(216, 220, 227, 0.5);
}
.LandingPage .btn-dark {
  color: #ffffff;
  background-color: #1b2a4e;
  border-color: #1b2a4e;
  box-shadow: none;
}
.LandingPage .btn-dark:hover {
  color: #ffffff;
  background-color: #111b32;
  border-color: #0e1628;
}
.LandingPage .btn-dark:focus,
.LandingPage .btn-dark.focus {
  color: #ffffff;
  background-color: #111b32;
  border-color: #0e1628;
  box-shadow: 0 0 0 0 rgba(61, 74, 105, 0.5);
}
.LandingPage .btn-dark.disabled,
.LandingPage .btn-dark:disabled {
  color: #ffffff;
  background-color: #1b2a4e;
  border-color: #1b2a4e;
}
.LandingPage .btn-dark:not(:disabled):not(.disabled):active,
.LandingPage .btn-dark:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #0e1628;
  border-color: #0b111f;
}
.LandingPage .btn-dark:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(61, 74, 105, 0.5);
}
.LandingPage .btn-primary-desat {
  color: #ffffff;
  background-color: #6c8aec;
  border-color: #6c8aec;
  box-shadow: none;
}
.LandingPage .btn-primary-desat:hover {
  color: #ffffff;
  background-color: #4a6fe8;
  border-color: #3f66e6;
}
.LandingPage .btn-primary-desat:focus,
.LandingPage .btn-primary-desat.focus {
  color: #ffffff;
  background-color: #4a6fe8;
  border-color: #3f66e6;
  box-shadow: 0 0 0 0 rgba(130, 156, 239, 0.5);
}
.LandingPage .btn-primary-desat.disabled,
.LandingPage .btn-primary-desat:disabled {
  color: #ffffff;
  background-color: #6c8aec;
  border-color: #6c8aec;
}
.LandingPage .btn-primary-desat:not(:disabled):not(.disabled):active,
.LandingPage .btn-primary-desat:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-primary-desat.dropdown-toggle {
  color: #ffffff;
  background-color: #3f66e6;
  border-color: #345de5;
}
.LandingPage .btn-primary-desat:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-primary-desat:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-primary-desat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(130, 156, 239, 0.5);
}
.LandingPage .btn-black {
  color: #ffffff;
  background-color: #161c2d;
  border-color: #161c2d;
  box-shadow: none;
}
.LandingPage .btn-black:hover {
  color: #ffffff;
  background-color: #090c13;
  border-color: #05070b;
}
.LandingPage .btn-black:focus,
.LandingPage .btn-black.focus {
  color: #ffffff;
  background-color: #090c13;
  border-color: #05070b;
  box-shadow: 0 0 0 0 rgba(57, 62, 77, 0.5);
}
.LandingPage .btn-black.disabled,
.LandingPage .btn-black:disabled {
  color: #ffffff;
  background-color: #161c2d;
  border-color: #161c2d;
}
.LandingPage .btn-black:not(:disabled):not(.disabled):active,
.LandingPage .btn-black:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-black.dropdown-toggle {
  color: #ffffff;
  background-color: #05070b;
  border-color: #010102;
}
.LandingPage .btn-black:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-black:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(57, 62, 77, 0.5);
}
.LandingPage .btn-white {
  color: #1b2a4e;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: none;
}
.LandingPage .btn-white:hover {
  color: #1b2a4e;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.LandingPage .btn-white:focus,
.LandingPage .btn-white.focus {
  color: #1b2a4e;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5);
}
.LandingPage .btn-white.disabled,
.LandingPage .btn-white:disabled {
  color: #1b2a4e;
  background-color: #ffffff;
  border-color: #ffffff;
}
.LandingPage .btn-white:not(:disabled):not(.disabled):active,
.LandingPage .btn-white:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-white.dropdown-toggle {
  color: #1b2a4e;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.LandingPage .btn-white:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5);
}
.LandingPage .btn-outline-primary {
  color: #335eea;
  border-color: #335eea;
}
.LandingPage .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .btn-outline-primary:focus,
.LandingPage .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5);
}
.LandingPage .btn-outline-primary.disabled,
.LandingPage .btn-outline-primary:disabled {
  color: #335eea;
  background-color: transparent;
}
.LandingPage .btn-outline-primary:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5);
}
.LandingPage .btn-outline-secondary {
  color: #506690;
  border-color: #506690;
}
.LandingPage .btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
}
.LandingPage .btn-outline-secondary:focus,
.LandingPage .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5);
}
.LandingPage .btn-outline-secondary.disabled,
.LandingPage .btn-outline-secondary:disabled {
  color: #506690;
  background-color: transparent;
}
.LandingPage .btn-outline-secondary:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
}
.LandingPage .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5);
}
.LandingPage .btn-outline-success {
  color: #42ba96;
  border-color: #42ba96;
}
.LandingPage .btn-outline-success:hover {
  color: #ffffff;
  background-color: #42ba96;
  border-color: #42ba96;
}
.LandingPage .btn-outline-success:focus,
.LandingPage .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5);
}
.LandingPage .btn-outline-success.disabled,
.LandingPage .btn-outline-success:disabled {
  color: #42ba96;
  background-color: transparent;
}
.LandingPage .btn-outline-success:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #42ba96;
  border-color: #42ba96;
}
.LandingPage .btn-outline-success:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5);
}
.LandingPage .btn-outline-info {
  color: #7c69ef;
  border-color: #7c69ef;
}
.LandingPage .btn-outline-info:hover {
  color: #ffffff;
  background-color: #7c69ef;
  border-color: #7c69ef;
}
.LandingPage .btn-outline-info:focus,
.LandingPage .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5);
}
.LandingPage .btn-outline-info.disabled,
.LandingPage .btn-outline-info:disabled {
  color: #7c69ef;
  background-color: transparent;
}
.LandingPage .btn-outline-info:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #7c69ef;
  border-color: #7c69ef;
}
.LandingPage .btn-outline-info:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5);
}
.LandingPage .btn-outline-warning {
  color: #fad776;
  border-color: #fad776;
}
.LandingPage .btn-outline-warning:hover {
  color: #1b2a4e;
  background-color: #fad776;
  border-color: #fad776;
}
.LandingPage .btn-outline-warning:focus,
.LandingPage .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5);
}
.LandingPage .btn-outline-warning.disabled,
.LandingPage .btn-outline-warning:disabled {
  color: #fad776;
  background-color: transparent;
}
.LandingPage .btn-outline-warning:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-warning.dropdown-toggle {
  color: #1b2a4e;
  background-color: #fad776;
  border-color: #fad776;
}
.LandingPage .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5);
}
.LandingPage .btn-outline-danger {
  color: #df4759;
  border-color: #df4759;
}
.LandingPage .btn-outline-danger:hover {
  color: #ffffff;
  background-color: #df4759;
  border-color: #df4759;
}
.LandingPage .btn-outline-danger:focus,
.LandingPage .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5);
}
.LandingPage .btn-outline-danger.disabled,
.LandingPage .btn-outline-danger:disabled {
  color: #df4759;
  background-color: transparent;
}
.LandingPage .btn-outline-danger:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #df4759;
  border-color: #df4759;
}
.LandingPage .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5);
}
.LandingPage .btn-outline-light {
  color: #f9fbfd;
  border-color: #f9fbfd;
}
.LandingPage .btn-outline-light:hover {
  color: #1b2a4e;
  background-color: #f9fbfd;
  border-color: #f9fbfd;
}
.LandingPage .btn-outline-light:focus,
.LandingPage .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
}
.LandingPage .btn-outline-light.disabled,
.LandingPage .btn-outline-light:disabled {
  color: #f9fbfd;
  background-color: transparent;
}
.LandingPage .btn-outline-light:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-light.dropdown-toggle {
  color: #1b2a4e;
  background-color: #f9fbfd;
  border-color: #f9fbfd;
}
.LandingPage .btn-outline-light:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
}
.LandingPage .btn-outline-dark {
  color: #1b2a4e;
  border-color: #1b2a4e;
}
.LandingPage .btn-outline-dark:hover {
  color: #ffffff;
  background-color: #1b2a4e;
  border-color: #1b2a4e;
}
.LandingPage .btn-outline-dark:focus,
.LandingPage .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5);
}
.LandingPage .btn-outline-dark.disabled,
.LandingPage .btn-outline-dark:disabled {
  color: #1b2a4e;
  background-color: transparent;
}
.LandingPage .btn-outline-dark:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1b2a4e;
  border-color: #1b2a4e;
}
.LandingPage .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5);
}
.LandingPage .btn-outline-primary-desat {
  color: #6c8aec;
  border-color: #6c8aec;
}
.LandingPage .btn-outline-primary-desat:hover {
  color: #ffffff;
  background-color: #6c8aec;
  border-color: #6c8aec;
}
.LandingPage .btn-outline-primary-desat:focus,
.LandingPage .btn-outline-primary-desat.focus {
  box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5);
}
.LandingPage .btn-outline-primary-desat.disabled,
.LandingPage .btn-outline-primary-desat:disabled {
  color: #6c8aec;
  background-color: transparent;
}
.LandingPage .btn-outline-primary-desat:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-primary-desat:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-primary-desat.dropdown-toggle {
  color: #ffffff;
  background-color: #6c8aec;
  border-color: #6c8aec;
}
.LandingPage .btn-outline-primary-desat:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-primary-desat:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-primary-desat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5);
}
.LandingPage .btn-outline-black {
  color: #161c2d;
  border-color: #161c2d;
}
.LandingPage .btn-outline-black:hover {
  color: #ffffff;
  background-color: #161c2d;
  border-color: #161c2d;
}
.LandingPage .btn-outline-black:focus,
.LandingPage .btn-outline-black.focus {
  box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5);
}
.LandingPage .btn-outline-black.disabled,
.LandingPage .btn-outline-black:disabled {
  color: #161c2d;
  background-color: transparent;
}
.LandingPage .btn-outline-black:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-black:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-black.dropdown-toggle {
  color: #ffffff;
  background-color: #161c2d;
  border-color: #161c2d;
}
.LandingPage .btn-outline-black:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5);
}
.LandingPage .btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}
.LandingPage .btn-outline-white:hover {
  color: #1b2a4e;
  background-color: #ffffff;
  border-color: #ffffff;
}
.LandingPage .btn-outline-white:focus,
.LandingPage .btn-outline-white.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.LandingPage .btn-outline-white.disabled,
.LandingPage .btn-outline-white:disabled {
  color: #ffffff;
  background-color: transparent;
}
.LandingPage .btn-outline-white:not(:disabled):not(.disabled):active,
.LandingPage .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .LandingPage .btn-outline-white.dropdown-toggle {
  color: #1b2a4e;
  background-color: #ffffff;
  border-color: #ffffff;
}
.LandingPage .btn-outline-white:not(:disabled):not(.disabled):active:focus,
.LandingPage .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.LandingPage .btn-link {
  font-weight: 400;
  color: #335eea;
  text-decoration: none;
}
.LandingPage .btn-link:hover {
  color: #133bbd;
  text-decoration: underline;
}
.LandingPage .btn-link:focus,
.LandingPage .btn-link.focus {
  text-decoration: underline;
}
.LandingPage .btn-link:disabled,
.LandingPage .btn-link.disabled {
  color: #869ab8;
  pointer-events: none;
}
.LandingPage .btn-lg,
.LandingPage .btn-group-lg > .btn {
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
}
.LandingPage .btn-sm,
.LandingPage .btn-group-sm > .btn {
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
}
.LandingPage .btn-block {
  display: block;
  width: 100%;
}
.LandingPage .btn-block + .btn-block {
  margin-top: 0.5rem;
}
.LandingPage input[type='submit'].btn-block,
.LandingPage input[type='reset'].btn-block,
.LandingPage input[type='button'].btn-block {
  width: 100%;
}
.LandingPage .fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .fade:not(.show) {
  opacity: 0;
}
.LandingPage .collapse:not(.show) {
  display: none;
}
.LandingPage .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .dropup,
.LandingPage .dropright,
.LandingPage .dropdown,
.LandingPage .dropleft {
  position: relative;
}
.LandingPage .dropdown-toggle {
  white-space: nowrap;
}
.LandingPage .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.LandingPage .dropdown-toggle:empty::after {
  margin-left: 0;
}
.LandingPage .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 14rem;
  padding: 1.625rem 0;
  margin: 0 0 0;
  font-size: 0.9375rem;
  color: #161c2d;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0px solid rgba(22, 28, 45, 0.15);
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
}
.LandingPage .dropdown-menu-left {
  right: auto;
  left: 0;
}
.LandingPage .dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .LandingPage .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .LandingPage .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .LandingPage .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .LandingPage .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .LandingPage .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .LandingPage .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .LandingPage .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .LandingPage .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.LandingPage .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.LandingPage .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.LandingPage .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.LandingPage .dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.LandingPage .dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.LandingPage .dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.LandingPage .dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.LandingPage .dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.LandingPage .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}
.LandingPage .dropleft .dropdown-toggle::after {
  display: none;
}
.LandingPage .dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.LandingPage .dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.LandingPage .dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.LandingPage .dropdown-menu[x-placement^='top'],
.LandingPage .dropdown-menu[x-placement^='right'],
.LandingPage .dropdown-menu[x-placement^='bottom'],
.LandingPage .dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}
.LandingPage .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f1f4f8;
}
.LandingPage .dropdown-item {
  display: block;
  width: 100%;
  padding: 0 1.75rem;
  clear: both;
  font-weight: 400;
  color: #506690;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.LandingPage .dropdown-item:hover,
.LandingPage .dropdown-item:focus {
  color: #335eea;
  text-decoration: none;
  background-color: none;
}
.LandingPage .dropdown-item.active,
.LandingPage .dropdown-item:active {
  color: #335eea;
  text-decoration: none;
  background-color: none;
}
.LandingPage .dropdown-item.disabled,
.LandingPage .dropdown-item:disabled {
  color: #869ab8;
  pointer-events: none;
  background-color: transparent;
}
.LandingPage .dropdown-menu.show {
  display: block;
}
.LandingPage .dropdown-header {
  display: block;
  padding: 1.625rem 1.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #335eea;
  white-space: nowrap;
}
.LandingPage .dropdown-item-text {
  display: block;
  padding: 0 1.75rem;
  color: #506690;
}
.LandingPage .btn-group,
.LandingPage .btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
.LandingPage .btn-group > .btn,
.LandingPage .btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.LandingPage .btn-group > .btn:hover,
.LandingPage .btn-group-vertical > .btn:hover {
  z-index: 1;
}
.LandingPage .btn-group > .btn:focus,
.LandingPage .btn-group > .btn:active,
.LandingPage .btn-group > .btn.active,
.LandingPage .btn-group-vertical > .btn:focus,
.LandingPage .btn-group-vertical > .btn:active,
.LandingPage .btn-group-vertical > .btn.active {
  z-index: 1;
}
.LandingPage .btn-toolbar {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.LandingPage .btn-toolbar .input-group {
  width: auto;
}
.LandingPage .btn-group > .btn:not(:first-child),
.LandingPage .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.LandingPage .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.LandingPage .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.LandingPage .btn-group > .btn:not(:first-child),
.LandingPage .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.LandingPage .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.LandingPage .dropdown-toggle-split::after,
.dropup .LandingPage .dropdown-toggle-split::after,
.dropright .LandingPage .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .LandingPage .dropdown-toggle-split::before {
  margin-right: 0;
}
.LandingPage .btn-sm + .dropdown-toggle-split,
.LandingPage .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.LandingPage .btn-lg + .dropdown-toggle-split,
.LandingPage .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.LandingPage .btn-group.show .dropdown-toggle {
  box-shadow: none;
}
.LandingPage .btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.LandingPage .btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
}
.LandingPage .btn-group-vertical > .btn,
.LandingPage .btn-group-vertical > .btn-group {
  width: 100%;
}
.LandingPage .btn-group-vertical > .btn:not(:first-child),
.LandingPage .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.LandingPage .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.LandingPage .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.LandingPage .btn-group-vertical > .btn:not(:first-child),
.LandingPage .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.LandingPage .btn-group-toggle > .btn,
.LandingPage .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.LandingPage .btn-group-toggle > .btn input[type='radio'],
.LandingPage .btn-group-toggle > .btn input[type='checkbox'],
.LandingPage .btn-group-toggle > .btn-group > .btn input[type='radio'],
.LandingPage .btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.LandingPage .input-group {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
}
.LandingPage .input-group > .form-control,
.LandingPage .input-group > .form-control-plaintext,
.LandingPage .input-group > .custom-select,
.LandingPage .input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.LandingPage .input-group > .form-control + .form-control,
.LandingPage .input-group > .form-control + .custom-select,
.LandingPage .input-group > .form-control + .custom-file,
.LandingPage .input-group > .form-control-plaintext + .form-control,
.LandingPage .input-group > .form-control-plaintext + .custom-select,
.LandingPage .input-group > .form-control-plaintext + .custom-file,
.LandingPage .input-group > .custom-select + .form-control,
.LandingPage .input-group > .custom-select + .custom-select,
.LandingPage .input-group > .custom-select + .custom-file,
.LandingPage .input-group > .custom-file + .form-control,
.LandingPage .input-group > .custom-file + .custom-select,
.LandingPage .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.LandingPage .input-group > .form-control:focus,
.LandingPage .input-group > .custom-select:focus,
.LandingPage .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.LandingPage .input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.LandingPage .input-group > .form-control:not(:last-child),
.LandingPage .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.LandingPage .input-group > .form-control:not(:first-child),
.LandingPage .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.LandingPage .input-group > .custom-file {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.LandingPage .input-group > .custom-file:not(:last-child) .custom-file-label,
.LandingPage .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.LandingPage .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.LandingPage .input-group-prepend,
.LandingPage .input-group-append {
  display: -webkit-box;
  display: flex;
}
.LandingPage .input-group-prepend .btn,
.LandingPage .input-group-append .btn {
  position: relative;
  z-index: 2;
}
.LandingPage .input-group-prepend .btn:focus,
.LandingPage .input-group-append .btn:focus {
  z-index: 3;
}
.LandingPage .input-group-prepend .btn + .btn,
.LandingPage .input-group-prepend .btn + .input-group-text,
.LandingPage .input-group-prepend .input-group-text + .input-group-text,
.LandingPage .input-group-prepend .input-group-text + .btn,
.LandingPage .input-group-append .btn + .btn,
.LandingPage .input-group-append .btn + .input-group-text,
.LandingPage .input-group-append .input-group-text + .input-group-text,
.LandingPage .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.LandingPage .input-group-prepend {
  margin-right: -1px;
}
.LandingPage .input-group-append {
  margin-left: -1px;
}
.LandingPage .input-group-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.8125rem 1.25rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #869ab8;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
}
.LandingPage .input-group-text input[type='radio'],
.LandingPage .input-group-text input[type='checkbox'] {
  margin-top: 0;
}
.LandingPage .input-group-lg > .form-control:not(textarea),
.LandingPage .input-group-lg > .custom-select {
  height: calc(1.6em + 2.244rem + 2px);
}
.LandingPage .input-group-lg > .form-control,
.LandingPage .input-group-lg > .custom-select,
.LandingPage .input-group-lg > .input-group-prepend > .input-group-text,
.LandingPage .input-group-lg > .input-group-append > .input-group-text,
.LandingPage .input-group-lg > .input-group-prepend > .btn,
.LandingPage .input-group-lg > .input-group-append > .btn {
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
}
.LandingPage .input-group-sm > .form-control:not(textarea),
.LandingPage .input-group-sm > .custom-select {
  height: calc(1.6em + 1.125rem + 2px);
}
.LandingPage .input-group-sm > .form-control,
.LandingPage .input-group-sm > .custom-select,
.LandingPage .input-group-sm > .input-group-prepend > .input-group-text,
.LandingPage .input-group-sm > .input-group-append > .input-group-text,
.LandingPage .input-group-sm > .input-group-prepend > .btn,
.LandingPage .input-group-sm > .input-group-append > .btn {
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
}
.LandingPage .input-group-lg > .custom-select,
.LandingPage .input-group-sm > .custom-select {
  padding-right: 3.25rem;
}
.LandingPage .input-group > .input-group-prepend > .btn,
.LandingPage .input-group > .input-group-prepend > .input-group-text,
.LandingPage .input-group > .input-group-append:not(:last-child) > .btn,
.LandingPage .input-group > .input-group-append:not(:last-child) > .input-group-text,
.LandingPage .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.LandingPage .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.LandingPage .input-group > .input-group-append > .btn,
.LandingPage .input-group > .input-group-append > .input-group-text,
.LandingPage .input-group > .input-group-prepend:not(:first-child) > .btn,
.LandingPage .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.LandingPage .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.LandingPage .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.LandingPage .custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5rem;
}
.LandingPage .custom-control-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 1rem;
}
.LandingPage .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.35rem;
  opacity: 0;
}
.LandingPage .custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #335eea;
  background-color: #335eea;
  box-shadow: ;
}
.LandingPage .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none, none;
}
.LandingPage .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #a7b9f6;
}
.LandingPage .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #d5defb;
  border-color: #d5defb;
  box-shadow: ;
}
.LandingPage .custom-control-input[disabled] ~ .custom-control-label,
.LandingPage .custom-control-input:disabled ~ .custom-control-label {
  color: #869ab8;
}
.LandingPage .custom-control-input[disabled] ~ .custom-control-label::before,
.LandingPage .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f1f4f8;
}
.LandingPage .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.LandingPage .custom-control-label::before {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #d9e2ef;
  border: #abbcd5 solid 0px;
  box-shadow: none;
}
.LandingPage .custom-control-label::after {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}
.LandingPage .custom-checkbox .custom-control-label::before {
  border-radius: 0.375rem;
}
.LandingPage .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.LandingPage .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #335eea;
  background-color: #335eea;
  box-shadow: ;
}
.LandingPage .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e");
}
.LandingPage .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 94, 234, 0.5);
}
.LandingPage .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(51, 94, 234, 0.5);
}
.LandingPage .custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.LandingPage .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.LandingPage .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 94, 234, 0.5);
}
.LandingPage .custom-switch {
  padding-left: 3rem;
}
.LandingPage .custom-switch .custom-control-label::before {
  left: -3rem;
  width: 2.5rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.LandingPage .custom-switch .custom-control-label::after {
  top: calc(0.35rem + 0px);
  left: calc(-3rem + 0px);
  width: 1rem;
  height: 1rem;
  background-color: #abbcd5;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #d9e2ef;
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}
.LandingPage .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 94, 234, 0.5);
}
.LandingPage .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 3.25rem 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1.25rem center/auto 50%;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.LandingPage .custom-select:focus {
  border-color: #a7b9f6;
  outline: 0;
}
.LandingPage .custom-select:focus::-ms-value {
  color: #161c2d;
  background-color: #ffffff;
}
.LandingPage .custom-select[multiple],
.LandingPage .custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 1.25rem;
  background-image: none;
}
.LandingPage .custom-select:disabled {
  color: #869ab8;
  background-color: #f1f4f8;
}
.LandingPage .custom-select::-ms-expand {
  display: none;
}
.LandingPage .custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #161c2d;
}
.LandingPage .custom-select-sm {
  height: calc(1.6em + 1.125rem + 2px);
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  padding-left: 1rem;
  font-size: 1.0625rem;
}
.LandingPage .custom-select-lg {
  height: calc(1.6em + 2.244rem + 2px);
  padding-top: 1.122rem;
  padding-bottom: 1.122rem;
  padding-left: 1.5rem;
  font-size: 1.0625rem;
}
.LandingPage .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  margin-bottom: 0;
}
.LandingPage .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  margin: 0;
  opacity: 0;
}
.LandingPage .custom-file-input:focus ~ .custom-file-label {
  border-color: #a7b9f6;
  box-shadow: none;
}
.LandingPage .custom-file-input[disabled] ~ .custom-file-label,
.LandingPage .custom-file-input:disabled ~ .custom-file-label {
  background-color: #f1f4f8;
}
.LandingPage .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}
.LandingPage .custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.LandingPage .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  background-color: #ffffff;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
  box-shadow: none;
}
.LandingPage .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 1.625rem);
  padding: 0.8125rem 1.25rem;
  line-height: 1.6;
  color: #506690;
  content: 'Browse';
  background-color: #f1f4f8;
  border-left: inherit;
  border-radius: 0 0.375rem 0.375rem 0;
}
.LandingPage .custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.LandingPage .custom-range:focus {
  outline: none;
}
.LandingPage .custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.LandingPage .custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.LandingPage .custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.LandingPage .custom-range::-moz-focus-outer {
  border: 0;
}
.LandingPage .custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #335eea;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .custom-range::-webkit-slider-thumb:active {
  background-color: #d5defb;
}
.LandingPage .custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d9e2ef;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1);
}
.LandingPage .custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #335eea;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.LandingPage .custom-range::-moz-range-thumb:active {
  background-color: #d5defb;
}
.LandingPage .custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d9e2ef;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1);
}
.LandingPage .custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #335eea;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.LandingPage .custom-range::-ms-thumb:active {
  background-color: #d5defb;
}
.LandingPage .custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1);
}
.LandingPage .custom-range::-ms-fill-lower {
  background-color: #d9e2ef;
  border-radius: 1rem;
}
.LandingPage .custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d9e2ef;
  border-radius: 1rem;
}
.LandingPage .custom-range:disabled::-webkit-slider-thumb {
  background-color: #abbcd5;
}
.LandingPage .custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.LandingPage .custom-range:disabled::-moz-range-thumb {
  background-color: #abbcd5;
}
.LandingPage .custom-range:disabled::-moz-range-track {
  cursor: default;
}
.LandingPage .custom-range:disabled::-ms-thumb {
  background-color: #abbcd5;
}
.LandingPage .custom-control-label::before,
.LandingPage .custom-file-label,
.LandingPage .custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .custom-control-label::before,
  .LandingPage .custom-file-label,
  .LandingPage .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .nav {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.LandingPage .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.LandingPage .nav-link:hover,
.LandingPage .nav-link:focus {
  text-decoration: none;
}
.LandingPage .nav-link.disabled {
  color: #869ab8;
  pointer-events: none;
  cursor: default;
}
.LandingPage .nav-tabs {
  border-bottom: 1px solid #d9e2ef;
}
.LandingPage .nav-tabs .nav-item {
  margin-bottom: -1px;
}
.LandingPage .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.LandingPage .nav-tabs .nav-link:hover,
.LandingPage .nav-tabs .nav-link:focus {
  border-color: #f1f4f8 #f1f4f8 #d9e2ef;
}
.LandingPage .nav-tabs .nav-link.disabled {
  color: #869ab8;
  background-color: transparent;
  border-color: transparent;
}
.LandingPage .nav-tabs .nav-link.active,
.LandingPage .nav-tabs .nav-item.show .nav-link {
  color: #506690;
  background-color: #ffffff;
  border-color: #d9e2ef #d9e2ef #ffffff;
}
.LandingPage .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.LandingPage .nav-pills .nav-link {
  border-radius: 0.375rem;
}
.LandingPage .nav-pills .nav-link.active,
.LandingPage .nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #335eea;
}
.LandingPage .nav-fill > .nav-link,
.LandingPage .nav-fill .nav-item {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  text-align: center;
}
.LandingPage .nav-justified > .nav-link,
.LandingPage .nav-justified .nav-item {
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: center;
}
.LandingPage .tab-content > .tab-pane {
  display: none;
}
.LandingPage .tab-content > .active {
  display: block;
}
.LandingPage .navbar {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1.25rem 0;
}
.LandingPage .navbar .container,
.LandingPage .navbar .container-fluid,
.LandingPage .navbar .container-sm,
.LandingPage .navbar .container-md,
.LandingPage .navbar .container-lg,
.LandingPage .navbar .container-xl {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.LandingPage .navbar-brand {
  display: inline-block;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  margin-right: 0;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: nowrap;
}
.LandingPage .navbar-brand:hover,
.LandingPage .navbar-brand:focus {
  text-decoration: none;
}
.LandingPage .navbar-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.LandingPage .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.LandingPage .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.LandingPage .navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.LandingPage .navbar-collapse {
  flex-basis: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
}
.LandingPage .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.1875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}
.LandingPage .navbar-toggler:hover,
.LandingPage .navbar-toggler:focus {
  text-decoration: none;
}
.LandingPage .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .LandingPage .navbar-expand-sm > .container,
  .LandingPage .navbar-expand-sm > .container-fluid,
  .LandingPage .navbar-expand-sm > .container-sm,
  .LandingPage .navbar-expand-sm > .container-md,
  .LandingPage .navbar-expand-sm > .container-lg,
  .LandingPage .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .LandingPage .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .LandingPage .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .LandingPage .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .LandingPage .navbar-expand-sm > .container,
  .LandingPage .navbar-expand-sm > .container-fluid,
  .LandingPage .navbar-expand-sm > .container-sm,
  .LandingPage .navbar-expand-sm > .container-md,
  .LandingPage .navbar-expand-sm > .container-lg,
  .LandingPage .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .LandingPage .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }
  .LandingPage .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .LandingPage .navbar-expand-md > .container,
  .LandingPage .navbar-expand-md > .container-fluid,
  .LandingPage .navbar-expand-md > .container-sm,
  .LandingPage .navbar-expand-md > .container-md,
  .LandingPage .navbar-expand-md > .container-lg,
  .LandingPage .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .LandingPage .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .LandingPage .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .LandingPage .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .LandingPage .navbar-expand-md > .container,
  .LandingPage .navbar-expand-md > .container-fluid,
  .LandingPage .navbar-expand-md > .container-sm,
  .LandingPage .navbar-expand-md > .container-md,
  .LandingPage .navbar-expand-md > .container-lg,
  .LandingPage .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .LandingPage .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }
  .LandingPage .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-expand-lg > .container,
  .LandingPage .navbar-expand-lg > .container-fluid,
  .LandingPage .navbar-expand-lg > .container-sm,
  .LandingPage .navbar-expand-lg > .container-md,
  .LandingPage .navbar-expand-lg > .container-lg,
  .LandingPage .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .LandingPage .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .LandingPage .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .LandingPage .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .LandingPage .navbar-expand-lg > .container,
  .LandingPage .navbar-expand-lg > .container-fluid,
  .LandingPage .navbar-expand-lg > .container-sm,
  .LandingPage .navbar-expand-lg > .container-md,
  .LandingPage .navbar-expand-lg > .container-lg,
  .LandingPage .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .LandingPage .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }
  .LandingPage .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .LandingPage .navbar-expand-xl > .container,
  .LandingPage .navbar-expand-xl > .container-fluid,
  .LandingPage .navbar-expand-xl > .container-sm,
  .LandingPage .navbar-expand-xl > .container-md,
  .LandingPage .navbar-expand-xl > .container-lg,
  .LandingPage .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .LandingPage .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .LandingPage .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .LandingPage .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .LandingPage .navbar-expand-xl > .container,
  .LandingPage .navbar-expand-xl > .container-fluid,
  .LandingPage .navbar-expand-xl > .container-sm,
  .LandingPage .navbar-expand-xl > .container-md,
  .LandingPage .navbar-expand-xl > .container-lg,
  .LandingPage .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .LandingPage .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }
  .LandingPage .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.LandingPage .navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.LandingPage .navbar-expand > .container,
.LandingPage .navbar-expand > .container-fluid,
.LandingPage .navbar-expand > .container-sm,
.LandingPage .navbar-expand > .container-md,
.LandingPage .navbar-expand > .container-lg,
.LandingPage .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.LandingPage .navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}
.LandingPage .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.LandingPage .navbar-expand .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.LandingPage .navbar-expand > .container,
.LandingPage .navbar-expand > .container-fluid,
.LandingPage .navbar-expand > .container-sm,
.LandingPage .navbar-expand > .container-md,
.LandingPage .navbar-expand > .container-lg,
.LandingPage .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.LandingPage .navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: flex !important;
  flex-basis: auto;
}
.LandingPage .navbar-expand .navbar-toggler {
  display: none;
}
.LandingPage .navbar-light .navbar-brand {
  color: #335eea;
}
.LandingPage .navbar-light .navbar-brand:hover,
.LandingPage .navbar-light .navbar-brand:focus {
  color: #335eea;
}
.LandingPage .navbar-light .navbar-nav .nav-link {
  color: #506690;
}
.LandingPage .navbar-light .navbar-nav .nav-link:hover,
.LandingPage .navbar-light .navbar-nav .nav-link:focus {
  color: #335eea;
}
.LandingPage .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(22, 28, 45, 0.3);
}
.LandingPage .navbar-light .navbar-nav .show > .nav-link,
.LandingPage .navbar-light .navbar-nav .active > .nav-link,
.LandingPage .navbar-light .navbar-nav .nav-link.show,
.LandingPage .navbar-light .navbar-nav .nav-link.active {
  color: #335eea;
}
.LandingPage .navbar-light .navbar-toggler {
  color: #506690;
  border-color: transparent;
}
.LandingPage .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23506690' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.LandingPage .navbar-light .navbar-text {
  color: #506690;
}
.LandingPage .navbar-light .navbar-text a {
  color: #335eea;
}
.LandingPage .navbar-light .navbar-text a:hover,
.LandingPage .navbar-light .navbar-text a:focus {
  color: #335eea;
}
.LandingPage .navbar-dark .navbar-brand {
  color: #ffffff;
}
.LandingPage .navbar-dark .navbar-brand:hover,
.LandingPage .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.LandingPage .navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}
.LandingPage .navbar-dark .navbar-nav .nav-link:hover,
.LandingPage .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.LandingPage .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.LandingPage .navbar-dark .navbar-nav .show > .nav-link,
.LandingPage .navbar-dark .navbar-nav .active > .nav-link,
.LandingPage .navbar-dark .navbar-nav .nav-link.show,
.LandingPage .navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.LandingPage .navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: transparent;
}
.LandingPage .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.LandingPage .navbar-dark .navbar-text {
  color: #ffffff;
}
.LandingPage .navbar-dark .navbar-text a {
  color: #ffffff;
}
.LandingPage .navbar-dark .navbar-text a:hover,
.LandingPage .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}
.LandingPage .card {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0px solid #f1f4f8;
  border-radius: 0.375rem;
}
.LandingPage .card > hr {
  margin-right: 0;
  margin-left: 0;
}
.LandingPage .card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.LandingPage .card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px);
}
.LandingPage .card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px);
}
.LandingPage .card > .card-header + .list-group,
.LandingPage .card > .list-group + .card-footer {
  border-top: 0;
}
.LandingPage .card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2rem;
}
.LandingPage .card-title {
  margin-bottom: 1.5rem;
}
.LandingPage .card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}
.LandingPage .card-text:last-child {
  margin-bottom: 0;
}
.LandingPage .card-link:hover {
  text-decoration: none;
}
.LandingPage .card-link + .card-link {
  margin-left: 2rem;
}
.LandingPage .card-header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: rgba(22, 28, 45, 0.03);
  border-bottom: 0px solid #f1f4f8;
}
.LandingPage .card-header:first-child {
  border-radius: calc(0.375rem - 0px) calc(0.375rem - 0px) 0 0;
}
.LandingPage .card-footer {
  padding: 1.5rem 2rem;
  background-color: rgba(22, 28, 45, 0.03);
  border-top: 0px solid #f1f4f8;
}
.LandingPage .card-footer:last-child {
  border-radius: 0 0 calc(0.375rem - 0px) calc(0.375rem - 0px);
}
.LandingPage .card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1.5rem;
  margin-left: -1rem;
  border-bottom: 0;
}
.LandingPage .card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem;
}
.LandingPage .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.375rem - 0px);
}
.LandingPage .card-img,
.LandingPage .card-img-top,
.LandingPage .card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.LandingPage .card-img,
.LandingPage .card-img-top {
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px);
}
.LandingPage .card-img,
.LandingPage .card-img-bottom {
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px);
}
.LandingPage .card-deck .card {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .LandingPage .card-deck {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
  .LandingPage .card-deck .card {
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    margin-right: 20px;
    margin-bottom: 0;
    margin-left: 20px;
  }
}
.LandingPage .card-group > .card {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .LandingPage .card-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
  }
  .LandingPage .card-group > .card {
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .LandingPage .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .LandingPage .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .LandingPage .card-group > .card:not(:last-child) .card-img-top,
  .LandingPage .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .LandingPage .card-group > .card:not(:last-child) .card-img-bottom,
  .LandingPage .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .LandingPage .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .LandingPage .card-group > .card:not(:first-child) .card-img-top,
  .LandingPage .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .LandingPage .card-group > .card:not(:first-child) .card-img-bottom,
  .LandingPage .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.LandingPage .card-columns .card {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .LandingPage .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .LandingPage .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.LandingPage .accordion {
  overflow-anchor: none;
}
.LandingPage .accordion > .card {
  overflow: hidden;
}
.LandingPage .accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.LandingPage .accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.LandingPage .accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0px;
}
.LandingPage .breadcrumb {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 0.9375rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}
.LandingPage .breadcrumb-item {
  display: -webkit-box;
  display: flex;
}
.LandingPage .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.LandingPage .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #506690;
  content: '/';
}
.LandingPage .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.LandingPage .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.LandingPage .breadcrumb-item.active {
  color: #506690;
}
.LandingPage .pagination {
  display: -webkit-box;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
}
.LandingPage .page-link {
  position: relative;
  display: block;
  padding: 0.8125rem 1.25rem;
  margin-left: -1px;
  line-height: 1.6;
  color: #335eea;
  background-color: #ffffff;
  border: 1px solid #d9e2ef;
}
.LandingPage .page-link:hover {
  z-index: 2;
  color: #133bbd;
  text-decoration: none;
  background-color: #f1f4f8;
  border-color: #d9e2ef;
}
.LandingPage .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}
.LandingPage .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.LandingPage .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.LandingPage .page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .page-item.disabled .page-link {
  color: #869ab8;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #d9e2ef;
}
.LandingPage .pagination-lg .page-link {
  padding: 1.122rem 1.5rem;
  font-size: 1.1875rem;
  line-height: 1.3;
}
.LandingPage .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.LandingPage .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.LandingPage .pagination-sm .page-link {
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.3;
}
.LandingPage .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.LandingPage .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.LandingPage .badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .badge {
    -webkit-transition: none;
    transition: none;
  }
}
a.LandingPage .badge:hover,
a.LandingPage .badge:focus {
  text-decoration: none;
}
.LandingPage .badge:empty {
  display: none;
}
.LandingPage .btn .badge {
  position: relative;
  top: -1px;
}
.LandingPage .badge-pill {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  border-radius: 10rem;
}
.LandingPage .badge-primary {
  color: #ffffff;
  background-color: #335eea;
}
a.LandingPage .badge-primary:hover,
a.LandingPage .badge-primary:focus {
  color: #ffffff;
  background-color: #1643d4;
}
a.LandingPage .badge-primary:focus,
a.LandingPage .badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5);
}
.LandingPage .badge-secondary {
  color: #ffffff;
  background-color: #506690;
}
a.LandingPage .badge-secondary:hover,
a.LandingPage .badge-secondary:focus {
  color: #ffffff;
  background-color: #3e4f6f;
}
a.LandingPage .badge-secondary:focus,
a.LandingPage .badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5);
}
.LandingPage .badge-success {
  color: #ffffff;
  background-color: #42ba96;
}
a.LandingPage .badge-success:hover,
a.LandingPage .badge-success:focus {
  color: #ffffff;
  background-color: #359478;
}
a.LandingPage .badge-success:focus,
a.LandingPage .badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5);
}
.LandingPage .badge-info {
  color: #ffffff;
  background-color: #7c69ef;
}
a.LandingPage .badge-info:hover,
a.LandingPage .badge-info:focus {
  color: #ffffff;
  background-color: #543bea;
}
a.LandingPage .badge-info:focus,
a.LandingPage .badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5);
}
.LandingPage .badge-warning {
  color: #1b2a4e;
  background-color: #fad776;
}
a.LandingPage .badge-warning:hover,
a.LandingPage .badge-warning:focus {
  color: #1b2a4e;
  background-color: #f8c945;
}
a.LandingPage .badge-warning:focus,
a.LandingPage .badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5);
}
.LandingPage .badge-danger {
  color: #ffffff;
  background-color: #df4759;
}
a.LandingPage .badge-danger:hover,
a.LandingPage .badge-danger:focus {
  color: #ffffff;
  background-color: #cf2438;
}
a.LandingPage .badge-danger:focus,
a.LandingPage .badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5);
}
.LandingPage .badge-light {
  color: #1b2a4e;
  background-color: #f9fbfd;
}
a.LandingPage .badge-light:hover,
a.LandingPage .badge-light:focus {
  color: #1b2a4e;
  background-color: #d3e2f0;
}
a.LandingPage .badge-light:focus,
a.LandingPage .badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
}
.LandingPage .badge-dark {
  color: #ffffff;
  background-color: #1b2a4e;
}
a.LandingPage .badge-dark:hover,
a.LandingPage .badge-dark:focus {
  color: #ffffff;
  background-color: #0e1628;
}
a.LandingPage .badge-dark:focus,
a.LandingPage .badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5);
}
.LandingPage .badge-primary-desat {
  color: #ffffff;
  background-color: #6c8aec;
}
a.LandingPage .badge-primary-desat:hover,
a.LandingPage .badge-primary-desat:focus {
  color: #ffffff;
  background-color: #3f66e6;
}
a.LandingPage .badge-primary-desat:focus,
a.LandingPage .badge-primary-desat.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5);
}
.LandingPage .badge-black {
  color: #ffffff;
  background-color: #161c2d;
}
a.LandingPage .badge-black:hover,
a.LandingPage .badge-black:focus {
  color: #ffffff;
  background-color: #05070b;
}
a.LandingPage .badge-black:focus,
a.LandingPage .badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5);
}
.LandingPage .badge-white {
  color: #1b2a4e;
  background-color: #ffffff;
}
a.LandingPage .badge-white:hover,
a.LandingPage .badge-white:focus {
  color: #1b2a4e;
  background-color: #e6e6e6;
}
a.LandingPage .badge-white:focus,
a.LandingPage .badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.LandingPage .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f1f4f8;
  border-radius: 0.5rem;
}
@media (min-width: 576px) {
  .LandingPage .jumbotron {
    padding: 4rem 2rem;
  }
}
.LandingPage .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.LandingPage .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}
.LandingPage .alert-heading {
  color: inherit;
}
.LandingPage .alert-link {
  font-weight: 600;
}
.LandingPage .alert-dismissible {
  padding-right: 4.09375rem;
}
.LandingPage .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.LandingPage .alert-primary {
  color: #253e8f;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .alert-primary hr {
  border-top-color: #1c4ce8;
}
.LandingPage .alert-primary .alert-link {
  color: #1b2c66;
}
.LandingPage .alert-secondary {
  color: #344260;
  background-color: #506690;
  border-color: #506690;
}
.LandingPage .alert-secondary hr {
  border-top-color: #475a80;
}
.LandingPage .alert-secondary .alert-link {
  color: #222b3f;
}
.LandingPage .alert-success {
  color: #2d6e64;
  background-color: #42ba96;
  border-color: #42ba96;
}
.LandingPage .alert-success hr {
  border-top-color: #3ba787;
}
.LandingPage .alert-success .alert-link {
  color: #1e4a43;
}
.LandingPage .alert-info {
  color: #4b4492;
  background-color: #7c69ef;
  border-color: #7c69ef;
}
.LandingPage .alert-info hr {
  border-top-color: #6852ed;
}
.LandingPage .alert-info .alert-link {
  color: #39346f;
}
.LandingPage .alert-warning {
  color: #8d7d53;
  background-color: #fad776;
  border-color: #fad776;
}
.LandingPage .alert-warning hr {
  border-top-color: #f9d05d;
}
.LandingPage .alert-warning .alert-link {
  color: #6d6140;
}
.LandingPage .alert-danger {
  color: #7f3244;
  background-color: #df4759;
  border-color: #df4759;
}
.LandingPage .alert-danger hr {
  border-top-color: #db3145;
}
.LandingPage .alert-danger .alert-link {
  color: #5a2430;
}
.LandingPage .alert-light {
  color: #8c9099;
  background-color: #f9fbfd;
  border-color: #f9fbfd;
}
.LandingPage .alert-light hr {
  border-top-color: #e6eef7;
}
.LandingPage .alert-light .alert-link {
  color: #727680;
}
.LandingPage .alert-dark {
  color: #19233e;
  background-color: #1b2a4e;
  border-color: #1b2a4e;
}
.LandingPage .alert-dark hr {
  border-top-color: #14203b;
}
.LandingPage .alert-dark .alert-link {
  color: #0a0e1a;
}
.LandingPage .alert-primary-desat {
  color: #435590;
  background-color: #6c8aec;
  border-color: #6c8aec;
}
.LandingPage .alert-primary-desat hr {
  border-top-color: #5578e9;
}
.LandingPage .alert-primary-desat .alert-link {
  color: #33406d;
}
.LandingPage .alert-black {
  color: #161c2d;
  background-color: #161c2d;
  border-color: #161c2d;
}
.LandingPage .alert-black hr {
  border-top-color: #0e111c;
}
.LandingPage .alert-black .alert-link {
  color: #05070b;
}
.LandingPage .alert-white {
  color: #8f929a;
  background-color: white;
  border-color: white;
}
.LandingPage .alert-white hr {
  border-top-color: #f2f2f2;
}
.LandingPage .alert-white .alert-link {
  color: #757881;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.LandingPage .progress {
  display: -webkit-box;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.79688rem;
  background-color: #f1f4f8;
  border-radius: 0.375rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(22, 28, 45, 0.1);
}
.LandingPage .progress-bar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #335eea;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.LandingPage .progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.LandingPage .media {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}
.LandingPage .media-body {
  -webkit-box-flex: 1;
  flex: 1;
}
.LandingPage .list-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem;
}
.LandingPage .list-group-item-action {
  width: 100%;
  color: #506690;
  text-align: inherit;
}
.LandingPage .list-group-item-action:hover,
.LandingPage .list-group-item-action:focus {
  z-index: 1;
  color: #506690;
  text-decoration: none;
  background-color: #f9fbfd;
}
.LandingPage .list-group-item-action:active {
  color: #161c2d;
  background-color: #f1f4f8;
}
.LandingPage .list-group-item {
  position: relative;
  display: block;
  padding: 1.5rem 2rem;
  background-color: #ffffff;
  border: 1px solid #f1f4f8;
}
.LandingPage .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.LandingPage .list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.LandingPage .list-group-item.disabled,
.LandingPage .list-group-item:disabled {
  color: #869ab8;
  pointer-events: none;
  background-color: #ffffff;
}
.LandingPage .list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .list-group-item + .LandingPage .list-group-item {
  border-top-width: 0;
}
.LandingPage .list-group-item + .LandingPage .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.LandingPage .list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}
.LandingPage .list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
}
.LandingPage .list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
}
.LandingPage .list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.LandingPage .list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.LandingPage .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .LandingPage .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .LandingPage .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .LandingPage .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .LandingPage .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .LandingPage .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .LandingPage .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .LandingPage .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .LandingPage .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .LandingPage .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .LandingPage .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .LandingPage .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .LandingPage .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .LandingPage .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .LandingPage .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .LandingPage .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .LandingPage .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .LandingPage .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .LandingPage .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .LandingPage .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .LandingPage .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .LandingPage .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.LandingPage .list-group-flush {
  border-radius: 0;
}
.LandingPage .list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.LandingPage .list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.LandingPage .list-group-item-primary {
  color: #253e8f;
  background-color: #c6d2f9;
}
.LandingPage .list-group-item-primary.list-group-item-action:hover,
.LandingPage .list-group-item-primary.list-group-item-action:focus {
  color: #253e8f;
  background-color: #afc0f7;
}
.LandingPage .list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #253e8f;
  border-color: #253e8f;
}
.LandingPage .list-group-item-secondary {
  color: #344260;
  background-color: #ced4e0;
}
.LandingPage .list-group-item-secondary.list-group-item-action:hover,
.LandingPage .list-group-item-secondary.list-group-item-action:focus {
  color: #344260;
  background-color: #bec6d6;
}
.LandingPage .list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #344260;
  border-color: #344260;
}
.LandingPage .list-group-item-success {
  color: #2d6e64;
  background-color: #caece2;
}
.LandingPage .list-group-item-success.list-group-item-action:hover,
.LandingPage .list-group-item-success.list-group-item-action:focus {
  color: #2d6e64;
  background-color: #b7e5d8;
}
.LandingPage .list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #2d6e64;
  border-color: #2d6e64;
}
.LandingPage .list-group-item-info {
  color: #4b4492;
  background-color: #dad5fb;
}
.LandingPage .list-group-item-info.list-group-item-action:hover,
.LandingPage .list-group-item-info.list-group-item-action:focus {
  color: #4b4492;
  background-color: #c5bef9;
}
.LandingPage .list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #4b4492;
  border-color: #4b4492;
}
.LandingPage .list-group-item-warning {
  color: #8d7d53;
  background-color: #fef4d9;
}
.LandingPage .list-group-item-warning.list-group-item-action:hover,
.LandingPage .list-group-item-warning.list-group-item-action:focus {
  color: #8d7d53;
  background-color: #fdedc0;
}
.LandingPage .list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #8d7d53;
  border-color: #8d7d53;
}
.LandingPage .list-group-item-danger {
  color: #7f3244;
  background-color: #f6cbd1;
}
.LandingPage .list-group-item-danger.list-group-item-action:hover,
.LandingPage .list-group-item-danger.list-group-item-action:focus {
  color: #7f3244;
  background-color: #f2b5be;
}
.LandingPage .list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f3244;
  border-color: #7f3244;
}
.LandingPage .list-group-item-light {
  color: #8c9099;
  background-color: #fdfefe;
}
.LandingPage .list-group-item-light.list-group-item-action:hover,
.LandingPage .list-group-item-light.list-group-item-action:focus {
  color: #8c9099;
  background-color: #ecf6f6;
}
.LandingPage .list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #8c9099;
  border-color: #8c9099;
}
.LandingPage .list-group-item-dark {
  color: #19233e;
  background-color: #bfc3cd;
}
.LandingPage .list-group-item-dark.list-group-item-action:hover,
.LandingPage .list-group-item-dark.list-group-item-action:focus {
  color: #19233e;
  background-color: #b1b6c2;
}
.LandingPage .list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #19233e;
  border-color: #19233e;
}
.LandingPage .list-group-item-primary-desat {
  color: #435590;
  background-color: #d6defa;
}
.LandingPage .list-group-item-primary-desat.list-group-item-action:hover,
.LandingPage .list-group-item-primary-desat.list-group-item-action:focus {
  color: #435590;
  background-color: #bfccf7;
}
.LandingPage .list-group-item-primary-desat.list-group-item-action.active {
  color: #ffffff;
  background-color: #435590;
  border-color: #435590;
}
.LandingPage .list-group-item-black {
  color: #161c2d;
  background-color: #bebfc4;
}
.LandingPage .list-group-item-black.list-group-item-action:hover,
.LandingPage .list-group-item-black.list-group-item-action:focus {
  color: #161c2d;
  background-color: #b1b2b8;
}
.LandingPage .list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: #161c2d;
  border-color: #161c2d;
}
.LandingPage .list-group-item-white {
  color: #8f929a;
  background-color: white;
}
.LandingPage .list-group-item-white.list-group-item-action:hover,
.LandingPage .list-group-item-white.list-group-item-action:focus {
  color: #8f929a;
  background-color: #f2f2f2;
}
.LandingPage .list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #8f929a;
  border-color: #8f929a;
}
.LandingPage .close {
  float: right;
  font-size: 1.59375rem;
  font-weight: 600;
  line-height: 1;
  color: #869ab8;
  text-shadow: none;
  opacity: 0.5;
}
.LandingPage .close:hover {
  color: #869ab8;
  text-decoration: none;
}
.LandingPage .close:not(:disabled):not(.disabled):hover,
.LandingPage .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
.LandingPage button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.LandingPage a.close.disabled {
  pointer-events: none;
}
.LandingPage .toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(22, 28, 45, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.LandingPage .toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.LandingPage .toast.showing {
  opacity: 1;
}
.LandingPage .toast.show {
  display: block;
  opacity: 1;
}
.LandingPage .toast.hide {
  display: none;
}
.LandingPage .toast-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #869ab8;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.LandingPage .toast-body {
  padding: 0.75rem;
}
.LandingPage .modal-open {
  overflow: hidden;
}
.LandingPage .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.LandingPage .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.LandingPage .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .LandingPage .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .LandingPage .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .LandingPage .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .LandingPage .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.LandingPage .modal-dialog-scrollable {
  display: -webkit-box;
  display: flex;
  max-height: calc(100% - 1rem);
}
.LandingPage .modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.LandingPage .modal-dialog-scrollable .modal-header,
.LandingPage .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.LandingPage .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.LandingPage .modal-dialog-centered {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.LandingPage .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: '';
}
.LandingPage .modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}
.LandingPage .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.LandingPage .modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.LandingPage .modal-content {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(22, 28, 45, 0.2);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(22, 28, 45, 0.5);
  outline: 0;
}
.LandingPage .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #161c2d;
}
.LandingPage .modal-backdrop.fade {
  opacity: 0;
}
.LandingPage .modal-backdrop.show {
  opacity: 0.8;
}
.LandingPage .modal-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f1f4f8;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.LandingPage .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.LandingPage .modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}
.LandingPage .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 2.5rem;
}
.LandingPage .modal-footer {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 2.25rem;
  border-top: 1px solid #f1f4f8;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.LandingPage .modal-footer > * {
  margin: 0.25rem;
}
.LandingPage .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .LandingPage .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .LandingPage .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .LandingPage .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .LandingPage .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .LandingPage .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .LandingPage .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(22, 28, 45, 0.5);
  }
  .LandingPage .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .LandingPage .modal-lg,
  .LandingPage .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .LandingPage .modal-xl {
    max-width: 1140px;
  }
}
.LandingPage .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: 'HKGroteskPro', serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  opacity: 0;
}
.LandingPage .tooltip.show {
  opacity: 0.9;
}
.LandingPage .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.LandingPage .tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.LandingPage .bs-tooltip-top,
.LandingPage .bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0;
}
.LandingPage .bs-tooltip-top .arrow,
.LandingPage .bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}
.LandingPage .bs-tooltip-top .arrow::before,
.LandingPage .bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #161c2d;
}
.LandingPage .bs-tooltip-right,
.LandingPage .bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem;
}
.LandingPage .bs-tooltip-right .arrow,
.LandingPage .bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.LandingPage .bs-tooltip-right .arrow::before,
.LandingPage .bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #161c2d;
}
.LandingPage .bs-tooltip-bottom,
.LandingPage .bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0;
}
.LandingPage .bs-tooltip-bottom .arrow,
.LandingPage .bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}
.LandingPage .bs-tooltip-bottom .arrow::before,
.LandingPage .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #161c2d;
}
.LandingPage .bs-tooltip-left,
.LandingPage .bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem;
}
.LandingPage .bs-tooltip-left .arrow,
.LandingPage .bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.LandingPage .bs-tooltip-left .arrow::before,
.LandingPage .bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #161c2d;
}
.LandingPage .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #161c2d;
  border-radius: 0.375rem;
}
.LandingPage .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 380px;
  font-family: 'HKGroteskPro', serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
}
.LandingPage .popover .arrow {
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 0.625rem;
  margin: 0 0.375rem;
}
.LandingPage .popover .arrow::before,
.LandingPage .popover .arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.LandingPage .bs-popover-top,
.LandingPage .bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.625rem;
}
.LandingPage .bs-popover-top > .arrow,
.LandingPage .bs-popover-auto[x-placement^='top'] > .arrow {
  bottom: calc(-0.625rem - 1px);
}
.LandingPage .bs-popover-top > .arrow::before,
.LandingPage .bs-popover-auto[x-placement^='top'] > .arrow::before {
  bottom: 0;
  border-width: 0.625rem 0.625rem 0;
  border-top-color: rgba(0, 0, 0, 0.05);
}
.LandingPage .bs-popover-top > .arrow::after,
.LandingPage .bs-popover-auto[x-placement^='top'] > .arrow::after {
  bottom: 1px;
  border-width: 0.625rem 0.625rem 0;
  border-top-color: #ffffff;
}
.LandingPage .bs-popover-right,
.LandingPage .bs-popover-auto[x-placement^='right'] {
  margin-left: 0.625rem;
}
.LandingPage .bs-popover-right > .arrow,
.LandingPage .bs-popover-auto[x-placement^='right'] > .arrow {
  left: calc(-0.625rem - 1px);
  width: 0.625rem;
  height: 1.25rem;
  margin: 0.375rem 0;
}
.LandingPage .bs-popover-right > .arrow::before,
.LandingPage .bs-popover-auto[x-placement^='right'] > .arrow::before {
  left: 0;
  border-width: 0.625rem 0.625rem 0.625rem 0;
  border-right-color: rgba(0, 0, 0, 0.05);
}
.LandingPage .bs-popover-right > .arrow::after,
.LandingPage .bs-popover-auto[x-placement^='right'] > .arrow::after {
  left: 1px;
  border-width: 0.625rem 0.625rem 0.625rem 0;
  border-right-color: #ffffff;
}
.LandingPage .bs-popover-bottom,
.LandingPage .bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.625rem;
}
.LandingPage .bs-popover-bottom > .arrow,
.LandingPage .bs-popover-auto[x-placement^='bottom'] > .arrow {
  top: calc(-0.625rem - 1px);
}
.LandingPage .bs-popover-bottom > .arrow::before,
.LandingPage .bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  top: 0;
  border-width: 0 0.625rem 0.625rem 0.625rem;
  border-bottom-color: rgba(0, 0, 0, 0.05);
}
.LandingPage .bs-popover-bottom > .arrow::after,
.LandingPage .bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 1px;
  border-width: 0 0.625rem 0.625rem 0.625rem;
  border-bottom-color: #ffffff;
}
.LandingPage .bs-popover-bottom .popover-header::before,
.LandingPage .bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1.25rem;
  margin-left: -0.625rem;
  content: '';
  border-bottom: 1px solid transparent;
}
.LandingPage .bs-popover-left,
.LandingPage .bs-popover-auto[x-placement^='left'] {
  margin-right: 0.625rem;
}
.LandingPage .bs-popover-left > .arrow,
.LandingPage .bs-popover-auto[x-placement^='left'] > .arrow {
  right: calc(-0.625rem - 1px);
  width: 0.625rem;
  height: 1.25rem;
  margin: 0.375rem 0;
}
.LandingPage .bs-popover-left > .arrow::before,
.LandingPage .bs-popover-auto[x-placement^='left'] > .arrow::before {
  right: 0;
  border-width: 0.625rem 0 0.625rem 0.625rem;
  border-left-color: rgba(0, 0, 0, 0.05);
}
.LandingPage .bs-popover-left > .arrow::after,
.LandingPage .bs-popover-auto[x-placement^='left'] > .arrow::after {
  right: 1px;
  border-width: 0.625rem 0 0.625rem 0.625rem;
  border-left-color: #ffffff;
}
.LandingPage .popover-header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  color: #335eea;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}
.LandingPage .popover-header:empty {
  display: none;
}
.LandingPage .popover-body {
  padding: 1.5rem 2rem;
  color: #869ab8;
}
.LandingPage .carousel {
  position: relative;
}
.LandingPage .carousel.pointer-event {
  touch-action: pan-y;
}
.LandingPage .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.LandingPage .carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}
.LandingPage .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .carousel-item.active,
.LandingPage .carousel-item-next,
.LandingPage .carousel-item-prev {
  display: block;
}
.LandingPage .carousel-item-next:not(.carousel-item-left),
.LandingPage .active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.LandingPage .carousel-item-prev:not(.carousel-item-right),
.LandingPage .active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.LandingPage .carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.LandingPage .carousel-fade .carousel-item.active,
.LandingPage .carousel-fade .carousel-item-next.carousel-item-left,
.LandingPage .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.LandingPage .carousel-fade .active.carousel-item-left,
.LandingPage .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .carousel-fade .active.carousel-item-left,
  .LandingPage .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .carousel-control-prev,
.LandingPage .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .carousel-control-prev,
  .LandingPage .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .carousel-control-prev:hover,
.LandingPage .carousel-control-prev:focus,
.LandingPage .carousel-control-next:hover,
.LandingPage .carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.LandingPage .carousel-control-prev {
  left: 0;
}
.LandingPage .carousel-control-next {
  right: 0;
}
.LandingPage .carousel-control-prev-icon,
.LandingPage .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}
.LandingPage .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.LandingPage .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.LandingPage .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.LandingPage .carousel-indicators li {
  box-sizing: content-box;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .carousel-indicators .active {
  opacity: 1;
}
.LandingPage .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.LandingPage .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.LandingPage .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.LandingPage .spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}
.LandingPage .spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.LandingPage .align-baseline {
  vertical-align: baseline !important;
}
.LandingPage .align-top {
  vertical-align: top !important;
}
.LandingPage .align-middle {
  vertical-align: middle !important;
}
.LandingPage .align-bottom {
  vertical-align: bottom !important;
}
.LandingPage .align-text-bottom {
  vertical-align: text-bottom !important;
}
.LandingPage .align-text-top {
  vertical-align: text-top !important;
}
.LandingPage .bg-primary {
  background-color: #335eea !important;
}
.LandingPage a.bg-primary:hover,
.LandingPage a.bg-primary:focus,
.LandingPage button.bg-primary:hover,
.LandingPage button.bg-primary:focus {
  background-color: #1643d4 !important;
}
.LandingPage .bg-secondary {
  background-color: #506690 !important;
}
.LandingPage a.bg-secondary:hover,
.LandingPage a.bg-secondary:focus,
.LandingPage button.bg-secondary:hover,
.LandingPage button.bg-secondary:focus {
  background-color: #3e4f6f !important;
}
.LandingPage .bg-success {
  background-color: #42ba96 !important;
}
.LandingPage a.bg-success:hover,
.LandingPage a.bg-success:focus,
.LandingPage button.bg-success:hover,
.LandingPage button.bg-success:focus {
  background-color: #359478 !important;
}
.LandingPage .bg-info {
  background-color: #7c69ef !important;
}
.LandingPage a.bg-info:hover,
.LandingPage a.bg-info:focus,
.LandingPage button.bg-info:hover,
.LandingPage button.bg-info:focus {
  background-color: #543bea !important;
}
.LandingPage .bg-warning {
  background-color: #fad776 !important;
}
.LandingPage a.bg-warning:hover,
.LandingPage a.bg-warning:focus,
.LandingPage button.bg-warning:hover,
.LandingPage button.bg-warning:focus {
  background-color: #f8c945 !important;
}
.LandingPage .bg-danger {
  background-color: #df4759 !important;
}
.LandingPage a.bg-danger:hover,
.LandingPage a.bg-danger:focus,
.LandingPage button.bg-danger:hover,
.LandingPage button.bg-danger:focus {
  background-color: #cf2438 !important;
}
.LandingPage .bg-light {
  background-color: #f9fbfd !important;
}
.LandingPage a.bg-light:hover,
.LandingPage a.bg-light:focus,
.LandingPage button.bg-light:hover,
.LandingPage button.bg-light:focus {
  background-color: #d3e2f0 !important;
}
.LandingPage .bg-dark {
  background-color: #1b2a4e !important;
}
.LandingPage a.bg-dark:hover,
.LandingPage a.bg-dark:focus,
.LandingPage button.bg-dark:hover,
.LandingPage button.bg-dark:focus {
  background-color: #0e1628 !important;
}
.LandingPage .bg-primary-desat {
  background-color: #6c8aec !important;
}
.LandingPage a.bg-primary-desat:hover,
.LandingPage a.bg-primary-desat:focus,
.LandingPage button.bg-primary-desat:hover,
.LandingPage button.bg-primary-desat:focus {
  background-color: #3f66e6 !important;
}
.LandingPage .bg-black {
  background-color: #161c2d !important;
}
.LandingPage a.bg-black:hover,
.LandingPage a.bg-black:focus,
.LandingPage button.bg-black:hover,
.LandingPage button.bg-black:focus {
  background-color: #05070b !important;
}
.LandingPage .bg-white {
  background-color: #ffffff !important;
}
.LandingPage a.bg-white:hover,
.LandingPage a.bg-white:focus,
.LandingPage button.bg-white:hover,
.LandingPage button.bg-white:focus {
  background-color: #e6e6e6 !important;
}
.LandingPage .bg-white {
  background-color: #ffffff !important;
}
.LandingPage .bg-transparent {
  background-color: transparent !important;
}
.LandingPage .border {
  border: 1px solid #f1f4f8 !important;
}
.LandingPage .border-top {
  border-top: 1px solid #f1f4f8 !important;
}
.LandingPage .border-right {
  border-right: 1px solid #f1f4f8 !important;
}
.LandingPage .border-bottom {
  border-bottom: 1px solid #f1f4f8 !important;
}
.LandingPage .border-left {
  border-left: 1px solid #f1f4f8 !important;
}
.LandingPage .border-0 {
  border: 0 !important;
}
.LandingPage .border-top-0 {
  border-top: 0 !important;
}
.LandingPage .border-right-0 {
  border-right: 0 !important;
}
.LandingPage .border-bottom-0 {
  border-bottom: 0 !important;
}
.LandingPage .border-left-0 {
  border-left: 0 !important;
}
.LandingPage .border-primary {
  border-color: #335eea !important;
}
.LandingPage .border-secondary {
  border-color: #506690 !important;
}
.LandingPage .border-success {
  border-color: #42ba96 !important;
}
.LandingPage .border-info {
  border-color: #7c69ef !important;
}
.LandingPage .border-warning {
  border-color: #fad776 !important;
}
.LandingPage .border-danger {
  border-color: #df4759 !important;
}
.LandingPage .border-light {
  border-color: #f9fbfd !important;
}
.LandingPage .border-dark {
  border-color: #1b2a4e !important;
}
.LandingPage .border-primary-desat {
  border-color: #6c8aec !important;
}
.LandingPage .border-black {
  border-color: #161c2d !important;
}
.LandingPage .border-white {
  border-color: #ffffff !important;
}
.LandingPage .border-white {
  border-color: #ffffff !important;
}
.LandingPage .rounded-sm {
  border-radius: 0.2rem !important;
}
.LandingPage .rounded {
  border-radius: 0.375rem !important;
}
.LandingPage .rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}
.LandingPage .rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
.LandingPage .rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.LandingPage .rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.LandingPage .rounded-lg {
  border-radius: 0.5rem !important;
}
.LandingPage .rounded-circle {
  border-radius: 50% !important;
}
.LandingPage .rounded-pill {
  border-radius: 50rem !important;
}
.LandingPage .rounded-0 {
  border-radius: 0 !important;
}
.LandingPage .clearfix::after {
  display: block;
  clear: both;
  content: '';
}
.LandingPage .d-none {
  display: none !important;
}
.LandingPage .d-inline {
  display: inline !important;
}
.LandingPage .d-inline-block {
  display: inline-block !important;
}
.LandingPage .d-block {
  display: block !important;
}
.LandingPage .d-table {
  display: table !important;
}
.LandingPage .d-table-row {
  display: table-row !important;
}
.LandingPage .d-table-cell {
  display: table-cell !important;
}
.LandingPage .d-flex {
  display: -webkit-box !important;
  display: flex !important;
}
.LandingPage .d-inline-flex {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .LandingPage .d-sm-none {
    display: none !important;
  }
  .LandingPage .d-sm-inline {
    display: inline !important;
  }
  .LandingPage .d-sm-inline-block {
    display: inline-block !important;
  }
  .LandingPage .d-sm-block {
    display: block !important;
  }
  .LandingPage .d-sm-table {
    display: table !important;
  }
  .LandingPage .d-sm-table-row {
    display: table-row !important;
  }
  .LandingPage .d-sm-table-cell {
    display: table-cell !important;
  }
  .LandingPage .d-sm-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .LandingPage .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .d-md-none {
    display: none !important;
  }
  .LandingPage .d-md-inline {
    display: inline !important;
  }
  .LandingPage .d-md-inline-block {
    display: inline-block !important;
  }
  .LandingPage .d-md-block {
    display: block !important;
  }
  .LandingPage .d-md-table {
    display: table !important;
  }
  .LandingPage .d-md-table-row {
    display: table-row !important;
  }
  .LandingPage .d-md-table-cell {
    display: table-cell !important;
  }
  .LandingPage .d-md-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .LandingPage .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .LandingPage .d-lg-none {
    display: none !important;
  }
  .LandingPage .d-lg-inline {
    display: inline !important;
  }
  .LandingPage .d-lg-inline-block {
    display: inline-block !important;
  }
  .LandingPage .d-lg-block {
    display: block !important;
  }
  .LandingPage .d-lg-table {
    display: table !important;
  }
  .LandingPage .d-lg-table-row {
    display: table-row !important;
  }
  .LandingPage .d-lg-table-cell {
    display: table-cell !important;
  }
  .LandingPage .d-lg-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .LandingPage .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .LandingPage .d-xl-none {
    display: none !important;
  }
  .LandingPage .d-xl-inline {
    display: inline !important;
  }
  .LandingPage .d-xl-inline-block {
    display: inline-block !important;
  }
  .LandingPage .d-xl-block {
    display: block !important;
  }
  .LandingPage .d-xl-table {
    display: table !important;
  }
  .LandingPage .d-xl-table-row {
    display: table-row !important;
  }
  .LandingPage .d-xl-table-cell {
    display: table-cell !important;
  }
  .LandingPage .d-xl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .LandingPage .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media print {
  .LandingPage .d-print-none {
    display: none !important;
  }
  .LandingPage .d-print-inline {
    display: inline !important;
  }
  .LandingPage .d-print-inline-block {
    display: inline-block !important;
  }
  .LandingPage .d-print-block {
    display: block !important;
  }
  .LandingPage .d-print-table {
    display: table !important;
  }
  .LandingPage .d-print-table-row {
    display: table-row !important;
  }
  .LandingPage .d-print-table-cell {
    display: table-cell !important;
  }
  .LandingPage .d-print-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .LandingPage .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
.LandingPage .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.LandingPage .embed-responsive::before {
  display: block;
  content: '';
}
.LandingPage .embed-responsive .embed-responsive-item,
.LandingPage .embed-responsive iframe,
.LandingPage .embed-responsive embed,
.LandingPage .embed-responsive object,
.LandingPage .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.LandingPage .embed-responsive-21by9::before {
  padding-top: 42.85714%;
}
.LandingPage .embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.LandingPage .embed-responsive-4by3::before {
  padding-top: 75%;
}
.LandingPage .embed-responsive-1by1::before {
  padding-top: 100%;
}
.LandingPage .flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
}
.LandingPage .flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}
.LandingPage .flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  flex-direction: row-reverse !important;
}
.LandingPage .flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  flex-direction: column-reverse !important;
}
.LandingPage .flex-wrap {
  flex-wrap: wrap !important;
}
.LandingPage .flex-nowrap {
  flex-wrap: nowrap !important;
}
.LandingPage .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.LandingPage .flex-fill {
  -webkit-box-flex: 1 !important;
  flex: 1 1 auto !important;
}
.LandingPage .flex-grow-0 {
  -webkit-box-flex: 0 !important;
  flex-grow: 0 !important;
}
.LandingPage .flex-grow-1 {
  -webkit-box-flex: 1 !important;
  flex-grow: 1 !important;
}
.LandingPage .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.LandingPage .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.LandingPage .justify-content-start {
  -webkit-box-pack: start !important;
  justify-content: flex-start !important;
}
.LandingPage .justify-content-end {
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
}
.LandingPage .justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
}
.LandingPage .justify-content-between {
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
}
.LandingPage .justify-content-around {
  justify-content: space-around !important;
}
.LandingPage .align-items-start {
  -webkit-box-align: start !important;
  align-items: flex-start !important;
}
.LandingPage .align-items-end {
  -webkit-box-align: end !important;
  align-items: flex-end !important;
}
.LandingPage .align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important;
}
.LandingPage .align-items-baseline {
  -webkit-box-align: baseline !important;
  align-items: baseline !important;
}
.LandingPage .align-items-stretch {
  -webkit-box-align: stretch !important;
  align-items: stretch !important;
}
.LandingPage .align-content-start {
  align-content: flex-start !important;
}
.LandingPage .align-content-end {
  align-content: flex-end !important;
}
.LandingPage .align-content-center {
  align-content: center !important;
}
.LandingPage .align-content-between {
  align-content: space-between !important;
}
.LandingPage .align-content-around {
  align-content: space-around !important;
}
.LandingPage .align-content-stretch {
  align-content: stretch !important;
}
.LandingPage .align-self-auto {
  align-self: auto !important;
}
.LandingPage .align-self-start {
  align-self: flex-start !important;
}
.LandingPage .align-self-end {
  align-self: flex-end !important;
}
.LandingPage .align-self-center {
  align-self: center !important;
}
.LandingPage .align-self-baseline {
  align-self: baseline !important;
}
.LandingPage .align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .LandingPage .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .LandingPage .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .LandingPage .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .LandingPage .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .LandingPage .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .LandingPage .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .LandingPage .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .LandingPage .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .LandingPage .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .LandingPage .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .LandingPage .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .LandingPage .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .LandingPage .justify-content-sm-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .LandingPage .justify-content-sm-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .LandingPage .justify-content-sm-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .LandingPage .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .LandingPage .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .LandingPage .align-items-sm-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .LandingPage .align-items-sm-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .LandingPage .align-items-sm-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .LandingPage .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .LandingPage .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .LandingPage .align-content-sm-start {
    align-content: flex-start !important;
  }
  .LandingPage .align-content-sm-end {
    align-content: flex-end !important;
  }
  .LandingPage .align-content-sm-center {
    align-content: center !important;
  }
  .LandingPage .align-content-sm-between {
    align-content: space-between !important;
  }
  .LandingPage .align-content-sm-around {
    align-content: space-around !important;
  }
  .LandingPage .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .LandingPage .align-self-sm-auto {
    align-self: auto !important;
  }
  .LandingPage .align-self-sm-start {
    align-self: flex-start !important;
  }
  .LandingPage .align-self-sm-end {
    align-self: flex-end !important;
  }
  .LandingPage .align-self-sm-center {
    align-self: center !important;
  }
  .LandingPage .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .LandingPage .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .LandingPage .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .LandingPage .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .LandingPage .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .LandingPage .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .LandingPage .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .LandingPage .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .LandingPage .flex-md-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .LandingPage .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .LandingPage .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .LandingPage .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .LandingPage .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .LandingPage .justify-content-md-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .LandingPage .justify-content-md-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .LandingPage .justify-content-md-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .LandingPage .justify-content-md-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .LandingPage .justify-content-md-around {
    justify-content: space-around !important;
  }
  .LandingPage .align-items-md-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .LandingPage .align-items-md-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .LandingPage .align-items-md-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .LandingPage .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .LandingPage .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .LandingPage .align-content-md-start {
    align-content: flex-start !important;
  }
  .LandingPage .align-content-md-end {
    align-content: flex-end !important;
  }
  .LandingPage .align-content-md-center {
    align-content: center !important;
  }
  .LandingPage .align-content-md-between {
    align-content: space-between !important;
  }
  .LandingPage .align-content-md-around {
    align-content: space-around !important;
  }
  .LandingPage .align-content-md-stretch {
    align-content: stretch !important;
  }
  .LandingPage .align-self-md-auto {
    align-self: auto !important;
  }
  .LandingPage .align-self-md-start {
    align-self: flex-start !important;
  }
  .LandingPage .align-self-md-end {
    align-self: flex-end !important;
  }
  .LandingPage .align-self-md-center {
    align-self: center !important;
  }
  .LandingPage .align-self-md-baseline {
    align-self: baseline !important;
  }
  .LandingPage .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .LandingPage .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .LandingPage .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .LandingPage .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .LandingPage .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .LandingPage .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .LandingPage .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .LandingPage .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .LandingPage .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .LandingPage .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .LandingPage .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .LandingPage .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .LandingPage .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .LandingPage .justify-content-lg-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .LandingPage .justify-content-lg-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .LandingPage .justify-content-lg-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .LandingPage .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .LandingPage .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .LandingPage .align-items-lg-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .LandingPage .align-items-lg-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .LandingPage .align-items-lg-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .LandingPage .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .LandingPage .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .LandingPage .align-content-lg-start {
    align-content: flex-start !important;
  }
  .LandingPage .align-content-lg-end {
    align-content: flex-end !important;
  }
  .LandingPage .align-content-lg-center {
    align-content: center !important;
  }
  .LandingPage .align-content-lg-between {
    align-content: space-between !important;
  }
  .LandingPage .align-content-lg-around {
    align-content: space-around !important;
  }
  .LandingPage .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .LandingPage .align-self-lg-auto {
    align-self: auto !important;
  }
  .LandingPage .align-self-lg-start {
    align-self: flex-start !important;
  }
  .LandingPage .align-self-lg-end {
    align-self: flex-end !important;
  }
  .LandingPage .align-self-lg-center {
    align-self: center !important;
  }
  .LandingPage .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .LandingPage .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .LandingPage .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .LandingPage .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .LandingPage .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .LandingPage .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .LandingPage .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .LandingPage .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .LandingPage .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .LandingPage .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .LandingPage .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .LandingPage .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .LandingPage .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .LandingPage .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .LandingPage .justify-content-xl-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .LandingPage .justify-content-xl-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .LandingPage .justify-content-xl-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .LandingPage .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .LandingPage .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .LandingPage .align-items-xl-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .LandingPage .align-items-xl-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .LandingPage .align-items-xl-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .LandingPage .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .LandingPage .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .LandingPage .align-content-xl-start {
    align-content: flex-start !important;
  }
  .LandingPage .align-content-xl-end {
    align-content: flex-end !important;
  }
  .LandingPage .align-content-xl-center {
    align-content: center !important;
  }
  .LandingPage .align-content-xl-between {
    align-content: space-between !important;
  }
  .LandingPage .align-content-xl-around {
    align-content: space-around !important;
  }
  .LandingPage .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .LandingPage .align-self-xl-auto {
    align-self: auto !important;
  }
  .LandingPage .align-self-xl-start {
    align-self: flex-start !important;
  }
  .LandingPage .align-self-xl-end {
    align-self: flex-end !important;
  }
  .LandingPage .align-self-xl-center {
    align-self: center !important;
  }
  .LandingPage .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .LandingPage .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.LandingPage .float-left {
  float: left !important;
}
.LandingPage .float-right {
  float: right !important;
}
.LandingPage .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .LandingPage .float-sm-left {
    float: left !important;
  }
  .LandingPage .float-sm-right {
    float: right !important;
  }
  .LandingPage .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .float-md-left {
    float: left !important;
  }
  .LandingPage .float-md-right {
    float: right !important;
  }
  .LandingPage .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .LandingPage .float-lg-left {
    float: left !important;
  }
  .LandingPage .float-lg-right {
    float: right !important;
  }
  .LandingPage .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .LandingPage .float-xl-left {
    float: left !important;
  }
  .LandingPage .float-xl-right {
    float: right !important;
  }
  .LandingPage .float-xl-none {
    float: none !important;
  }
}
.LandingPage .user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}
.LandingPage .user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.LandingPage .user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.LandingPage .overflow-auto {
  overflow: auto !important;
}
.LandingPage .overflow-hidden {
  overflow: hidden !important;
}
.LandingPage .position-static {
  position: static !important;
}
.LandingPage .position-relative {
  position: relative !important;
}
.LandingPage .position-absolute {
  position: absolute !important;
}
.LandingPage .position-fixed {
  position: fixed !important;
}
.LandingPage .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.LandingPage .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.LandingPage .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .LandingPage .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.LandingPage .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.LandingPage .sr-only-focusable:active,
.LandingPage .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.LandingPage .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075) !important;
}
.LandingPage .shadow {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important;
}
.LandingPage .shadow-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1) !important;
}
.LandingPage .shadow-none {
  box-shadow: none !important;
}
.LandingPage .w-25 {
  width: 25% !important;
}
.LandingPage .w-50 {
  width: 50% !important;
}
.LandingPage .w-75 {
  width: 75% !important;
}
.LandingPage .w-100 {
  width: 100% !important;
}
.LandingPage .w-auto {
  width: auto !important;
}
.LandingPage .w-110 {
  width: 110% !important;
}
.LandingPage .w-120 {
  width: 120% !important;
}
.LandingPage .w-130 {
  width: 130% !important;
}
.LandingPage .w-140 {
  width: 140% !important;
}
.LandingPage .w-150 {
  width: 150% !important;
}
.LandingPage .h-25 {
  height: 25% !important;
}
.LandingPage .h-50 {
  height: 50% !important;
}
.LandingPage .h-75 {
  height: 75% !important;
}
.LandingPage .h-100 {
  height: 100% !important;
}
.LandingPage .h-auto {
  height: auto !important;
}
.LandingPage .h-110 {
  height: 110% !important;
}
.LandingPage .h-120 {
  height: 120% !important;
}
.LandingPage .h-130 {
  height: 130% !important;
}
.LandingPage .h-140 {
  height: 140% !important;
}
.LandingPage .h-150 {
  height: 150% !important;
}
.LandingPage .mw-100 {
  max-width: 100% !important;
}
.LandingPage .mh-100 {
  max-height: 100% !important;
}
.LandingPage .min-vw-100 {
  min-width: 100vw !important;
}
.LandingPage .min-vh-100 {
  min-height: 100vh !important;
}
.LandingPage .vw-100 {
  width: 100vw !important;
}
.LandingPage .vh-100 {
  height: 100vh !important;
}
.LandingPage .m-0 {
  margin: 0 !important;
}
.LandingPage .mt-0,
.LandingPage .my-0 {
  margin-top: 0 !important;
}
.LandingPage .mr-0,
.LandingPage .mx-0 {
  margin-right: 0 !important;
}
.LandingPage .mb-0,
.LandingPage .my-0 {
  margin-bottom: 0 !important;
}
.LandingPage .ml-0,
.LandingPage .mx-0 {
  margin-left: 0 !important;
}
.LandingPage .m-1 {
  margin: 0.25rem !important;
}
.LandingPage .mt-1,
.LandingPage .my-1 {
  margin-top: 0.25rem !important;
}
.LandingPage .mr-1,
.LandingPage .mx-1 {
  margin-right: 0.25rem !important;
}
.LandingPage .mb-1,
.LandingPage .my-1 {
  margin-bottom: 0.25rem !important;
}
.LandingPage .ml-1,
.LandingPage .mx-1 {
  margin-left: 0.25rem !important;
}
.LandingPage .m-2 {
  margin: 0.5rem !important;
}
.LandingPage .mt-2,
.LandingPage .my-2 {
  margin-top: 0.5rem !important;
}
.LandingPage .mr-2,
.LandingPage .mx-2 {
  margin-right: 0.5rem !important;
}
.LandingPage .mb-2,
.LandingPage .my-2 {
  margin-bottom: 0.5rem !important;
}
.LandingPage .ml-2,
.LandingPage .mx-2 {
  margin-left: 0.5rem !important;
}
.LandingPage .m-3 {
  margin: 0.75rem !important;
}
.LandingPage .mt-3,
.LandingPage .my-3 {
  margin-top: 0.75rem !important;
}
.LandingPage .mr-3,
.LandingPage .mx-3 {
  margin-right: 0.75rem !important;
}
.LandingPage .mb-3,
.LandingPage .my-3 {
  margin-bottom: 0.75rem !important;
}
.LandingPage .ml-3,
.LandingPage .mx-3 {
  margin-left: 0.75rem !important;
}
.LandingPage .m-4 {
  margin: 1rem !important;
}
.LandingPage .mt-4,
.LandingPage .my-4 {
  margin-top: 1rem !important;
}
.LandingPage .mr-4,
.LandingPage .mx-4 {
  margin-right: 1rem !important;
}
.LandingPage .mb-4,
.LandingPage .my-4 {
  margin-bottom: 1rem !important;
}
.LandingPage .ml-4,
.LandingPage .mx-4 {
  margin-left: 1rem !important;
}
.LandingPage .m-5 {
  margin: 1.5rem !important;
}
.LandingPage .mt-5,
.LandingPage .my-5 {
  margin-top: 1.5rem !important;
}
.LandingPage .mr-5,
.LandingPage .mx-5 {
  margin-right: 1.5rem !important;
}
.LandingPage .mb-5,
.LandingPage .my-5 {
  margin-bottom: 1.5rem !important;
}
.LandingPage .ml-5,
.LandingPage .mx-5 {
  margin-left: 1.5rem !important;
}
.LandingPage .m-6 {
  margin: 2rem !important;
}
.LandingPage .mt-6,
.LandingPage .my-6 {
  margin-top: 2rem !important;
}
.LandingPage .mr-6,
.LandingPage .mx-6 {
  margin-right: 2rem !important;
}
.LandingPage .mb-6,
.LandingPage .my-6 {
  margin-bottom: 2rem !important;
}
.LandingPage .ml-6,
.LandingPage .mx-6 {
  margin-left: 2rem !important;
}
.LandingPage .m-7 {
  margin: 2.5rem !important;
}
.LandingPage .mt-7,
.LandingPage .my-7 {
  margin-top: 2.5rem !important;
}
.LandingPage .mr-7,
.LandingPage .mx-7 {
  margin-right: 2.5rem !important;
}
.LandingPage .mb-7,
.LandingPage .my-7 {
  margin-bottom: 2.5rem !important;
}
.LandingPage .ml-7,
.LandingPage .mx-7 {
  margin-left: 2.5rem !important;
}
.LandingPage .m-8 {
  margin: 3rem !important;
}
.LandingPage .mt-8,
.LandingPage .my-8 {
  margin-top: 3rem !important;
}
.LandingPage .mr-8,
.LandingPage .mx-8 {
  margin-right: 3rem !important;
}
.LandingPage .mb-8,
.LandingPage .my-8 {
  margin-bottom: 3rem !important;
}
.LandingPage .ml-8,
.LandingPage .mx-8 {
  margin-left: 3rem !important;
}
.LandingPage .m-9 {
  margin: 4rem !important;
}
.LandingPage .mt-9,
.LandingPage .my-9 {
  margin-top: 4rem !important;
}
.LandingPage .mr-9,
.LandingPage .mx-9 {
  margin-right: 4rem !important;
}
.LandingPage .mb-9,
.LandingPage .my-9 {
  margin-bottom: 4rem !important;
}
.LandingPage .ml-9,
.LandingPage .mx-9 {
  margin-left: 4rem !important;
}
.LandingPage .m-10 {
  margin: 5rem !important;
}
.LandingPage .mt-10,
.LandingPage .my-10 {
  margin-top: 5rem !important;
}
.LandingPage .mr-10,
.LandingPage .mx-10 {
  margin-right: 5rem !important;
}
.LandingPage .mb-10,
.LandingPage .my-10 {
  margin-bottom: 5rem !important;
}
.LandingPage .ml-10,
.LandingPage .mx-10 {
  margin-left: 5rem !important;
}
.LandingPage .m-11 {
  margin: 6rem !important;
}
.LandingPage .mt-11,
.LandingPage .my-11 {
  margin-top: 6rem !important;
}
.LandingPage .mr-11,
.LandingPage .mx-11 {
  margin-right: 6rem !important;
}
.LandingPage .mb-11,
.LandingPage .my-11 {
  margin-bottom: 6rem !important;
}
.LandingPage .ml-11,
.LandingPage .mx-11 {
  margin-left: 6rem !important;
}
.LandingPage .m-12 {
  margin: 8rem !important;
}
.LandingPage .mt-12,
.LandingPage .my-12 {
  margin-top: 8rem !important;
}
.LandingPage .mr-12,
.LandingPage .mx-12 {
  margin-right: 8rem !important;
}
.LandingPage .mb-12,
.LandingPage .my-12 {
  margin-bottom: 8rem !important;
}
.LandingPage .ml-12,
.LandingPage .mx-12 {
  margin-left: 8rem !important;
}
.LandingPage .m-13 {
  margin: 10rem !important;
}
.LandingPage .mt-13,
.LandingPage .my-13 {
  margin-top: 10rem !important;
}
.LandingPage .mr-13,
.LandingPage .mx-13 {
  margin-right: 10rem !important;
}
.LandingPage .mb-13,
.LandingPage .my-13 {
  margin-bottom: 10rem !important;
}
.LandingPage .ml-13,
.LandingPage .mx-13 {
  margin-left: 10rem !important;
}
.LandingPage .m-14 {
  margin: 12rem !important;
}
.LandingPage .mt-14,
.LandingPage .my-14 {
  margin-top: 12rem !important;
}
.LandingPage .mr-14,
.LandingPage .mx-14 {
  margin-right: 12rem !important;
}
.LandingPage .mb-14,
.LandingPage .my-14 {
  margin-bottom: 12rem !important;
}
.LandingPage .ml-14,
.LandingPage .mx-14 {
  margin-left: 12rem !important;
}
.LandingPage .m-15 {
  margin: 16rem !important;
}
.LandingPage .mt-15,
.LandingPage .my-15 {
  margin-top: 16rem !important;
}
.LandingPage .mr-15,
.LandingPage .mx-15 {
  margin-right: 16rem !important;
}
.LandingPage .mb-15,
.LandingPage .my-15 {
  margin-bottom: 16rem !important;
}
.LandingPage .ml-15,
.LandingPage .mx-15 {
  margin-left: 16rem !important;
}
.LandingPage .m-16 {
  margin: 25rem !important;
}
.LandingPage .mt-16,
.LandingPage .my-16 {
  margin-top: 25rem !important;
}
.LandingPage .mr-16,
.LandingPage .mx-16 {
  margin-right: 25rem !important;
}
.LandingPage .mb-16,
.LandingPage .my-16 {
  margin-bottom: 25rem !important;
}
.LandingPage .ml-16,
.LandingPage .mx-16 {
  margin-left: 25rem !important;
}
.LandingPage .p-0 {
  padding: 0 !important;
}
.LandingPage .pt-0,
.LandingPage .py-0 {
  padding-top: 0 !important;
}
.LandingPage .pr-0,
.LandingPage .px-0 {
  padding-right: 0 !important;
}
.LandingPage .pb-0,
.LandingPage .py-0 {
  padding-bottom: 0 !important;
}
.LandingPage .pl-0,
.LandingPage .px-0 {
  padding-left: 0 !important;
}
.LandingPage .p-1 {
  padding: 0.25rem !important;
}
.LandingPage .pt-1,
.LandingPage .py-1 {
  padding-top: 0.25rem !important;
}
.LandingPage .pr-1,
.LandingPage .px-1 {
  padding-right: 0.25rem !important;
}
.LandingPage .pb-1,
.LandingPage .py-1 {
  padding-bottom: 0.25rem !important;
}
.LandingPage .pl-1,
.LandingPage .px-1 {
  padding-left: 0.25rem !important;
}
.LandingPage .p-2 {
  padding: 0.5rem !important;
}
.LandingPage .pt-2,
.LandingPage .py-2 {
  padding-top: 0.5rem !important;
}
.LandingPage .pr-2,
.LandingPage .px-2 {
  padding-right: 0.5rem !important;
}
.LandingPage .pb-2,
.LandingPage .py-2 {
  padding-bottom: 0.5rem !important;
}
.LandingPage .pl-2,
.LandingPage .px-2 {
  padding-left: 0.5rem !important;
}
.LandingPage .p-3 {
  padding: 0.75rem !important;
}
.LandingPage .pt-3,
.LandingPage .py-3 {
  padding-top: 0.75rem !important;
}
.LandingPage .pr-3,
.LandingPage .px-3 {
  padding-right: 0.75rem !important;
}
.LandingPage .pb-3,
.LandingPage .py-3 {
  padding-bottom: 0.75rem !important;
}
.LandingPage .pl-3,
.LandingPage .px-3 {
  padding-left: 0.75rem !important;
}
.LandingPage .p-4 {
  padding: 1rem !important;
}
.LandingPage .pt-4,
.LandingPage .py-4 {
  padding-top: 1rem !important;
}
.LandingPage .pr-4,
.LandingPage .px-4 {
  padding-right: 1rem !important;
}
.LandingPage .pb-4,
.LandingPage .py-4 {
  padding-bottom: 1rem !important;
}
.LandingPage .pl-4,
.LandingPage .px-4 {
  padding-left: 1rem !important;
}
.LandingPage .p-5 {
  padding: 1.5rem !important;
}
.LandingPage .pt-5,
.LandingPage .py-5 {
  padding-top: 1.5rem !important;
}
.LandingPage .pr-5,
.LandingPage .px-5 {
  padding-right: 1.5rem !important;
}
.LandingPage .pb-5,
.LandingPage .py-5 {
  padding-bottom: 1.5rem !important;
}
.LandingPage .pl-5,
.LandingPage .px-5 {
  padding-left: 1.5rem !important;
}
.LandingPage .p-6 {
  padding: 2rem !important;
}
.LandingPage .pt-6,
.LandingPage .py-6 {
  padding-top: 2rem !important;
}
.LandingPage .pr-6,
.LandingPage .px-6 {
  padding-right: 2rem !important;
}
.LandingPage .pb-6,
.LandingPage .py-6 {
  padding-bottom: 2rem !important;
}
.LandingPage .pl-6,
.LandingPage .px-6 {
  padding-left: 2rem !important;
}
.LandingPage .p-7 {
  padding: 2.5rem !important;
}
.LandingPage .pt-7,
.LandingPage .py-7 {
  padding-top: 2.5rem !important;
}
.LandingPage .pr-7,
.LandingPage .px-7 {
  padding-right: 2.5rem !important;
}
.LandingPage .pb-7,
.LandingPage .py-7 {
  padding-bottom: 2.5rem !important;
}
.LandingPage .pl-7,
.LandingPage .px-7 {
  padding-left: 2.5rem !important;
}
.LandingPage .p-8 {
  padding: 3rem !important;
}
.LandingPage .pt-8,
.LandingPage .py-8 {
  padding-top: 3rem !important;
}
.LandingPage .pr-8,
.LandingPage .px-8 {
  padding-right: 3rem !important;
}
.LandingPage .pb-8,
.LandingPage .py-8 {
  padding-bottom: 3rem !important;
}
.LandingPage .pl-8,
.LandingPage .px-8 {
  padding-left: 3rem !important;
}
.LandingPage .p-9 {
  padding: 4rem !important;
}
.LandingPage .pt-9,
.LandingPage .py-9 {
  padding-top: 4rem !important;
}
.LandingPage .pr-9,
.LandingPage .px-9 {
  padding-right: 4rem !important;
}
.LandingPage .pb-9,
.LandingPage .py-9 {
  padding-bottom: 4rem !important;
}
.LandingPage .pl-9,
.LandingPage .px-9 {
  padding-left: 4rem !important;
}
.LandingPage .p-10 {
  padding: 5rem !important;
}
.LandingPage .pt-10,
.LandingPage .py-10 {
  padding-top: 5rem !important;
}
.LandingPage .pr-10,
.LandingPage .px-10 {
  padding-right: 5rem !important;
}
.LandingPage .pb-10,
.LandingPage .py-10 {
  padding-bottom: 5rem !important;
}
.LandingPage .pl-10,
.LandingPage .px-10 {
  padding-left: 5rem !important;
}
.LandingPage .p-11 {
  padding: 6rem !important;
}
.LandingPage .pt-11,
.LandingPage .py-11 {
  padding-top: 6rem !important;
}
.LandingPage .pr-11,
.LandingPage .px-11 {
  padding-right: 6rem !important;
}
.LandingPage .pb-11,
.LandingPage .py-11 {
  padding-bottom: 6rem !important;
}
.LandingPage .pl-11,
.LandingPage .px-11 {
  padding-left: 6rem !important;
}
.LandingPage .p-12 {
  padding: 8rem !important;
}
.LandingPage .pt-12,
.LandingPage .py-12 {
  padding-top: 8rem !important;
}
.LandingPage .pr-12,
.LandingPage .px-12 {
  padding-right: 8rem !important;
}
.LandingPage .pb-12,
.LandingPage .py-12 {
  padding-bottom: 8rem !important;
}
.LandingPage .pl-12,
.LandingPage .px-12 {
  padding-left: 8rem !important;
}
.LandingPage .p-13 {
  padding: 10rem !important;
}
.LandingPage .pt-13,
.LandingPage .py-13 {
  padding-top: 10rem !important;
}
.LandingPage .pr-13,
.LandingPage .px-13 {
  padding-right: 10rem !important;
}
.LandingPage .pb-13,
.LandingPage .py-13 {
  padding-bottom: 10rem !important;
}
.LandingPage .pl-13,
.LandingPage .px-13 {
  padding-left: 10rem !important;
}
.LandingPage .p-14 {
  padding: 12rem !important;
}
.LandingPage .pt-14,
.LandingPage .py-14 {
  padding-top: 12rem !important;
}
.LandingPage .pr-14,
.LandingPage .px-14 {
  padding-right: 12rem !important;
}
.LandingPage .pb-14,
.LandingPage .py-14 {
  padding-bottom: 12rem !important;
}
.LandingPage .pl-14,
.LandingPage .px-14 {
  padding-left: 12rem !important;
}
.LandingPage .p-15 {
  padding: 16rem !important;
}
.LandingPage .pt-15,
.LandingPage .py-15 {
  padding-top: 16rem !important;
}
.LandingPage .pr-15,
.LandingPage .px-15 {
  padding-right: 16rem !important;
}
.LandingPage .pb-15,
.LandingPage .py-15 {
  padding-bottom: 16rem !important;
}
.LandingPage .pl-15,
.LandingPage .px-15 {
  padding-left: 16rem !important;
}
.LandingPage .p-16 {
  padding: 25rem !important;
}
.LandingPage .pt-16,
.LandingPage .py-16 {
  padding-top: 25rem !important;
}
.LandingPage .pr-16,
.LandingPage .px-16 {
  padding-right: 25rem !important;
}
.LandingPage .pb-16,
.LandingPage .py-16 {
  padding-bottom: 25rem !important;
}
.LandingPage .pl-16,
.LandingPage .px-16 {
  padding-left: 25rem !important;
}
.LandingPage .m-n1 {
  margin: -0.25rem !important;
}
.LandingPage .mt-n1,
.LandingPage .my-n1 {
  margin-top: -0.25rem !important;
}
.LandingPage .mr-n1,
.LandingPage .mx-n1 {
  margin-right: -0.25rem !important;
}
.LandingPage .mb-n1,
.LandingPage .my-n1 {
  margin-bottom: -0.25rem !important;
}
.LandingPage .ml-n1,
.LandingPage .mx-n1 {
  margin-left: -0.25rem !important;
}
.LandingPage .m-n2 {
  margin: -0.5rem !important;
}
.LandingPage .mt-n2,
.LandingPage .my-n2 {
  margin-top: -0.5rem !important;
}
.LandingPage .mr-n2,
.LandingPage .mx-n2 {
  margin-right: -0.5rem !important;
}
.LandingPage .mb-n2,
.LandingPage .my-n2 {
  margin-bottom: -0.5rem !important;
}
.LandingPage .ml-n2,
.LandingPage .mx-n2 {
  margin-left: -0.5rem !important;
}
.LandingPage .m-n3 {
  margin: -0.75rem !important;
}
.LandingPage .mt-n3,
.LandingPage .my-n3 {
  margin-top: -0.75rem !important;
}
.LandingPage .mr-n3,
.LandingPage .mx-n3 {
  margin-right: -0.75rem !important;
}
.LandingPage .mb-n3,
.LandingPage .my-n3 {
  margin-bottom: -0.75rem !important;
}
.LandingPage .ml-n3,
.LandingPage .mx-n3 {
  margin-left: -0.75rem !important;
}
.LandingPage .m-n4 {
  margin: -1rem !important;
}
.LandingPage .mt-n4,
.LandingPage .my-n4 {
  margin-top: -1rem !important;
}
.LandingPage .mr-n4,
.LandingPage .mx-n4 {
  margin-right: -1rem !important;
}
.LandingPage .mb-n4,
.LandingPage .my-n4 {
  margin-bottom: -1rem !important;
}
.LandingPage .ml-n4,
.LandingPage .mx-n4 {
  margin-left: -1rem !important;
}
.LandingPage .m-n5 {
  margin: -1.5rem !important;
}
.LandingPage .mt-n5,
.LandingPage .my-n5 {
  margin-top: -1.5rem !important;
}
.LandingPage .mr-n5,
.LandingPage .mx-n5 {
  margin-right: -1.5rem !important;
}
.LandingPage .mb-n5,
.LandingPage .my-n5 {
  margin-bottom: -1.5rem !important;
}
.LandingPage .ml-n5,
.LandingPage .mx-n5 {
  margin-left: -1.5rem !important;
}
.LandingPage .m-n6 {
  margin: -2rem !important;
}
.LandingPage .mt-n6,
.LandingPage .my-n6 {
  margin-top: -2rem !important;
}
.LandingPage .mr-n6,
.LandingPage .mx-n6 {
  margin-right: -2rem !important;
}
.LandingPage .mb-n6,
.LandingPage .my-n6 {
  margin-bottom: -2rem !important;
}
.LandingPage .ml-n6,
.LandingPage .mx-n6 {
  margin-left: -2rem !important;
}
.LandingPage .m-n7 {
  margin: -2.5rem !important;
}
.LandingPage .mt-n7,
.LandingPage .my-n7 {
  margin-top: -2.5rem !important;
}
.LandingPage .mr-n7,
.LandingPage .mx-n7 {
  margin-right: -2.5rem !important;
}
.LandingPage .mb-n7,
.LandingPage .my-n7 {
  margin-bottom: -2.5rem !important;
}
.LandingPage .ml-n7,
.LandingPage .mx-n7 {
  margin-left: -2.5rem !important;
}
.LandingPage .m-n8 {
  margin: -3rem !important;
}
.LandingPage .mt-n8,
.LandingPage .my-n8 {
  margin-top: -3rem !important;
}
.LandingPage .mr-n8,
.LandingPage .mx-n8 {
  margin-right: -3rem !important;
}
.LandingPage .mb-n8,
.LandingPage .my-n8 {
  margin-bottom: -3rem !important;
}
.LandingPage .ml-n8,
.LandingPage .mx-n8 {
  margin-left: -3rem !important;
}
.LandingPage .m-n9 {
  margin: -4rem !important;
}
.LandingPage .mt-n9,
.LandingPage .my-n9 {
  margin-top: -4rem !important;
}
.LandingPage .mr-n9,
.LandingPage .mx-n9 {
  margin-right: -4rem !important;
}
.LandingPage .mb-n9,
.LandingPage .my-n9 {
  margin-bottom: -4rem !important;
}
.LandingPage .ml-n9,
.LandingPage .mx-n9 {
  margin-left: -4rem !important;
}
.LandingPage .m-n10 {
  margin: -5rem !important;
}
.LandingPage .mt-n10,
.LandingPage .my-n10 {
  margin-top: -5rem !important;
}
.LandingPage .mr-n10,
.LandingPage .mx-n10 {
  margin-right: -5rem !important;
}
.LandingPage .mb-n10,
.LandingPage .my-n10 {
  margin-bottom: -5rem !important;
}
.LandingPage .ml-n10,
.LandingPage .mx-n10 {
  margin-left: -5rem !important;
}
.LandingPage .m-n11 {
  margin: -6rem !important;
}
.LandingPage .mt-n11,
.LandingPage .my-n11 {
  margin-top: -6rem !important;
}
.LandingPage .mr-n11,
.LandingPage .mx-n11 {
  margin-right: -6rem !important;
}
.LandingPage .mb-n11,
.LandingPage .my-n11 {
  margin-bottom: -6rem !important;
}
.LandingPage .ml-n11,
.LandingPage .mx-n11 {
  margin-left: -6rem !important;
}
.LandingPage .m-n12 {
  margin: -8rem !important;
}
.LandingPage .mt-n12,
.LandingPage .my-n12 {
  margin-top: -8rem !important;
}
.LandingPage .mr-n12,
.LandingPage .mx-n12 {
  margin-right: -8rem !important;
}
.LandingPage .mb-n12,
.LandingPage .my-n12 {
  margin-bottom: -8rem !important;
}
.LandingPage .ml-n12,
.LandingPage .mx-n12 {
  margin-left: -8rem !important;
}
.LandingPage .m-n13 {
  margin: -10rem !important;
}
.LandingPage .mt-n13,
.LandingPage .my-n13 {
  margin-top: -10rem !important;
}
.LandingPage .mr-n13,
.LandingPage .mx-n13 {
  margin-right: -10rem !important;
}
.LandingPage .mb-n13,
.LandingPage .my-n13 {
  margin-bottom: -10rem !important;
}
.LandingPage .ml-n13,
.LandingPage .mx-n13 {
  margin-left: -10rem !important;
}
.LandingPage .m-n14 {
  margin: -12rem !important;
}
.LandingPage .mt-n14,
.LandingPage .my-n14 {
  margin-top: -12rem !important;
}
.LandingPage .mr-n14,
.LandingPage .mx-n14 {
  margin-right: -12rem !important;
}
.LandingPage .mb-n14,
.LandingPage .my-n14 {
  margin-bottom: -12rem !important;
}
.LandingPage .ml-n14,
.LandingPage .mx-n14 {
  margin-left: -12rem !important;
}
.LandingPage .m-n15 {
  margin: -16rem !important;
}
.LandingPage .mt-n15,
.LandingPage .my-n15 {
  margin-top: -16rem !important;
}
.LandingPage .mr-n15,
.LandingPage .mx-n15 {
  margin-right: -16rem !important;
}
.LandingPage .mb-n15,
.LandingPage .my-n15 {
  margin-bottom: -16rem !important;
}
.LandingPage .ml-n15,
.LandingPage .mx-n15 {
  margin-left: -16rem !important;
}
.LandingPage .m-n16 {
  margin: -25rem !important;
}
.LandingPage .mt-n16,
.LandingPage .my-n16 {
  margin-top: -25rem !important;
}
.LandingPage .mr-n16,
.LandingPage .mx-n16 {
  margin-right: -25rem !important;
}
.LandingPage .mb-n16,
.LandingPage .my-n16 {
  margin-bottom: -25rem !important;
}
.LandingPage .ml-n16,
.LandingPage .mx-n16 {
  margin-left: -25rem !important;
}
.LandingPage .m-auto {
  margin: auto !important;
}
.LandingPage .mt-auto,
.LandingPage .my-auto {
  margin-top: auto !important;
}
.LandingPage .mr-auto,
.LandingPage .mx-auto {
  margin-right: auto !important;
}
.LandingPage .mb-auto,
.LandingPage .my-auto {
  margin-bottom: auto !important;
}
.LandingPage .ml-auto,
.LandingPage .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .LandingPage .m-sm-0 {
    margin: 0 !important;
  }
  .LandingPage .mt-sm-0,
  .LandingPage .my-sm-0 {
    margin-top: 0 !important;
  }
  .LandingPage .mr-sm-0,
  .LandingPage .mx-sm-0 {
    margin-right: 0 !important;
  }
  .LandingPage .mb-sm-0,
  .LandingPage .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .LandingPage .ml-sm-0,
  .LandingPage .mx-sm-0 {
    margin-left: 0 !important;
  }
  .LandingPage .m-sm-1 {
    margin: 0.25rem !important;
  }
  .LandingPage .mt-sm-1,
  .LandingPage .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .LandingPage .mr-sm-1,
  .LandingPage .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .LandingPage .mb-sm-1,
  .LandingPage .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .LandingPage .ml-sm-1,
  .LandingPage .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .LandingPage .m-sm-2 {
    margin: 0.5rem !important;
  }
  .LandingPage .mt-sm-2,
  .LandingPage .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .LandingPage .mr-sm-2,
  .LandingPage .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .LandingPage .mb-sm-2,
  .LandingPage .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .LandingPage .ml-sm-2,
  .LandingPage .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .LandingPage .m-sm-3 {
    margin: 0.75rem !important;
  }
  .LandingPage .mt-sm-3,
  .LandingPage .my-sm-3 {
    margin-top: 0.75rem !important;
  }
  .LandingPage .mr-sm-3,
  .LandingPage .mx-sm-3 {
    margin-right: 0.75rem !important;
  }
  .LandingPage .mb-sm-3,
  .LandingPage .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .LandingPage .ml-sm-3,
  .LandingPage .mx-sm-3 {
    margin-left: 0.75rem !important;
  }
  .LandingPage .m-sm-4 {
    margin: 1rem !important;
  }
  .LandingPage .mt-sm-4,
  .LandingPage .my-sm-4 {
    margin-top: 1rem !important;
  }
  .LandingPage .mr-sm-4,
  .LandingPage .mx-sm-4 {
    margin-right: 1rem !important;
  }
  .LandingPage .mb-sm-4,
  .LandingPage .my-sm-4 {
    margin-bottom: 1rem !important;
  }
  .LandingPage .ml-sm-4,
  .LandingPage .mx-sm-4 {
    margin-left: 1rem !important;
  }
  .LandingPage .m-sm-5 {
    margin: 1.5rem !important;
  }
  .LandingPage .mt-sm-5,
  .LandingPage .my-sm-5 {
    margin-top: 1.5rem !important;
  }
  .LandingPage .mr-sm-5,
  .LandingPage .mx-sm-5 {
    margin-right: 1.5rem !important;
  }
  .LandingPage .mb-sm-5,
  .LandingPage .my-sm-5 {
    margin-bottom: 1.5rem !important;
  }
  .LandingPage .ml-sm-5,
  .LandingPage .mx-sm-5 {
    margin-left: 1.5rem !important;
  }
  .LandingPage .m-sm-6 {
    margin: 2rem !important;
  }
  .LandingPage .mt-sm-6,
  .LandingPage .my-sm-6 {
    margin-top: 2rem !important;
  }
  .LandingPage .mr-sm-6,
  .LandingPage .mx-sm-6 {
    margin-right: 2rem !important;
  }
  .LandingPage .mb-sm-6,
  .LandingPage .my-sm-6 {
    margin-bottom: 2rem !important;
  }
  .LandingPage .ml-sm-6,
  .LandingPage .mx-sm-6 {
    margin-left: 2rem !important;
  }
  .LandingPage .m-sm-7 {
    margin: 2.5rem !important;
  }
  .LandingPage .mt-sm-7,
  .LandingPage .my-sm-7 {
    margin-top: 2.5rem !important;
  }
  .LandingPage .mr-sm-7,
  .LandingPage .mx-sm-7 {
    margin-right: 2.5rem !important;
  }
  .LandingPage .mb-sm-7,
  .LandingPage .my-sm-7 {
    margin-bottom: 2.5rem !important;
  }
  .LandingPage .ml-sm-7,
  .LandingPage .mx-sm-7 {
    margin-left: 2.5rem !important;
  }
  .LandingPage .m-sm-8 {
    margin: 3rem !important;
  }
  .LandingPage .mt-sm-8,
  .LandingPage .my-sm-8 {
    margin-top: 3rem !important;
  }
  .LandingPage .mr-sm-8,
  .LandingPage .mx-sm-8 {
    margin-right: 3rem !important;
  }
  .LandingPage .mb-sm-8,
  .LandingPage .my-sm-8 {
    margin-bottom: 3rem !important;
  }
  .LandingPage .ml-sm-8,
  .LandingPage .mx-sm-8 {
    margin-left: 3rem !important;
  }
  .LandingPage .m-sm-9 {
    margin: 4rem !important;
  }
  .LandingPage .mt-sm-9,
  .LandingPage .my-sm-9 {
    margin-top: 4rem !important;
  }
  .LandingPage .mr-sm-9,
  .LandingPage .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .LandingPage .mb-sm-9,
  .LandingPage .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .LandingPage .ml-sm-9,
  .LandingPage .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .LandingPage .m-sm-10 {
    margin: 5rem !important;
  }
  .LandingPage .mt-sm-10,
  .LandingPage .my-sm-10 {
    margin-top: 5rem !important;
  }
  .LandingPage .mr-sm-10,
  .LandingPage .mx-sm-10 {
    margin-right: 5rem !important;
  }
  .LandingPage .mb-sm-10,
  .LandingPage .my-sm-10 {
    margin-bottom: 5rem !important;
  }
  .LandingPage .ml-sm-10,
  .LandingPage .mx-sm-10 {
    margin-left: 5rem !important;
  }
  .LandingPage .m-sm-11 {
    margin: 6rem !important;
  }
  .LandingPage .mt-sm-11,
  .LandingPage .my-sm-11 {
    margin-top: 6rem !important;
  }
  .LandingPage .mr-sm-11,
  .LandingPage .mx-sm-11 {
    margin-right: 6rem !important;
  }
  .LandingPage .mb-sm-11,
  .LandingPage .my-sm-11 {
    margin-bottom: 6rem !important;
  }
  .LandingPage .ml-sm-11,
  .LandingPage .mx-sm-11 {
    margin-left: 6rem !important;
  }
  .LandingPage .m-sm-12 {
    margin: 8rem !important;
  }
  .LandingPage .mt-sm-12,
  .LandingPage .my-sm-12 {
    margin-top: 8rem !important;
  }
  .LandingPage .mr-sm-12,
  .LandingPage .mx-sm-12 {
    margin-right: 8rem !important;
  }
  .LandingPage .mb-sm-12,
  .LandingPage .my-sm-12 {
    margin-bottom: 8rem !important;
  }
  .LandingPage .ml-sm-12,
  .LandingPage .mx-sm-12 {
    margin-left: 8rem !important;
  }
  .LandingPage .m-sm-13 {
    margin: 10rem !important;
  }
  .LandingPage .mt-sm-13,
  .LandingPage .my-sm-13 {
    margin-top: 10rem !important;
  }
  .LandingPage .mr-sm-13,
  .LandingPage .mx-sm-13 {
    margin-right: 10rem !important;
  }
  .LandingPage .mb-sm-13,
  .LandingPage .my-sm-13 {
    margin-bottom: 10rem !important;
  }
  .LandingPage .ml-sm-13,
  .LandingPage .mx-sm-13 {
    margin-left: 10rem !important;
  }
  .LandingPage .m-sm-14 {
    margin: 12rem !important;
  }
  .LandingPage .mt-sm-14,
  .LandingPage .my-sm-14 {
    margin-top: 12rem !important;
  }
  .LandingPage .mr-sm-14,
  .LandingPage .mx-sm-14 {
    margin-right: 12rem !important;
  }
  .LandingPage .mb-sm-14,
  .LandingPage .my-sm-14 {
    margin-bottom: 12rem !important;
  }
  .LandingPage .ml-sm-14,
  .LandingPage .mx-sm-14 {
    margin-left: 12rem !important;
  }
  .LandingPage .m-sm-15 {
    margin: 16rem !important;
  }
  .LandingPage .mt-sm-15,
  .LandingPage .my-sm-15 {
    margin-top: 16rem !important;
  }
  .LandingPage .mr-sm-15,
  .LandingPage .mx-sm-15 {
    margin-right: 16rem !important;
  }
  .LandingPage .mb-sm-15,
  .LandingPage .my-sm-15 {
    margin-bottom: 16rem !important;
  }
  .LandingPage .ml-sm-15,
  .LandingPage .mx-sm-15 {
    margin-left: 16rem !important;
  }
  .LandingPage .m-sm-16 {
    margin: 25rem !important;
  }
  .LandingPage .mt-sm-16,
  .LandingPage .my-sm-16 {
    margin-top: 25rem !important;
  }
  .LandingPage .mr-sm-16,
  .LandingPage .mx-sm-16 {
    margin-right: 25rem !important;
  }
  .LandingPage .mb-sm-16,
  .LandingPage .my-sm-16 {
    margin-bottom: 25rem !important;
  }
  .LandingPage .ml-sm-16,
  .LandingPage .mx-sm-16 {
    margin-left: 25rem !important;
  }
  .LandingPage .p-sm-0 {
    padding: 0 !important;
  }
  .LandingPage .pt-sm-0,
  .LandingPage .py-sm-0 {
    padding-top: 0 !important;
  }
  .LandingPage .pr-sm-0,
  .LandingPage .px-sm-0 {
    padding-right: 0 !important;
  }
  .LandingPage .pb-sm-0,
  .LandingPage .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .LandingPage .pl-sm-0,
  .LandingPage .px-sm-0 {
    padding-left: 0 !important;
  }
  .LandingPage .p-sm-1 {
    padding: 0.25rem !important;
  }
  .LandingPage .pt-sm-1,
  .LandingPage .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .LandingPage .pr-sm-1,
  .LandingPage .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .LandingPage .pb-sm-1,
  .LandingPage .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .LandingPage .pl-sm-1,
  .LandingPage .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .LandingPage .p-sm-2 {
    padding: 0.5rem !important;
  }
  .LandingPage .pt-sm-2,
  .LandingPage .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .LandingPage .pr-sm-2,
  .LandingPage .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .LandingPage .pb-sm-2,
  .LandingPage .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .LandingPage .pl-sm-2,
  .LandingPage .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .LandingPage .p-sm-3 {
    padding: 0.75rem !important;
  }
  .LandingPage .pt-sm-3,
  .LandingPage .py-sm-3 {
    padding-top: 0.75rem !important;
  }
  .LandingPage .pr-sm-3,
  .LandingPage .px-sm-3 {
    padding-right: 0.75rem !important;
  }
  .LandingPage .pb-sm-3,
  .LandingPage .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .LandingPage .pl-sm-3,
  .LandingPage .px-sm-3 {
    padding-left: 0.75rem !important;
  }
  .LandingPage .p-sm-4 {
    padding: 1rem !important;
  }
  .LandingPage .pt-sm-4,
  .LandingPage .py-sm-4 {
    padding-top: 1rem !important;
  }
  .LandingPage .pr-sm-4,
  .LandingPage .px-sm-4 {
    padding-right: 1rem !important;
  }
  .LandingPage .pb-sm-4,
  .LandingPage .py-sm-4 {
    padding-bottom: 1rem !important;
  }
  .LandingPage .pl-sm-4,
  .LandingPage .px-sm-4 {
    padding-left: 1rem !important;
  }
  .LandingPage .p-sm-5 {
    padding: 1.5rem !important;
  }
  .LandingPage .pt-sm-5,
  .LandingPage .py-sm-5 {
    padding-top: 1.5rem !important;
  }
  .LandingPage .pr-sm-5,
  .LandingPage .px-sm-5 {
    padding-right: 1.5rem !important;
  }
  .LandingPage .pb-sm-5,
  .LandingPage .py-sm-5 {
    padding-bottom: 1.5rem !important;
  }
  .LandingPage .pl-sm-5,
  .LandingPage .px-sm-5 {
    padding-left: 1.5rem !important;
  }
  .LandingPage .p-sm-6 {
    padding: 2rem !important;
  }
  .LandingPage .pt-sm-6,
  .LandingPage .py-sm-6 {
    padding-top: 2rem !important;
  }
  .LandingPage .pr-sm-6,
  .LandingPage .px-sm-6 {
    padding-right: 2rem !important;
  }
  .LandingPage .pb-sm-6,
  .LandingPage .py-sm-6 {
    padding-bottom: 2rem !important;
  }
  .LandingPage .pl-sm-6,
  .LandingPage .px-sm-6 {
    padding-left: 2rem !important;
  }
  .LandingPage .p-sm-7 {
    padding: 2.5rem !important;
  }
  .LandingPage .pt-sm-7,
  .LandingPage .py-sm-7 {
    padding-top: 2.5rem !important;
  }
  .LandingPage .pr-sm-7,
  .LandingPage .px-sm-7 {
    padding-right: 2.5rem !important;
  }
  .LandingPage .pb-sm-7,
  .LandingPage .py-sm-7 {
    padding-bottom: 2.5rem !important;
  }
  .LandingPage .pl-sm-7,
  .LandingPage .px-sm-7 {
    padding-left: 2.5rem !important;
  }
  .LandingPage .p-sm-8 {
    padding: 3rem !important;
  }
  .LandingPage .pt-sm-8,
  .LandingPage .py-sm-8 {
    padding-top: 3rem !important;
  }
  .LandingPage .pr-sm-8,
  .LandingPage .px-sm-8 {
    padding-right: 3rem !important;
  }
  .LandingPage .pb-sm-8,
  .LandingPage .py-sm-8 {
    padding-bottom: 3rem !important;
  }
  .LandingPage .pl-sm-8,
  .LandingPage .px-sm-8 {
    padding-left: 3rem !important;
  }
  .LandingPage .p-sm-9 {
    padding: 4rem !important;
  }
  .LandingPage .pt-sm-9,
  .LandingPage .py-sm-9 {
    padding-top: 4rem !important;
  }
  .LandingPage .pr-sm-9,
  .LandingPage .px-sm-9 {
    padding-right: 4rem !important;
  }
  .LandingPage .pb-sm-9,
  .LandingPage .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .LandingPage .pl-sm-9,
  .LandingPage .px-sm-9 {
    padding-left: 4rem !important;
  }
  .LandingPage .p-sm-10 {
    padding: 5rem !important;
  }
  .LandingPage .pt-sm-10,
  .LandingPage .py-sm-10 {
    padding-top: 5rem !important;
  }
  .LandingPage .pr-sm-10,
  .LandingPage .px-sm-10 {
    padding-right: 5rem !important;
  }
  .LandingPage .pb-sm-10,
  .LandingPage .py-sm-10 {
    padding-bottom: 5rem !important;
  }
  .LandingPage .pl-sm-10,
  .LandingPage .px-sm-10 {
    padding-left: 5rem !important;
  }
  .LandingPage .p-sm-11 {
    padding: 6rem !important;
  }
  .LandingPage .pt-sm-11,
  .LandingPage .py-sm-11 {
    padding-top: 6rem !important;
  }
  .LandingPage .pr-sm-11,
  .LandingPage .px-sm-11 {
    padding-right: 6rem !important;
  }
  .LandingPage .pb-sm-11,
  .LandingPage .py-sm-11 {
    padding-bottom: 6rem !important;
  }
  .LandingPage .pl-sm-11,
  .LandingPage .px-sm-11 {
    padding-left: 6rem !important;
  }
  .LandingPage .p-sm-12 {
    padding: 8rem !important;
  }
  .LandingPage .pt-sm-12,
  .LandingPage .py-sm-12 {
    padding-top: 8rem !important;
  }
  .LandingPage .pr-sm-12,
  .LandingPage .px-sm-12 {
    padding-right: 8rem !important;
  }
  .LandingPage .pb-sm-12,
  .LandingPage .py-sm-12 {
    padding-bottom: 8rem !important;
  }
  .LandingPage .pl-sm-12,
  .LandingPage .px-sm-12 {
    padding-left: 8rem !important;
  }
  .LandingPage .p-sm-13 {
    padding: 10rem !important;
  }
  .LandingPage .pt-sm-13,
  .LandingPage .py-sm-13 {
    padding-top: 10rem !important;
  }
  .LandingPage .pr-sm-13,
  .LandingPage .px-sm-13 {
    padding-right: 10rem !important;
  }
  .LandingPage .pb-sm-13,
  .LandingPage .py-sm-13 {
    padding-bottom: 10rem !important;
  }
  .LandingPage .pl-sm-13,
  .LandingPage .px-sm-13 {
    padding-left: 10rem !important;
  }
  .LandingPage .p-sm-14 {
    padding: 12rem !important;
  }
  .LandingPage .pt-sm-14,
  .LandingPage .py-sm-14 {
    padding-top: 12rem !important;
  }
  .LandingPage .pr-sm-14,
  .LandingPage .px-sm-14 {
    padding-right: 12rem !important;
  }
  .LandingPage .pb-sm-14,
  .LandingPage .py-sm-14 {
    padding-bottom: 12rem !important;
  }
  .LandingPage .pl-sm-14,
  .LandingPage .px-sm-14 {
    padding-left: 12rem !important;
  }
  .LandingPage .p-sm-15 {
    padding: 16rem !important;
  }
  .LandingPage .pt-sm-15,
  .LandingPage .py-sm-15 {
    padding-top: 16rem !important;
  }
  .LandingPage .pr-sm-15,
  .LandingPage .px-sm-15 {
    padding-right: 16rem !important;
  }
  .LandingPage .pb-sm-15,
  .LandingPage .py-sm-15 {
    padding-bottom: 16rem !important;
  }
  .LandingPage .pl-sm-15,
  .LandingPage .px-sm-15 {
    padding-left: 16rem !important;
  }
  .LandingPage .p-sm-16 {
    padding: 25rem !important;
  }
  .LandingPage .pt-sm-16,
  .LandingPage .py-sm-16 {
    padding-top: 25rem !important;
  }
  .LandingPage .pr-sm-16,
  .LandingPage .px-sm-16 {
    padding-right: 25rem !important;
  }
  .LandingPage .pb-sm-16,
  .LandingPage .py-sm-16 {
    padding-bottom: 25rem !important;
  }
  .LandingPage .pl-sm-16,
  .LandingPage .px-sm-16 {
    padding-left: 25rem !important;
  }
  .LandingPage .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .LandingPage .mt-sm-n1,
  .LandingPage .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .LandingPage .mr-sm-n1,
  .LandingPage .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .LandingPage .mb-sm-n1,
  .LandingPage .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .LandingPage .ml-sm-n1,
  .LandingPage .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .LandingPage .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .LandingPage .mt-sm-n2,
  .LandingPage .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .LandingPage .mr-sm-n2,
  .LandingPage .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .LandingPage .mb-sm-n2,
  .LandingPage .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .LandingPage .ml-sm-n2,
  .LandingPage .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .LandingPage .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .LandingPage .mt-sm-n3,
  .LandingPage .my-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .LandingPage .mr-sm-n3,
  .LandingPage .mx-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .LandingPage .mb-sm-n3,
  .LandingPage .my-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .LandingPage .ml-sm-n3,
  .LandingPage .mx-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .LandingPage .m-sm-n4 {
    margin: -1rem !important;
  }
  .LandingPage .mt-sm-n4,
  .LandingPage .my-sm-n4 {
    margin-top: -1rem !important;
  }
  .LandingPage .mr-sm-n4,
  .LandingPage .mx-sm-n4 {
    margin-right: -1rem !important;
  }
  .LandingPage .mb-sm-n4,
  .LandingPage .my-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .LandingPage .ml-sm-n4,
  .LandingPage .mx-sm-n4 {
    margin-left: -1rem !important;
  }
  .LandingPage .m-sm-n5 {
    margin: -1.5rem !important;
  }
  .LandingPage .mt-sm-n5,
  .LandingPage .my-sm-n5 {
    margin-top: -1.5rem !important;
  }
  .LandingPage .mr-sm-n5,
  .LandingPage .mx-sm-n5 {
    margin-right: -1.5rem !important;
  }
  .LandingPage .mb-sm-n5,
  .LandingPage .my-sm-n5 {
    margin-bottom: -1.5rem !important;
  }
  .LandingPage .ml-sm-n5,
  .LandingPage .mx-sm-n5 {
    margin-left: -1.5rem !important;
  }
  .LandingPage .m-sm-n6 {
    margin: -2rem !important;
  }
  .LandingPage .mt-sm-n6,
  .LandingPage .my-sm-n6 {
    margin-top: -2rem !important;
  }
  .LandingPage .mr-sm-n6,
  .LandingPage .mx-sm-n6 {
    margin-right: -2rem !important;
  }
  .LandingPage .mb-sm-n6,
  .LandingPage .my-sm-n6 {
    margin-bottom: -2rem !important;
  }
  .LandingPage .ml-sm-n6,
  .LandingPage .mx-sm-n6 {
    margin-left: -2rem !important;
  }
  .LandingPage .m-sm-n7 {
    margin: -2.5rem !important;
  }
  .LandingPage .mt-sm-n7,
  .LandingPage .my-sm-n7 {
    margin-top: -2.5rem !important;
  }
  .LandingPage .mr-sm-n7,
  .LandingPage .mx-sm-n7 {
    margin-right: -2.5rem !important;
  }
  .LandingPage .mb-sm-n7,
  .LandingPage .my-sm-n7 {
    margin-bottom: -2.5rem !important;
  }
  .LandingPage .ml-sm-n7,
  .LandingPage .mx-sm-n7 {
    margin-left: -2.5rem !important;
  }
  .LandingPage .m-sm-n8 {
    margin: -3rem !important;
  }
  .LandingPage .mt-sm-n8,
  .LandingPage .my-sm-n8 {
    margin-top: -3rem !important;
  }
  .LandingPage .mr-sm-n8,
  .LandingPage .mx-sm-n8 {
    margin-right: -3rem !important;
  }
  .LandingPage .mb-sm-n8,
  .LandingPage .my-sm-n8 {
    margin-bottom: -3rem !important;
  }
  .LandingPage .ml-sm-n8,
  .LandingPage .mx-sm-n8 {
    margin-left: -3rem !important;
  }
  .LandingPage .m-sm-n9 {
    margin: -4rem !important;
  }
  .LandingPage .mt-sm-n9,
  .LandingPage .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .LandingPage .mr-sm-n9,
  .LandingPage .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .LandingPage .mb-sm-n9,
  .LandingPage .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .LandingPage .ml-sm-n9,
  .LandingPage .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .LandingPage .m-sm-n10 {
    margin: -5rem !important;
  }
  .LandingPage .mt-sm-n10,
  .LandingPage .my-sm-n10 {
    margin-top: -5rem !important;
  }
  .LandingPage .mr-sm-n10,
  .LandingPage .mx-sm-n10 {
    margin-right: -5rem !important;
  }
  .LandingPage .mb-sm-n10,
  .LandingPage .my-sm-n10 {
    margin-bottom: -5rem !important;
  }
  .LandingPage .ml-sm-n10,
  .LandingPage .mx-sm-n10 {
    margin-left: -5rem !important;
  }
  .LandingPage .m-sm-n11 {
    margin: -6rem !important;
  }
  .LandingPage .mt-sm-n11,
  .LandingPage .my-sm-n11 {
    margin-top: -6rem !important;
  }
  .LandingPage .mr-sm-n11,
  .LandingPage .mx-sm-n11 {
    margin-right: -6rem !important;
  }
  .LandingPage .mb-sm-n11,
  .LandingPage .my-sm-n11 {
    margin-bottom: -6rem !important;
  }
  .LandingPage .ml-sm-n11,
  .LandingPage .mx-sm-n11 {
    margin-left: -6rem !important;
  }
  .LandingPage .m-sm-n12 {
    margin: -8rem !important;
  }
  .LandingPage .mt-sm-n12,
  .LandingPage .my-sm-n12 {
    margin-top: -8rem !important;
  }
  .LandingPage .mr-sm-n12,
  .LandingPage .mx-sm-n12 {
    margin-right: -8rem !important;
  }
  .LandingPage .mb-sm-n12,
  .LandingPage .my-sm-n12 {
    margin-bottom: -8rem !important;
  }
  .LandingPage .ml-sm-n12,
  .LandingPage .mx-sm-n12 {
    margin-left: -8rem !important;
  }
  .LandingPage .m-sm-n13 {
    margin: -10rem !important;
  }
  .LandingPage .mt-sm-n13,
  .LandingPage .my-sm-n13 {
    margin-top: -10rem !important;
  }
  .LandingPage .mr-sm-n13,
  .LandingPage .mx-sm-n13 {
    margin-right: -10rem !important;
  }
  .LandingPage .mb-sm-n13,
  .LandingPage .my-sm-n13 {
    margin-bottom: -10rem !important;
  }
  .LandingPage .ml-sm-n13,
  .LandingPage .mx-sm-n13 {
    margin-left: -10rem !important;
  }
  .LandingPage .m-sm-n14 {
    margin: -12rem !important;
  }
  .LandingPage .mt-sm-n14,
  .LandingPage .my-sm-n14 {
    margin-top: -12rem !important;
  }
  .LandingPage .mr-sm-n14,
  .LandingPage .mx-sm-n14 {
    margin-right: -12rem !important;
  }
  .LandingPage .mb-sm-n14,
  .LandingPage .my-sm-n14 {
    margin-bottom: -12rem !important;
  }
  .LandingPage .ml-sm-n14,
  .LandingPage .mx-sm-n14 {
    margin-left: -12rem !important;
  }
  .LandingPage .m-sm-n15 {
    margin: -16rem !important;
  }
  .LandingPage .mt-sm-n15,
  .LandingPage .my-sm-n15 {
    margin-top: -16rem !important;
  }
  .LandingPage .mr-sm-n15,
  .LandingPage .mx-sm-n15 {
    margin-right: -16rem !important;
  }
  .LandingPage .mb-sm-n15,
  .LandingPage .my-sm-n15 {
    margin-bottom: -16rem !important;
  }
  .LandingPage .ml-sm-n15,
  .LandingPage .mx-sm-n15 {
    margin-left: -16rem !important;
  }
  .LandingPage .m-sm-n16 {
    margin: -25rem !important;
  }
  .LandingPage .mt-sm-n16,
  .LandingPage .my-sm-n16 {
    margin-top: -25rem !important;
  }
  .LandingPage .mr-sm-n16,
  .LandingPage .mx-sm-n16 {
    margin-right: -25rem !important;
  }
  .LandingPage .mb-sm-n16,
  .LandingPage .my-sm-n16 {
    margin-bottom: -25rem !important;
  }
  .LandingPage .ml-sm-n16,
  .LandingPage .mx-sm-n16 {
    margin-left: -25rem !important;
  }
  .LandingPage .m-sm-auto {
    margin: auto !important;
  }
  .LandingPage .mt-sm-auto,
  .LandingPage .my-sm-auto {
    margin-top: auto !important;
  }
  .LandingPage .mr-sm-auto,
  .LandingPage .mx-sm-auto {
    margin-right: auto !important;
  }
  .LandingPage .mb-sm-auto,
  .LandingPage .my-sm-auto {
    margin-bottom: auto !important;
  }
  .LandingPage .ml-sm-auto,
  .LandingPage .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .m-md-0 {
    margin: 0 !important;
  }
  .LandingPage .mt-md-0,
  .LandingPage .my-md-0 {
    margin-top: 0 !important;
  }
  .LandingPage .mr-md-0,
  .LandingPage .mx-md-0 {
    margin-right: 0 !important;
  }
  .LandingPage .mb-md-0,
  .LandingPage .my-md-0 {
    margin-bottom: 0 !important;
  }
  .LandingPage .ml-md-0,
  .LandingPage .mx-md-0 {
    margin-left: 0 !important;
  }
  .LandingPage .m-md-1 {
    margin: 0.25rem !important;
  }
  .LandingPage .mt-md-1,
  .LandingPage .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .LandingPage .mr-md-1,
  .LandingPage .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .LandingPage .mb-md-1,
  .LandingPage .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .LandingPage .ml-md-1,
  .LandingPage .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .LandingPage .m-md-2 {
    margin: 0.5rem !important;
  }
  .LandingPage .mt-md-2,
  .LandingPage .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .LandingPage .mr-md-2,
  .LandingPage .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .LandingPage .mb-md-2,
  .LandingPage .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .LandingPage .ml-md-2,
  .LandingPage .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .LandingPage .m-md-3 {
    margin: 0.75rem !important;
  }
  .LandingPage .mt-md-3,
  .LandingPage .my-md-3 {
    margin-top: 0.75rem !important;
  }
  .LandingPage .mr-md-3,
  .LandingPage .mx-md-3 {
    margin-right: 0.75rem !important;
  }
  .LandingPage .mb-md-3,
  .LandingPage .my-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .LandingPage .ml-md-3,
  .LandingPage .mx-md-3 {
    margin-left: 0.75rem !important;
  }
  .LandingPage .m-md-4 {
    margin: 1rem !important;
  }
  .LandingPage .mt-md-4,
  .LandingPage .my-md-4 {
    margin-top: 1rem !important;
  }
  .LandingPage .mr-md-4,
  .LandingPage .mx-md-4 {
    margin-right: 1rem !important;
  }
  .LandingPage .mb-md-4,
  .LandingPage .my-md-4 {
    margin-bottom: 1rem !important;
  }
  .LandingPage .ml-md-4,
  .LandingPage .mx-md-4 {
    margin-left: 1rem !important;
  }
  .LandingPage .m-md-5 {
    margin: 1.5rem !important;
  }
  .LandingPage .mt-md-5,
  .LandingPage .my-md-5 {
    margin-top: 1.5rem !important;
  }
  .LandingPage .mr-md-5,
  .LandingPage .mx-md-5 {
    margin-right: 1.5rem !important;
  }
  .LandingPage .mb-md-5,
  .LandingPage .my-md-5 {
    margin-bottom: 1.5rem !important;
  }
  .LandingPage .ml-md-5,
  .LandingPage .mx-md-5 {
    margin-left: 1.5rem !important;
  }
  .LandingPage .m-md-6 {
    margin: 2rem !important;
  }
  .LandingPage .mt-md-6,
  .LandingPage .my-md-6 {
    margin-top: 2rem !important;
  }
  .LandingPage .mr-md-6,
  .LandingPage .mx-md-6 {
    margin-right: 2rem !important;
  }
  .LandingPage .mb-md-6,
  .LandingPage .my-md-6 {
    margin-bottom: 2rem !important;
  }
  .LandingPage .ml-md-6,
  .LandingPage .mx-md-6 {
    margin-left: 2rem !important;
  }
  .LandingPage .m-md-7 {
    margin: 2.5rem !important;
  }
  .LandingPage .mt-md-7,
  .LandingPage .my-md-7 {
    margin-top: 2.5rem !important;
  }
  .LandingPage .mr-md-7,
  .LandingPage .mx-md-7 {
    margin-right: 2.5rem !important;
  }
  .LandingPage .mb-md-7,
  .LandingPage .my-md-7 {
    margin-bottom: 2.5rem !important;
  }
  .LandingPage .ml-md-7,
  .LandingPage .mx-md-7 {
    margin-left: 2.5rem !important;
  }
  .LandingPage .m-md-8 {
    margin: 3rem !important;
  }
  .LandingPage .mt-md-8,
  .LandingPage .my-md-8 {
    margin-top: 3rem !important;
  }
  .LandingPage .mr-md-8,
  .LandingPage .mx-md-8 {
    margin-right: 3rem !important;
  }
  .LandingPage .mb-md-8,
  .LandingPage .my-md-8 {
    margin-bottom: 3rem !important;
  }
  .LandingPage .ml-md-8,
  .LandingPage .mx-md-8 {
    margin-left: 3rem !important;
  }
  .LandingPage .m-md-9 {
    margin: 4rem !important;
  }
  .LandingPage .mt-md-9,
  .LandingPage .my-md-9 {
    margin-top: 4rem !important;
  }
  .LandingPage .mr-md-9,
  .LandingPage .mx-md-9 {
    margin-right: 4rem !important;
  }
  .LandingPage .mb-md-9,
  .LandingPage .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .LandingPage .ml-md-9,
  .LandingPage .mx-md-9 {
    margin-left: 4rem !important;
  }
  .LandingPage .m-md-10 {
    margin: 5rem !important;
  }
  .LandingPage .mt-md-10,
  .LandingPage .my-md-10 {
    margin-top: 5rem !important;
  }
  .LandingPage .mr-md-10,
  .LandingPage .mx-md-10 {
    margin-right: 5rem !important;
  }
  .LandingPage .mb-md-10,
  .LandingPage .my-md-10 {
    margin-bottom: 5rem !important;
  }
  .LandingPage .ml-md-10,
  .LandingPage .mx-md-10 {
    margin-left: 5rem !important;
  }
  .LandingPage .m-md-11 {
    margin: 6rem !important;
  }
  .LandingPage .mt-md-11,
  .LandingPage .my-md-11 {
    margin-top: 6rem !important;
  }
  .LandingPage .mr-md-11,
  .LandingPage .mx-md-11 {
    margin-right: 6rem !important;
  }
  .LandingPage .mb-md-11,
  .LandingPage .my-md-11 {
    margin-bottom: 6rem !important;
  }
  .LandingPage .ml-md-11,
  .LandingPage .mx-md-11 {
    margin-left: 6rem !important;
  }
  .LandingPage .m-md-12 {
    margin: 8rem !important;
  }
  .LandingPage .mt-md-12,
  .LandingPage .my-md-12 {
    margin-top: 8rem !important;
  }
  .LandingPage .mr-md-12,
  .LandingPage .mx-md-12 {
    margin-right: 8rem !important;
  }
  .LandingPage .mb-md-12,
  .LandingPage .my-md-12 {
    margin-bottom: 8rem !important;
  }
  .LandingPage .ml-md-12,
  .LandingPage .mx-md-12 {
    margin-left: 8rem !important;
  }
  .LandingPage .m-md-13 {
    margin: 10rem !important;
  }
  .LandingPage .mt-md-13,
  .LandingPage .my-md-13 {
    margin-top: 10rem !important;
  }
  .LandingPage .mr-md-13,
  .LandingPage .mx-md-13 {
    margin-right: 10rem !important;
  }
  .LandingPage .mb-md-13,
  .LandingPage .my-md-13 {
    margin-bottom: 10rem !important;
  }
  .LandingPage .ml-md-13,
  .LandingPage .mx-md-13 {
    margin-left: 10rem !important;
  }
  .LandingPage .m-md-14 {
    margin: 12rem !important;
  }
  .LandingPage .mt-md-14,
  .LandingPage .my-md-14 {
    margin-top: 12rem !important;
  }
  .LandingPage .mr-md-14,
  .LandingPage .mx-md-14 {
    margin-right: 12rem !important;
  }
  .LandingPage .mb-md-14,
  .LandingPage .my-md-14 {
    margin-bottom: 12rem !important;
  }
  .LandingPage .ml-md-14,
  .LandingPage .mx-md-14 {
    margin-left: 12rem !important;
  }
  .LandingPage .m-md-15 {
    margin: 16rem !important;
  }
  .LandingPage .mt-md-15,
  .LandingPage .my-md-15 {
    margin-top: 16rem !important;
  }
  .LandingPage .mr-md-15,
  .LandingPage .mx-md-15 {
    margin-right: 16rem !important;
  }
  .LandingPage .mb-md-15,
  .LandingPage .my-md-15 {
    margin-bottom: 16rem !important;
  }
  .LandingPage .ml-md-15,
  .LandingPage .mx-md-15 {
    margin-left: 16rem !important;
  }
  .LandingPage .m-md-16 {
    margin: 25rem !important;
  }
  .LandingPage .mt-md-16,
  .LandingPage .my-md-16 {
    margin-top: 25rem !important;
  }
  .LandingPage .mr-md-16,
  .LandingPage .mx-md-16 {
    margin-right: 25rem !important;
  }
  .LandingPage .mb-md-16,
  .LandingPage .my-md-16 {
    margin-bottom: 25rem !important;
  }
  .LandingPage .ml-md-16,
  .LandingPage .mx-md-16 {
    margin-left: 25rem !important;
  }
  .LandingPage .p-md-0 {
    padding: 0 !important;
  }
  .LandingPage .pt-md-0,
  .LandingPage .py-md-0 {
    padding-top: 0 !important;
  }
  .LandingPage .pr-md-0,
  .LandingPage .px-md-0 {
    padding-right: 0 !important;
  }
  .LandingPage .pb-md-0,
  .LandingPage .py-md-0 {
    padding-bottom: 0 !important;
  }
  .LandingPage .pl-md-0,
  .LandingPage .px-md-0 {
    padding-left: 0 !important;
  }
  .LandingPage .p-md-1 {
    padding: 0.25rem !important;
  }
  .LandingPage .pt-md-1,
  .LandingPage .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .LandingPage .pr-md-1,
  .LandingPage .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .LandingPage .pb-md-1,
  .LandingPage .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .LandingPage .pl-md-1,
  .LandingPage .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .LandingPage .p-md-2 {
    padding: 0.5rem !important;
  }
  .LandingPage .pt-md-2,
  .LandingPage .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .LandingPage .pr-md-2,
  .LandingPage .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .LandingPage .pb-md-2,
  .LandingPage .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .LandingPage .pl-md-2,
  .LandingPage .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .LandingPage .p-md-3 {
    padding: 0.75rem !important;
  }
  .LandingPage .pt-md-3,
  .LandingPage .py-md-3 {
    padding-top: 0.75rem !important;
  }
  .LandingPage .pr-md-3,
  .LandingPage .px-md-3 {
    padding-right: 0.75rem !important;
  }
  .LandingPage .pb-md-3,
  .LandingPage .py-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .LandingPage .pl-md-3,
  .LandingPage .px-md-3 {
    padding-left: 0.75rem !important;
  }
  .LandingPage .p-md-4 {
    padding: 1rem !important;
  }
  .LandingPage .pt-md-4,
  .LandingPage .py-md-4 {
    padding-top: 1rem !important;
  }
  .LandingPage .pr-md-4,
  .LandingPage .px-md-4 {
    padding-right: 1rem !important;
  }
  .LandingPage .pb-md-4,
  .LandingPage .py-md-4 {
    padding-bottom: 1rem !important;
  }
  .LandingPage .pl-md-4,
  .LandingPage .px-md-4 {
    padding-left: 1rem !important;
  }
  .LandingPage .p-md-5 {
    padding: 1.5rem !important;
  }
  .LandingPage .pt-md-5,
  .LandingPage .py-md-5 {
    padding-top: 1.5rem !important;
  }
  .LandingPage .pr-md-5,
  .LandingPage .px-md-5 {
    padding-right: 1.5rem !important;
  }
  .LandingPage .pb-md-5,
  .LandingPage .py-md-5 {
    padding-bottom: 1.5rem !important;
  }
  .LandingPage .pl-md-5,
  .LandingPage .px-md-5 {
    padding-left: 1.5rem !important;
  }
  .LandingPage .p-md-6 {
    padding: 2rem !important;
  }
  .LandingPage .pt-md-6,
  .LandingPage .py-md-6 {
    padding-top: 2rem !important;
  }
  .LandingPage .pr-md-6,
  .LandingPage .px-md-6 {
    padding-right: 2rem !important;
  }
  .LandingPage .pb-md-6,
  .LandingPage .py-md-6 {
    padding-bottom: 2rem !important;
  }
  .LandingPage .pl-md-6,
  .LandingPage .px-md-6 {
    padding-left: 2rem !important;
  }
  .LandingPage .p-md-7 {
    padding: 2.5rem !important;
  }
  .LandingPage .pt-md-7,
  .LandingPage .py-md-7 {
    padding-top: 2.5rem !important;
  }
  .LandingPage .pr-md-7,
  .LandingPage .px-md-7 {
    padding-right: 2.5rem !important;
  }
  .LandingPage .pb-md-7,
  .LandingPage .py-md-7 {
    padding-bottom: 2.5rem !important;
  }
  .LandingPage .pl-md-7,
  .LandingPage .px-md-7 {
    padding-left: 2.5rem !important;
  }
  .LandingPage .p-md-8 {
    padding: 3rem !important;
  }
  .LandingPage .pt-md-8,
  .LandingPage .py-md-8 {
    padding-top: 3rem !important;
  }
  .LandingPage .pr-md-8,
  .LandingPage .px-md-8 {
    padding-right: 3rem !important;
  }
  .LandingPage .pb-md-8,
  .LandingPage .py-md-8 {
    padding-bottom: 3rem !important;
  }
  .LandingPage .pl-md-8,
  .LandingPage .px-md-8 {
    padding-left: 3rem !important;
  }
  .LandingPage .p-md-9 {
    padding: 4rem !important;
  }
  .LandingPage .pt-md-9,
  .LandingPage .py-md-9 {
    padding-top: 4rem !important;
  }
  .LandingPage .pr-md-9,
  .LandingPage .px-md-9 {
    padding-right: 4rem !important;
  }
  .LandingPage .pb-md-9,
  .LandingPage .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .LandingPage .pl-md-9,
  .LandingPage .px-md-9 {
    padding-left: 4rem !important;
  }
  .LandingPage .p-md-10 {
    padding: 5rem !important;
  }
  .LandingPage .pt-md-10,
  .LandingPage .py-md-10 {
    padding-top: 5rem !important;
  }
  .LandingPage .pr-md-10,
  .LandingPage .px-md-10 {
    padding-right: 5rem !important;
  }
  .LandingPage .pb-md-10,
  .LandingPage .py-md-10 {
    padding-bottom: 5rem !important;
  }
  .LandingPage .pl-md-10,
  .LandingPage .px-md-10 {
    padding-left: 5rem !important;
  }
  .LandingPage .p-md-11 {
    padding: 6rem !important;
  }
  .LandingPage .pt-md-11,
  .LandingPage .py-md-11 {
    padding-top: 6rem !important;
  }
  .LandingPage .pr-md-11,
  .LandingPage .px-md-11 {
    padding-right: 6rem !important;
  }
  .LandingPage .pb-md-11,
  .LandingPage .py-md-11 {
    padding-bottom: 6rem !important;
  }
  .LandingPage .pl-md-11,
  .LandingPage .px-md-11 {
    padding-left: 6rem !important;
  }
  .LandingPage .p-md-12 {
    padding: 8rem !important;
  }
  .LandingPage .pt-md-12,
  .LandingPage .py-md-12 {
    padding-top: 8rem !important;
  }
  .LandingPage .pr-md-12,
  .LandingPage .px-md-12 {
    padding-right: 8rem !important;
  }
  .LandingPage .pb-md-12,
  .LandingPage .py-md-12 {
    padding-bottom: 8rem !important;
  }
  .LandingPage .pl-md-12,
  .LandingPage .px-md-12 {
    padding-left: 8rem !important;
  }
  .LandingPage .p-md-13 {
    padding: 10rem !important;
  }
  .LandingPage .pt-md-13,
  .LandingPage .py-md-13 {
    padding-top: 10rem !important;
  }
  .LandingPage .pr-md-13,
  .LandingPage .px-md-13 {
    padding-right: 10rem !important;
  }
  .LandingPage .pb-md-13,
  .LandingPage .py-md-13 {
    padding-bottom: 10rem !important;
  }
  .LandingPage .pl-md-13,
  .LandingPage .px-md-13 {
    padding-left: 10rem !important;
  }
  .LandingPage .p-md-14 {
    padding: 12rem !important;
  }
  .LandingPage .pt-md-14,
  .LandingPage .py-md-14 {
    padding-top: 12rem !important;
  }
  .LandingPage .pr-md-14,
  .LandingPage .px-md-14 {
    padding-right: 12rem !important;
  }
  .LandingPage .pb-md-14,
  .LandingPage .py-md-14 {
    padding-bottom: 12rem !important;
  }
  .LandingPage .pl-md-14,
  .LandingPage .px-md-14 {
    padding-left: 12rem !important;
  }
  .LandingPage .p-md-15 {
    padding: 16rem !important;
  }
  .LandingPage .pt-md-15,
  .LandingPage .py-md-15 {
    padding-top: 16rem !important;
  }
  .LandingPage .pr-md-15,
  .LandingPage .px-md-15 {
    padding-right: 16rem !important;
  }
  .LandingPage .pb-md-15,
  .LandingPage .py-md-15 {
    padding-bottom: 16rem !important;
  }
  .LandingPage .pl-md-15,
  .LandingPage .px-md-15 {
    padding-left: 16rem !important;
  }
  .LandingPage .p-md-16 {
    padding: 25rem !important;
  }
  .LandingPage .pt-md-16,
  .LandingPage .py-md-16 {
    padding-top: 25rem !important;
  }
  .LandingPage .pr-md-16,
  .LandingPage .px-md-16 {
    padding-right: 25rem !important;
  }
  .LandingPage .pb-md-16,
  .LandingPage .py-md-16 {
    padding-bottom: 25rem !important;
  }
  .LandingPage .pl-md-16,
  .LandingPage .px-md-16 {
    padding-left: 25rem !important;
  }
  .LandingPage .m-md-n1 {
    margin: -0.25rem !important;
  }
  .LandingPage .mt-md-n1,
  .LandingPage .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .LandingPage .mr-md-n1,
  .LandingPage .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .LandingPage .mb-md-n1,
  .LandingPage .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .LandingPage .ml-md-n1,
  .LandingPage .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .LandingPage .m-md-n2 {
    margin: -0.5rem !important;
  }
  .LandingPage .mt-md-n2,
  .LandingPage .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .LandingPage .mr-md-n2,
  .LandingPage .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .LandingPage .mb-md-n2,
  .LandingPage .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .LandingPage .ml-md-n2,
  .LandingPage .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .LandingPage .m-md-n3 {
    margin: -0.75rem !important;
  }
  .LandingPage .mt-md-n3,
  .LandingPage .my-md-n3 {
    margin-top: -0.75rem !important;
  }
  .LandingPage .mr-md-n3,
  .LandingPage .mx-md-n3 {
    margin-right: -0.75rem !important;
  }
  .LandingPage .mb-md-n3,
  .LandingPage .my-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .LandingPage .ml-md-n3,
  .LandingPage .mx-md-n3 {
    margin-left: -0.75rem !important;
  }
  .LandingPage .m-md-n4 {
    margin: -1rem !important;
  }
  .LandingPage .mt-md-n4,
  .LandingPage .my-md-n4 {
    margin-top: -1rem !important;
  }
  .LandingPage .mr-md-n4,
  .LandingPage .mx-md-n4 {
    margin-right: -1rem !important;
  }
  .LandingPage .mb-md-n4,
  .LandingPage .my-md-n4 {
    margin-bottom: -1rem !important;
  }
  .LandingPage .ml-md-n4,
  .LandingPage .mx-md-n4 {
    margin-left: -1rem !important;
  }
  .LandingPage .m-md-n5 {
    margin: -1.5rem !important;
  }
  .LandingPage .mt-md-n5,
  .LandingPage .my-md-n5 {
    margin-top: -1.5rem !important;
  }
  .LandingPage .mr-md-n5,
  .LandingPage .mx-md-n5 {
    margin-right: -1.5rem !important;
  }
  .LandingPage .mb-md-n5,
  .LandingPage .my-md-n5 {
    margin-bottom: -1.5rem !important;
  }
  .LandingPage .ml-md-n5,
  .LandingPage .mx-md-n5 {
    margin-left: -1.5rem !important;
  }
  .LandingPage .m-md-n6 {
    margin: -2rem !important;
  }
  .LandingPage .mt-md-n6,
  .LandingPage .my-md-n6 {
    margin-top: -2rem !important;
  }
  .LandingPage .mr-md-n6,
  .LandingPage .mx-md-n6 {
    margin-right: -2rem !important;
  }
  .LandingPage .mb-md-n6,
  .LandingPage .my-md-n6 {
    margin-bottom: -2rem !important;
  }
  .LandingPage .ml-md-n6,
  .LandingPage .mx-md-n6 {
    margin-left: -2rem !important;
  }
  .LandingPage .m-md-n7 {
    margin: -2.5rem !important;
  }
  .LandingPage .mt-md-n7,
  .LandingPage .my-md-n7 {
    margin-top: -2.5rem !important;
  }
  .LandingPage .mr-md-n7,
  .LandingPage .mx-md-n7 {
    margin-right: -2.5rem !important;
  }
  .LandingPage .mb-md-n7,
  .LandingPage .my-md-n7 {
    margin-bottom: -2.5rem !important;
  }
  .LandingPage .ml-md-n7,
  .LandingPage .mx-md-n7 {
    margin-left: -2.5rem !important;
  }
  .LandingPage .m-md-n8 {
    margin: -3rem !important;
  }
  .LandingPage .mt-md-n8,
  .LandingPage .my-md-n8 {
    margin-top: -3rem !important;
  }
  .LandingPage .mr-md-n8,
  .LandingPage .mx-md-n8 {
    margin-right: -3rem !important;
  }
  .LandingPage .mb-md-n8,
  .LandingPage .my-md-n8 {
    margin-bottom: -3rem !important;
  }
  .LandingPage .ml-md-n8,
  .LandingPage .mx-md-n8 {
    margin-left: -3rem !important;
  }
  .LandingPage .m-md-n9 {
    margin: -4rem !important;
  }
  .LandingPage .mt-md-n9,
  .LandingPage .my-md-n9 {
    margin-top: -4rem !important;
  }
  .LandingPage .mr-md-n9,
  .LandingPage .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .LandingPage .mb-md-n9,
  .LandingPage .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .LandingPage .ml-md-n9,
  .LandingPage .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .LandingPage .m-md-n10 {
    margin: -5rem !important;
  }
  .LandingPage .mt-md-n10,
  .LandingPage .my-md-n10 {
    margin-top: -5rem !important;
  }
  .LandingPage .mr-md-n10,
  .LandingPage .mx-md-n10 {
    margin-right: -5rem !important;
  }
  .LandingPage .mb-md-n10,
  .LandingPage .my-md-n10 {
    margin-bottom: -5rem !important;
  }
  .LandingPage .ml-md-n10,
  .LandingPage .mx-md-n10 {
    margin-left: -5rem !important;
  }
  .LandingPage .m-md-n11 {
    margin: -6rem !important;
  }
  .LandingPage .mt-md-n11,
  .LandingPage .my-md-n11 {
    margin-top: -6rem !important;
  }
  .LandingPage .mr-md-n11,
  .LandingPage .mx-md-n11 {
    margin-right: -6rem !important;
  }
  .LandingPage .mb-md-n11,
  .LandingPage .my-md-n11 {
    margin-bottom: -6rem !important;
  }
  .LandingPage .ml-md-n11,
  .LandingPage .mx-md-n11 {
    margin-left: -6rem !important;
  }
  .LandingPage .m-md-n12 {
    margin: -8rem !important;
  }
  .LandingPage .mt-md-n12,
  .LandingPage .my-md-n12 {
    margin-top: -8rem !important;
  }
  .LandingPage .mr-md-n12,
  .LandingPage .mx-md-n12 {
    margin-right: -8rem !important;
  }
  .LandingPage .mb-md-n12,
  .LandingPage .my-md-n12 {
    margin-bottom: -8rem !important;
  }
  .LandingPage .ml-md-n12,
  .LandingPage .mx-md-n12 {
    margin-left: -8rem !important;
  }
  .LandingPage .m-md-n13 {
    margin: -10rem !important;
  }
  .LandingPage .mt-md-n13,
  .LandingPage .my-md-n13 {
    margin-top: -10rem !important;
  }
  .LandingPage .mr-md-n13,
  .LandingPage .mx-md-n13 {
    margin-right: -10rem !important;
  }
  .LandingPage .mb-md-n13,
  .LandingPage .my-md-n13 {
    margin-bottom: -10rem !important;
  }
  .LandingPage .ml-md-n13,
  .LandingPage .mx-md-n13 {
    margin-left: -10rem !important;
  }
  .LandingPage .m-md-n14 {
    margin: -12rem !important;
  }
  .LandingPage .mt-md-n14,
  .LandingPage .my-md-n14 {
    margin-top: -12rem !important;
  }
  .LandingPage .mr-md-n14,
  .LandingPage .mx-md-n14 {
    margin-right: -12rem !important;
  }
  .LandingPage .mb-md-n14,
  .LandingPage .my-md-n14 {
    margin-bottom: -12rem !important;
  }
  .LandingPage .ml-md-n14,
  .LandingPage .mx-md-n14 {
    margin-left: -12rem !important;
  }
  .LandingPage .m-md-n15 {
    margin: -16rem !important;
  }
  .LandingPage .mt-md-n15,
  .LandingPage .my-md-n15 {
    margin-top: -16rem !important;
  }
  .LandingPage .mr-md-n15,
  .LandingPage .mx-md-n15 {
    margin-right: -16rem !important;
  }
  .LandingPage .mb-md-n15,
  .LandingPage .my-md-n15 {
    margin-bottom: -16rem !important;
  }
  .LandingPage .ml-md-n15,
  .LandingPage .mx-md-n15 {
    margin-left: -16rem !important;
  }
  .LandingPage .m-md-n16 {
    margin: -25rem !important;
  }
  .LandingPage .mt-md-n16,
  .LandingPage .my-md-n16 {
    margin-top: -25rem !important;
  }
  .LandingPage .mr-md-n16,
  .LandingPage .mx-md-n16 {
    margin-right: -25rem !important;
  }
  .LandingPage .mb-md-n16,
  .LandingPage .my-md-n16 {
    margin-bottom: -25rem !important;
  }
  .LandingPage .ml-md-n16,
  .LandingPage .mx-md-n16 {
    margin-left: -25rem !important;
  }
  .LandingPage .m-md-auto {
    margin: auto !important;
  }
  .LandingPage .mt-md-auto,
  .LandingPage .my-md-auto {
    margin-top: auto !important;
  }
  .LandingPage .mr-md-auto,
  .LandingPage .mx-md-auto {
    margin-right: auto !important;
  }
  .LandingPage .mb-md-auto,
  .LandingPage .my-md-auto {
    margin-bottom: auto !important;
  }
  .LandingPage .ml-md-auto,
  .LandingPage .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .LandingPage .m-lg-0 {
    margin: 0 !important;
  }
  .LandingPage .mt-lg-0,
  .LandingPage .my-lg-0 {
    margin-top: 0 !important;
  }
  .LandingPage .mr-lg-0,
  .LandingPage .mx-lg-0 {
    margin-right: 0 !important;
  }
  .LandingPage .mb-lg-0,
  .LandingPage .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .LandingPage .ml-lg-0,
  .LandingPage .mx-lg-0 {
    margin-left: 0 !important;
  }
  .LandingPage .m-lg-1 {
    margin: 0.25rem !important;
  }
  .LandingPage .mt-lg-1,
  .LandingPage .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .LandingPage .mr-lg-1,
  .LandingPage .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .LandingPage .mb-lg-1,
  .LandingPage .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .LandingPage .ml-lg-1,
  .LandingPage .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .LandingPage .m-lg-2 {
    margin: 0.5rem !important;
  }
  .LandingPage .mt-lg-2,
  .LandingPage .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .LandingPage .mr-lg-2,
  .LandingPage .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .LandingPage .mb-lg-2,
  .LandingPage .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .LandingPage .ml-lg-2,
  .LandingPage .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .LandingPage .m-lg-3 {
    margin: 0.75rem !important;
  }
  .LandingPage .mt-lg-3,
  .LandingPage .my-lg-3 {
    margin-top: 0.75rem !important;
  }
  .LandingPage .mr-lg-3,
  .LandingPage .mx-lg-3 {
    margin-right: 0.75rem !important;
  }
  .LandingPage .mb-lg-3,
  .LandingPage .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .LandingPage .ml-lg-3,
  .LandingPage .mx-lg-3 {
    margin-left: 0.75rem !important;
  }
  .LandingPage .m-lg-4 {
    margin: 1rem !important;
  }
  .LandingPage .mt-lg-4,
  .LandingPage .my-lg-4 {
    margin-top: 1rem !important;
  }
  .LandingPage .mr-lg-4,
  .LandingPage .mx-lg-4 {
    margin-right: 1rem !important;
  }
  .LandingPage .mb-lg-4,
  .LandingPage .my-lg-4 {
    margin-bottom: 1rem !important;
  }
  .LandingPage .ml-lg-4,
  .LandingPage .mx-lg-4 {
    margin-left: 1rem !important;
  }
  .LandingPage .m-lg-5 {
    margin: 1.5rem !important;
  }
  .LandingPage .mt-lg-5,
  .LandingPage .my-lg-5 {
    margin-top: 1.5rem !important;
  }
  .LandingPage .mr-lg-5,
  .LandingPage .mx-lg-5 {
    margin-right: 1.5rem !important;
  }
  .LandingPage .mb-lg-5,
  .LandingPage .my-lg-5 {
    margin-bottom: 1.5rem !important;
  }
  .LandingPage .ml-lg-5,
  .LandingPage .mx-lg-5 {
    margin-left: 1.5rem !important;
  }
  .LandingPage .m-lg-6 {
    margin: 2rem !important;
  }
  .LandingPage .mt-lg-6,
  .LandingPage .my-lg-6 {
    margin-top: 2rem !important;
  }
  .LandingPage .mr-lg-6,
  .LandingPage .mx-lg-6 {
    margin-right: 2rem !important;
  }
  .LandingPage .mb-lg-6,
  .LandingPage .my-lg-6 {
    margin-bottom: 2rem !important;
  }
  .LandingPage .ml-lg-6,
  .LandingPage .mx-lg-6 {
    margin-left: 2rem !important;
  }
  .LandingPage .m-lg-7 {
    margin: 2.5rem !important;
  }
  .LandingPage .mt-lg-7,
  .LandingPage .my-lg-7 {
    margin-top: 2.5rem !important;
  }
  .LandingPage .mr-lg-7,
  .LandingPage .mx-lg-7 {
    margin-right: 2.5rem !important;
  }
  .LandingPage .mb-lg-7,
  .LandingPage .my-lg-7 {
    margin-bottom: 2.5rem !important;
  }
  .LandingPage .ml-lg-7,
  .LandingPage .mx-lg-7 {
    margin-left: 2.5rem !important;
  }
  .LandingPage .m-lg-8 {
    margin: 3rem !important;
  }
  .LandingPage .mt-lg-8,
  .LandingPage .my-lg-8 {
    margin-top: 3rem !important;
  }
  .LandingPage .mr-lg-8,
  .LandingPage .mx-lg-8 {
    margin-right: 3rem !important;
  }
  .LandingPage .mb-lg-8,
  .LandingPage .my-lg-8 {
    margin-bottom: 3rem !important;
  }
  .LandingPage .ml-lg-8,
  .LandingPage .mx-lg-8 {
    margin-left: 3rem !important;
  }
  .LandingPage .m-lg-9 {
    margin: 4rem !important;
  }
  .LandingPage .mt-lg-9,
  .LandingPage .my-lg-9 {
    margin-top: 4rem !important;
  }
  .LandingPage .mr-lg-9,
  .LandingPage .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .LandingPage .mb-lg-9,
  .LandingPage .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .LandingPage .ml-lg-9,
  .LandingPage .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .LandingPage .m-lg-10 {
    margin: 5rem !important;
  }
  .LandingPage .mt-lg-10,
  .LandingPage .my-lg-10 {
    margin-top: 5rem !important;
  }
  .LandingPage .mr-lg-10,
  .LandingPage .mx-lg-10 {
    margin-right: 5rem !important;
  }
  .LandingPage .mb-lg-10,
  .LandingPage .my-lg-10 {
    margin-bottom: 5rem !important;
  }
  .LandingPage .ml-lg-10,
  .LandingPage .mx-lg-10 {
    margin-left: 5rem !important;
  }
  .LandingPage .m-lg-11 {
    margin: 6rem !important;
  }
  .LandingPage .mt-lg-11,
  .LandingPage .my-lg-11 {
    margin-top: 6rem !important;
  }
  .LandingPage .mr-lg-11,
  .LandingPage .mx-lg-11 {
    margin-right: 6rem !important;
  }
  .LandingPage .mb-lg-11,
  .LandingPage .my-lg-11 {
    margin-bottom: 6rem !important;
  }
  .LandingPage .ml-lg-11,
  .LandingPage .mx-lg-11 {
    margin-left: 6rem !important;
  }
  .LandingPage .m-lg-12 {
    margin: 8rem !important;
  }
  .LandingPage .mt-lg-12,
  .LandingPage .my-lg-12 {
    margin-top: 8rem !important;
  }
  .LandingPage .mr-lg-12,
  .LandingPage .mx-lg-12 {
    margin-right: 8rem !important;
  }
  .LandingPage .mb-lg-12,
  .LandingPage .my-lg-12 {
    margin-bottom: 8rem !important;
  }
  .LandingPage .ml-lg-12,
  .LandingPage .mx-lg-12 {
    margin-left: 8rem !important;
  }
  .LandingPage .m-lg-13 {
    margin: 10rem !important;
  }
  .LandingPage .mt-lg-13,
  .LandingPage .my-lg-13 {
    margin-top: 10rem !important;
  }
  .LandingPage .mr-lg-13,
  .LandingPage .mx-lg-13 {
    margin-right: 10rem !important;
  }
  .LandingPage .mb-lg-13,
  .LandingPage .my-lg-13 {
    margin-bottom: 10rem !important;
  }
  .LandingPage .ml-lg-13,
  .LandingPage .mx-lg-13 {
    margin-left: 10rem !important;
  }
  .LandingPage .m-lg-14 {
    margin: 12rem !important;
  }
  .LandingPage .mt-lg-14,
  .LandingPage .my-lg-14 {
    margin-top: 12rem !important;
  }
  .LandingPage .mr-lg-14,
  .LandingPage .mx-lg-14 {
    margin-right: 12rem !important;
  }
  .LandingPage .mb-lg-14,
  .LandingPage .my-lg-14 {
    margin-bottom: 12rem !important;
  }
  .LandingPage .ml-lg-14,
  .LandingPage .mx-lg-14 {
    margin-left: 12rem !important;
  }
  .LandingPage .m-lg-15 {
    margin: 16rem !important;
  }
  .LandingPage .mt-lg-15,
  .LandingPage .my-lg-15 {
    margin-top: 16rem !important;
  }
  .LandingPage .mr-lg-15,
  .LandingPage .mx-lg-15 {
    margin-right: 16rem !important;
  }
  .LandingPage .mb-lg-15,
  .LandingPage .my-lg-15 {
    margin-bottom: 16rem !important;
  }
  .LandingPage .ml-lg-15,
  .LandingPage .mx-lg-15 {
    margin-left: 16rem !important;
  }
  .LandingPage .m-lg-16 {
    margin: 25rem !important;
  }
  .LandingPage .mt-lg-16,
  .LandingPage .my-lg-16 {
    margin-top: 25rem !important;
  }
  .LandingPage .mr-lg-16,
  .LandingPage .mx-lg-16 {
    margin-right: 25rem !important;
  }
  .LandingPage .mb-lg-16,
  .LandingPage .my-lg-16 {
    margin-bottom: 25rem !important;
  }
  .LandingPage .ml-lg-16,
  .LandingPage .mx-lg-16 {
    margin-left: 25rem !important;
  }
  .LandingPage .p-lg-0 {
    padding: 0 !important;
  }
  .LandingPage .pt-lg-0,
  .LandingPage .py-lg-0 {
    padding-top: 0 !important;
  }
  .LandingPage .pr-lg-0,
  .LandingPage .px-lg-0 {
    padding-right: 0 !important;
  }
  .LandingPage .pb-lg-0,
  .LandingPage .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .LandingPage .pl-lg-0,
  .LandingPage .px-lg-0 {
    padding-left: 0 !important;
  }
  .LandingPage .p-lg-1 {
    padding: 0.25rem !important;
  }
  .LandingPage .pt-lg-1,
  .LandingPage .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .LandingPage .pr-lg-1,
  .LandingPage .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .LandingPage .pb-lg-1,
  .LandingPage .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .LandingPage .pl-lg-1,
  .LandingPage .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .LandingPage .p-lg-2 {
    padding: 0.5rem !important;
  }
  .LandingPage .pt-lg-2,
  .LandingPage .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .LandingPage .pr-lg-2,
  .LandingPage .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .LandingPage .pb-lg-2,
  .LandingPage .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .LandingPage .pl-lg-2,
  .LandingPage .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .LandingPage .p-lg-3 {
    padding: 0.75rem !important;
  }
  .LandingPage .pt-lg-3,
  .LandingPage .py-lg-3 {
    padding-top: 0.75rem !important;
  }
  .LandingPage .pr-lg-3,
  .LandingPage .px-lg-3 {
    padding-right: 0.75rem !important;
  }
  .LandingPage .pb-lg-3,
  .LandingPage .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .LandingPage .pl-lg-3,
  .LandingPage .px-lg-3 {
    padding-left: 0.75rem !important;
  }
  .LandingPage .p-lg-4 {
    padding: 1rem !important;
  }
  .LandingPage .pt-lg-4,
  .LandingPage .py-lg-4 {
    padding-top: 1rem !important;
  }
  .LandingPage .pr-lg-4,
  .LandingPage .px-lg-4 {
    padding-right: 1rem !important;
  }
  .LandingPage .pb-lg-4,
  .LandingPage .py-lg-4 {
    padding-bottom: 1rem !important;
  }
  .LandingPage .pl-lg-4,
  .LandingPage .px-lg-4 {
    padding-left: 1rem !important;
  }
  .LandingPage .p-lg-5 {
    padding: 1.5rem !important;
  }
  .LandingPage .pt-lg-5,
  .LandingPage .py-lg-5 {
    padding-top: 1.5rem !important;
  }
  .LandingPage .pr-lg-5,
  .LandingPage .px-lg-5 {
    padding-right: 1.5rem !important;
  }
  .LandingPage .pb-lg-5,
  .LandingPage .py-lg-5 {
    padding-bottom: 1.5rem !important;
  }
  .LandingPage .pl-lg-5,
  .LandingPage .px-lg-5 {
    padding-left: 1.5rem !important;
  }
  .LandingPage .p-lg-6 {
    padding: 2rem !important;
  }
  .LandingPage .pt-lg-6,
  .LandingPage .py-lg-6 {
    padding-top: 2rem !important;
  }
  .LandingPage .pr-lg-6,
  .LandingPage .px-lg-6 {
    padding-right: 2rem !important;
  }
  .LandingPage .pb-lg-6,
  .LandingPage .py-lg-6 {
    padding-bottom: 2rem !important;
  }
  .LandingPage .pl-lg-6,
  .LandingPage .px-lg-6 {
    padding-left: 2rem !important;
  }
  .LandingPage .p-lg-7 {
    padding: 2.5rem !important;
  }
  .LandingPage .pt-lg-7,
  .LandingPage .py-lg-7 {
    padding-top: 2.5rem !important;
  }
  .LandingPage .pr-lg-7,
  .LandingPage .px-lg-7 {
    padding-right: 2.5rem !important;
  }
  .LandingPage .pb-lg-7,
  .LandingPage .py-lg-7 {
    padding-bottom: 2.5rem !important;
  }
  .LandingPage .pl-lg-7,
  .LandingPage .px-lg-7 {
    padding-left: 2.5rem !important;
  }
  .LandingPage .p-lg-8 {
    padding: 3rem !important;
  }
  .LandingPage .pt-lg-8,
  .LandingPage .py-lg-8 {
    padding-top: 3rem !important;
  }
  .LandingPage .pr-lg-8,
  .LandingPage .px-lg-8 {
    padding-right: 3rem !important;
  }
  .LandingPage .pb-lg-8,
  .LandingPage .py-lg-8 {
    padding-bottom: 3rem !important;
  }
  .LandingPage .pl-lg-8,
  .LandingPage .px-lg-8 {
    padding-left: 3rem !important;
  }
  .LandingPage .p-lg-9 {
    padding: 4rem !important;
  }
  .LandingPage .pt-lg-9,
  .LandingPage .py-lg-9 {
    padding-top: 4rem !important;
  }
  .LandingPage .pr-lg-9,
  .LandingPage .px-lg-9 {
    padding-right: 4rem !important;
  }
  .LandingPage .pb-lg-9,
  .LandingPage .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .LandingPage .pl-lg-9,
  .LandingPage .px-lg-9 {
    padding-left: 4rem !important;
  }
  .LandingPage .p-lg-10 {
    padding: 5rem !important;
  }
  .LandingPage .pt-lg-10,
  .LandingPage .py-lg-10 {
    padding-top: 5rem !important;
  }
  .LandingPage .pr-lg-10,
  .LandingPage .px-lg-10 {
    padding-right: 5rem !important;
  }
  .LandingPage .pb-lg-10,
  .LandingPage .py-lg-10 {
    padding-bottom: 5rem !important;
  }
  .LandingPage .pl-lg-10,
  .LandingPage .px-lg-10 {
    padding-left: 5rem !important;
  }
  .LandingPage .p-lg-11 {
    padding: 6rem !important;
  }
  .LandingPage .pt-lg-11,
  .LandingPage .py-lg-11 {
    padding-top: 6rem !important;
  }
  .LandingPage .pr-lg-11,
  .LandingPage .px-lg-11 {
    padding-right: 6rem !important;
  }
  .LandingPage .pb-lg-11,
  .LandingPage .py-lg-11 {
    padding-bottom: 6rem !important;
  }
  .LandingPage .pl-lg-11,
  .LandingPage .px-lg-11 {
    padding-left: 6rem !important;
  }
  .LandingPage .p-lg-12 {
    padding: 8rem !important;
  }
  .LandingPage .pt-lg-12,
  .LandingPage .py-lg-12 {
    padding-top: 8rem !important;
  }
  .LandingPage .pr-lg-12,
  .LandingPage .px-lg-12 {
    padding-right: 8rem !important;
  }
  .LandingPage .pb-lg-12,
  .LandingPage .py-lg-12 {
    padding-bottom: 8rem !important;
  }
  .LandingPage .pl-lg-12,
  .LandingPage .px-lg-12 {
    padding-left: 8rem !important;
  }
  .LandingPage .p-lg-13 {
    padding: 10rem !important;
  }
  .LandingPage .pt-lg-13,
  .LandingPage .py-lg-13 {
    padding-top: 10rem !important;
  }
  .LandingPage .pr-lg-13,
  .LandingPage .px-lg-13 {
    padding-right: 10rem !important;
  }
  .LandingPage .pb-lg-13,
  .LandingPage .py-lg-13 {
    padding-bottom: 10rem !important;
  }
  .LandingPage .pl-lg-13,
  .LandingPage .px-lg-13 {
    padding-left: 10rem !important;
  }
  .LandingPage .p-lg-14 {
    padding: 12rem !important;
  }
  .LandingPage .pt-lg-14,
  .LandingPage .py-lg-14 {
    padding-top: 12rem !important;
  }
  .LandingPage .pr-lg-14,
  .LandingPage .px-lg-14 {
    padding-right: 12rem !important;
  }
  .LandingPage .pb-lg-14,
  .LandingPage .py-lg-14 {
    padding-bottom: 12rem !important;
  }
  .LandingPage .pl-lg-14,
  .LandingPage .px-lg-14 {
    padding-left: 12rem !important;
  }
  .LandingPage .p-lg-15 {
    padding: 16rem !important;
  }
  .LandingPage .pt-lg-15,
  .LandingPage .py-lg-15 {
    padding-top: 16rem !important;
  }
  .LandingPage .pr-lg-15,
  .LandingPage .px-lg-15 {
    padding-right: 16rem !important;
  }
  .LandingPage .pb-lg-15,
  .LandingPage .py-lg-15 {
    padding-bottom: 16rem !important;
  }
  .LandingPage .pl-lg-15,
  .LandingPage .px-lg-15 {
    padding-left: 16rem !important;
  }
  .LandingPage .p-lg-16 {
    padding: 25rem !important;
  }
  .LandingPage .pt-lg-16,
  .LandingPage .py-lg-16 {
    padding-top: 25rem !important;
  }
  .LandingPage .pr-lg-16,
  .LandingPage .px-lg-16 {
    padding-right: 25rem !important;
  }
  .LandingPage .pb-lg-16,
  .LandingPage .py-lg-16 {
    padding-bottom: 25rem !important;
  }
  .LandingPage .pl-lg-16,
  .LandingPage .px-lg-16 {
    padding-left: 25rem !important;
  }
  .LandingPage .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .LandingPage .mt-lg-n1,
  .LandingPage .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .LandingPage .mr-lg-n1,
  .LandingPage .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .LandingPage .mb-lg-n1,
  .LandingPage .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .LandingPage .ml-lg-n1,
  .LandingPage .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .LandingPage .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .LandingPage .mt-lg-n2,
  .LandingPage .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .LandingPage .mr-lg-n2,
  .LandingPage .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .LandingPage .mb-lg-n2,
  .LandingPage .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .LandingPage .ml-lg-n2,
  .LandingPage .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .LandingPage .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .LandingPage .mt-lg-n3,
  .LandingPage .my-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .LandingPage .mr-lg-n3,
  .LandingPage .mx-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .LandingPage .mb-lg-n3,
  .LandingPage .my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .LandingPage .ml-lg-n3,
  .LandingPage .mx-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .LandingPage .m-lg-n4 {
    margin: -1rem !important;
  }
  .LandingPage .mt-lg-n4,
  .LandingPage .my-lg-n4 {
    margin-top: -1rem !important;
  }
  .LandingPage .mr-lg-n4,
  .LandingPage .mx-lg-n4 {
    margin-right: -1rem !important;
  }
  .LandingPage .mb-lg-n4,
  .LandingPage .my-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .LandingPage .ml-lg-n4,
  .LandingPage .mx-lg-n4 {
    margin-left: -1rem !important;
  }
  .LandingPage .m-lg-n5 {
    margin: -1.5rem !important;
  }
  .LandingPage .mt-lg-n5,
  .LandingPage .my-lg-n5 {
    margin-top: -1.5rem !important;
  }
  .LandingPage .mr-lg-n5,
  .LandingPage .mx-lg-n5 {
    margin-right: -1.5rem !important;
  }
  .LandingPage .mb-lg-n5,
  .LandingPage .my-lg-n5 {
    margin-bottom: -1.5rem !important;
  }
  .LandingPage .ml-lg-n5,
  .LandingPage .mx-lg-n5 {
    margin-left: -1.5rem !important;
  }
  .LandingPage .m-lg-n6 {
    margin: -2rem !important;
  }
  .LandingPage .mt-lg-n6,
  .LandingPage .my-lg-n6 {
    margin-top: -2rem !important;
  }
  .LandingPage .mr-lg-n6,
  .LandingPage .mx-lg-n6 {
    margin-right: -2rem !important;
  }
  .LandingPage .mb-lg-n6,
  .LandingPage .my-lg-n6 {
    margin-bottom: -2rem !important;
  }
  .LandingPage .ml-lg-n6,
  .LandingPage .mx-lg-n6 {
    margin-left: -2rem !important;
  }
  .LandingPage .m-lg-n7 {
    margin: -2.5rem !important;
  }
  .LandingPage .mt-lg-n7,
  .LandingPage .my-lg-n7 {
    margin-top: -2.5rem !important;
  }
  .LandingPage .mr-lg-n7,
  .LandingPage .mx-lg-n7 {
    margin-right: -2.5rem !important;
  }
  .LandingPage .mb-lg-n7,
  .LandingPage .my-lg-n7 {
    margin-bottom: -2.5rem !important;
  }
  .LandingPage .ml-lg-n7,
  .LandingPage .mx-lg-n7 {
    margin-left: -2.5rem !important;
  }
  .LandingPage .m-lg-n8 {
    margin: -3rem !important;
  }
  .LandingPage .mt-lg-n8,
  .LandingPage .my-lg-n8 {
    margin-top: -3rem !important;
  }
  .LandingPage .mr-lg-n8,
  .LandingPage .mx-lg-n8 {
    margin-right: -3rem !important;
  }
  .LandingPage .mb-lg-n8,
  .LandingPage .my-lg-n8 {
    margin-bottom: -3rem !important;
  }
  .LandingPage .ml-lg-n8,
  .LandingPage .mx-lg-n8 {
    margin-left: -3rem !important;
  }
  .LandingPage .m-lg-n9 {
    margin: -4rem !important;
  }
  .LandingPage .mt-lg-n9,
  .LandingPage .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .LandingPage .mr-lg-n9,
  .LandingPage .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .LandingPage .mb-lg-n9,
  .LandingPage .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .LandingPage .ml-lg-n9,
  .LandingPage .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .LandingPage .m-lg-n10 {
    margin: -5rem !important;
  }
  .LandingPage .mt-lg-n10,
  .LandingPage .my-lg-n10 {
    margin-top: -5rem !important;
  }
  .LandingPage .mr-lg-n10,
  .LandingPage .mx-lg-n10 {
    margin-right: -5rem !important;
  }
  .LandingPage .mb-lg-n10,
  .LandingPage .my-lg-n10 {
    margin-bottom: -5rem !important;
  }
  .LandingPage .ml-lg-n10,
  .LandingPage .mx-lg-n10 {
    margin-left: -5rem !important;
  }
  .LandingPage .m-lg-n11 {
    margin: -6rem !important;
  }
  .LandingPage .mt-lg-n11,
  .LandingPage .my-lg-n11 {
    margin-top: -6rem !important;
  }
  .LandingPage .mr-lg-n11,
  .LandingPage .mx-lg-n11 {
    margin-right: -6rem !important;
  }
  .LandingPage .mb-lg-n11,
  .LandingPage .my-lg-n11 {
    margin-bottom: -6rem !important;
  }
  .LandingPage .ml-lg-n11,
  .LandingPage .mx-lg-n11 {
    margin-left: -6rem !important;
  }
  .LandingPage .m-lg-n12 {
    margin: -8rem !important;
  }
  .LandingPage .mt-lg-n12,
  .LandingPage .my-lg-n12 {
    margin-top: -8rem !important;
  }
  .LandingPage .mr-lg-n12,
  .LandingPage .mx-lg-n12 {
    margin-right: -8rem !important;
  }
  .LandingPage .mb-lg-n12,
  .LandingPage .my-lg-n12 {
    margin-bottom: -8rem !important;
  }
  .LandingPage .ml-lg-n12,
  .LandingPage .mx-lg-n12 {
    margin-left: -8rem !important;
  }
  .LandingPage .m-lg-n13 {
    margin: -10rem !important;
  }
  .LandingPage .mt-lg-n13,
  .LandingPage .my-lg-n13 {
    margin-top: -10rem !important;
  }
  .LandingPage .mr-lg-n13,
  .LandingPage .mx-lg-n13 {
    margin-right: -10rem !important;
  }
  .LandingPage .mb-lg-n13,
  .LandingPage .my-lg-n13 {
    margin-bottom: -10rem !important;
  }
  .LandingPage .ml-lg-n13,
  .LandingPage .mx-lg-n13 {
    margin-left: -10rem !important;
  }
  .LandingPage .m-lg-n14 {
    margin: -12rem !important;
  }
  .LandingPage .mt-lg-n14,
  .LandingPage .my-lg-n14 {
    margin-top: -12rem !important;
  }
  .LandingPage .mr-lg-n14,
  .LandingPage .mx-lg-n14 {
    margin-right: -12rem !important;
  }
  .LandingPage .mb-lg-n14,
  .LandingPage .my-lg-n14 {
    margin-bottom: -12rem !important;
  }
  .LandingPage .ml-lg-n14,
  .LandingPage .mx-lg-n14 {
    margin-left: -12rem !important;
  }
  .LandingPage .m-lg-n15 {
    margin: -16rem !important;
  }
  .LandingPage .mt-lg-n15,
  .LandingPage .my-lg-n15 {
    margin-top: -16rem !important;
  }
  .LandingPage .mr-lg-n15,
  .LandingPage .mx-lg-n15 {
    margin-right: -16rem !important;
  }
  .LandingPage .mb-lg-n15,
  .LandingPage .my-lg-n15 {
    margin-bottom: -16rem !important;
  }
  .LandingPage .ml-lg-n15,
  .LandingPage .mx-lg-n15 {
    margin-left: -16rem !important;
  }
  .LandingPage .m-lg-n16 {
    margin: -25rem !important;
  }
  .LandingPage .mt-lg-n16,
  .LandingPage .my-lg-n16 {
    margin-top: -25rem !important;
  }
  .LandingPage .mr-lg-n16,
  .LandingPage .mx-lg-n16 {
    margin-right: -25rem !important;
  }
  .LandingPage .mb-lg-n16,
  .LandingPage .my-lg-n16 {
    margin-bottom: -25rem !important;
  }
  .LandingPage .ml-lg-n16,
  .LandingPage .mx-lg-n16 {
    margin-left: -25rem !important;
  }
  .LandingPage .m-lg-auto {
    margin: auto !important;
  }
  .LandingPage .mt-lg-auto,
  .LandingPage .my-lg-auto {
    margin-top: auto !important;
  }
  .LandingPage .mr-lg-auto,
  .LandingPage .mx-lg-auto {
    margin-right: auto !important;
  }
  .LandingPage .mb-lg-auto,
  .LandingPage .my-lg-auto {
    margin-bottom: auto !important;
  }
  .LandingPage .ml-lg-auto,
  .LandingPage .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .LandingPage .m-xl-0 {
    margin: 0 !important;
  }
  .LandingPage .mt-xl-0,
  .LandingPage .my-xl-0 {
    margin-top: 0 !important;
  }
  .LandingPage .mr-xl-0,
  .LandingPage .mx-xl-0 {
    margin-right: 0 !important;
  }
  .LandingPage .mb-xl-0,
  .LandingPage .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .LandingPage .ml-xl-0,
  .LandingPage .mx-xl-0 {
    margin-left: 0 !important;
  }
  .LandingPage .m-xl-1 {
    margin: 0.25rem !important;
  }
  .LandingPage .mt-xl-1,
  .LandingPage .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .LandingPage .mr-xl-1,
  .LandingPage .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .LandingPage .mb-xl-1,
  .LandingPage .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .LandingPage .ml-xl-1,
  .LandingPage .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .LandingPage .m-xl-2 {
    margin: 0.5rem !important;
  }
  .LandingPage .mt-xl-2,
  .LandingPage .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .LandingPage .mr-xl-2,
  .LandingPage .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .LandingPage .mb-xl-2,
  .LandingPage .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .LandingPage .ml-xl-2,
  .LandingPage .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .LandingPage .m-xl-3 {
    margin: 0.75rem !important;
  }
  .LandingPage .mt-xl-3,
  .LandingPage .my-xl-3 {
    margin-top: 0.75rem !important;
  }
  .LandingPage .mr-xl-3,
  .LandingPage .mx-xl-3 {
    margin-right: 0.75rem !important;
  }
  .LandingPage .mb-xl-3,
  .LandingPage .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .LandingPage .ml-xl-3,
  .LandingPage .mx-xl-3 {
    margin-left: 0.75rem !important;
  }
  .LandingPage .m-xl-4 {
    margin: 1rem !important;
  }
  .LandingPage .mt-xl-4,
  .LandingPage .my-xl-4 {
    margin-top: 1rem !important;
  }
  .LandingPage .mr-xl-4,
  .LandingPage .mx-xl-4 {
    margin-right: 1rem !important;
  }
  .LandingPage .mb-xl-4,
  .LandingPage .my-xl-4 {
    margin-bottom: 1rem !important;
  }
  .LandingPage .ml-xl-4,
  .LandingPage .mx-xl-4 {
    margin-left: 1rem !important;
  }
  .LandingPage .m-xl-5 {
    margin: 1.5rem !important;
  }
  .LandingPage .mt-xl-5,
  .LandingPage .my-xl-5 {
    margin-top: 1.5rem !important;
  }
  .LandingPage .mr-xl-5,
  .LandingPage .mx-xl-5 {
    margin-right: 1.5rem !important;
  }
  .LandingPage .mb-xl-5,
  .LandingPage .my-xl-5 {
    margin-bottom: 1.5rem !important;
  }
  .LandingPage .ml-xl-5,
  .LandingPage .mx-xl-5 {
    margin-left: 1.5rem !important;
  }
  .LandingPage .m-xl-6 {
    margin: 2rem !important;
  }
  .LandingPage .mt-xl-6,
  .LandingPage .my-xl-6 {
    margin-top: 2rem !important;
  }
  .LandingPage .mr-xl-6,
  .LandingPage .mx-xl-6 {
    margin-right: 2rem !important;
  }
  .LandingPage .mb-xl-6,
  .LandingPage .my-xl-6 {
    margin-bottom: 2rem !important;
  }
  .LandingPage .ml-xl-6,
  .LandingPage .mx-xl-6 {
    margin-left: 2rem !important;
  }
  .LandingPage .m-xl-7 {
    margin: 2.5rem !important;
  }
  .LandingPage .mt-xl-7,
  .LandingPage .my-xl-7 {
    margin-top: 2.5rem !important;
  }
  .LandingPage .mr-xl-7,
  .LandingPage .mx-xl-7 {
    margin-right: 2.5rem !important;
  }
  .LandingPage .mb-xl-7,
  .LandingPage .my-xl-7 {
    margin-bottom: 2.5rem !important;
  }
  .LandingPage .ml-xl-7,
  .LandingPage .mx-xl-7 {
    margin-left: 2.5rem !important;
  }
  .LandingPage .m-xl-8 {
    margin: 3rem !important;
  }
  .LandingPage .mt-xl-8,
  .LandingPage .my-xl-8 {
    margin-top: 3rem !important;
  }
  .LandingPage .mr-xl-8,
  .LandingPage .mx-xl-8 {
    margin-right: 3rem !important;
  }
  .LandingPage .mb-xl-8,
  .LandingPage .my-xl-8 {
    margin-bottom: 3rem !important;
  }
  .LandingPage .ml-xl-8,
  .LandingPage .mx-xl-8 {
    margin-left: 3rem !important;
  }
  .LandingPage .m-xl-9 {
    margin: 4rem !important;
  }
  .LandingPage .mt-xl-9,
  .LandingPage .my-xl-9 {
    margin-top: 4rem !important;
  }
  .LandingPage .mr-xl-9,
  .LandingPage .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .LandingPage .mb-xl-9,
  .LandingPage .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .LandingPage .ml-xl-9,
  .LandingPage .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .LandingPage .m-xl-10 {
    margin: 5rem !important;
  }
  .LandingPage .mt-xl-10,
  .LandingPage .my-xl-10 {
    margin-top: 5rem !important;
  }
  .LandingPage .mr-xl-10,
  .LandingPage .mx-xl-10 {
    margin-right: 5rem !important;
  }
  .LandingPage .mb-xl-10,
  .LandingPage .my-xl-10 {
    margin-bottom: 5rem !important;
  }
  .LandingPage .ml-xl-10,
  .LandingPage .mx-xl-10 {
    margin-left: 5rem !important;
  }
  .LandingPage .m-xl-11 {
    margin: 6rem !important;
  }
  .LandingPage .mt-xl-11,
  .LandingPage .my-xl-11 {
    margin-top: 6rem !important;
  }
  .LandingPage .mr-xl-11,
  .LandingPage .mx-xl-11 {
    margin-right: 6rem !important;
  }
  .LandingPage .mb-xl-11,
  .LandingPage .my-xl-11 {
    margin-bottom: 6rem !important;
  }
  .LandingPage .ml-xl-11,
  .LandingPage .mx-xl-11 {
    margin-left: 6rem !important;
  }
  .LandingPage .m-xl-12 {
    margin: 8rem !important;
  }
  .LandingPage .mt-xl-12,
  .LandingPage .my-xl-12 {
    margin-top: 8rem !important;
  }
  .LandingPage .mr-xl-12,
  .LandingPage .mx-xl-12 {
    margin-right: 8rem !important;
  }
  .LandingPage .mb-xl-12,
  .LandingPage .my-xl-12 {
    margin-bottom: 8rem !important;
  }
  .LandingPage .ml-xl-12,
  .LandingPage .mx-xl-12 {
    margin-left: 8rem !important;
  }
  .LandingPage .m-xl-13 {
    margin: 10rem !important;
  }
  .LandingPage .mt-xl-13,
  .LandingPage .my-xl-13 {
    margin-top: 10rem !important;
  }
  .LandingPage .mr-xl-13,
  .LandingPage .mx-xl-13 {
    margin-right: 10rem !important;
  }
  .LandingPage .mb-xl-13,
  .LandingPage .my-xl-13 {
    margin-bottom: 10rem !important;
  }
  .LandingPage .ml-xl-13,
  .LandingPage .mx-xl-13 {
    margin-left: 10rem !important;
  }
  .LandingPage .m-xl-14 {
    margin: 12rem !important;
  }
  .LandingPage .mt-xl-14,
  .LandingPage .my-xl-14 {
    margin-top: 12rem !important;
  }
  .LandingPage .mr-xl-14,
  .LandingPage .mx-xl-14 {
    margin-right: 12rem !important;
  }
  .LandingPage .mb-xl-14,
  .LandingPage .my-xl-14 {
    margin-bottom: 12rem !important;
  }
  .LandingPage .ml-xl-14,
  .LandingPage .mx-xl-14 {
    margin-left: 12rem !important;
  }
  .LandingPage .m-xl-15 {
    margin: 16rem !important;
  }
  .LandingPage .mt-xl-15,
  .LandingPage .my-xl-15 {
    margin-top: 16rem !important;
  }
  .LandingPage .mr-xl-15,
  .LandingPage .mx-xl-15 {
    margin-right: 16rem !important;
  }
  .LandingPage .mb-xl-15,
  .LandingPage .my-xl-15 {
    margin-bottom: 16rem !important;
  }
  .LandingPage .ml-xl-15,
  .LandingPage .mx-xl-15 {
    margin-left: 16rem !important;
  }
  .LandingPage .m-xl-16 {
    margin: 25rem !important;
  }
  .LandingPage .mt-xl-16,
  .LandingPage .my-xl-16 {
    margin-top: 25rem !important;
  }
  .LandingPage .mr-xl-16,
  .LandingPage .mx-xl-16 {
    margin-right: 25rem !important;
  }
  .LandingPage .mb-xl-16,
  .LandingPage .my-xl-16 {
    margin-bottom: 25rem !important;
  }
  .LandingPage .ml-xl-16,
  .LandingPage .mx-xl-16 {
    margin-left: 25rem !important;
  }
  .LandingPage .p-xl-0 {
    padding: 0 !important;
  }
  .LandingPage .pt-xl-0,
  .LandingPage .py-xl-0 {
    padding-top: 0 !important;
  }
  .LandingPage .pr-xl-0,
  .LandingPage .px-xl-0 {
    padding-right: 0 !important;
  }
  .LandingPage .pb-xl-0,
  .LandingPage .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .LandingPage .pl-xl-0,
  .LandingPage .px-xl-0 {
    padding-left: 0 !important;
  }
  .LandingPage .p-xl-1 {
    padding: 0.25rem !important;
  }
  .LandingPage .pt-xl-1,
  .LandingPage .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .LandingPage .pr-xl-1,
  .LandingPage .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .LandingPage .pb-xl-1,
  .LandingPage .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .LandingPage .pl-xl-1,
  .LandingPage .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .LandingPage .p-xl-2 {
    padding: 0.5rem !important;
  }
  .LandingPage .pt-xl-2,
  .LandingPage .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .LandingPage .pr-xl-2,
  .LandingPage .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .LandingPage .pb-xl-2,
  .LandingPage .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .LandingPage .pl-xl-2,
  .LandingPage .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .LandingPage .p-xl-3 {
    padding: 0.75rem !important;
  }
  .LandingPage .pt-xl-3,
  .LandingPage .py-xl-3 {
    padding-top: 0.75rem !important;
  }
  .LandingPage .pr-xl-3,
  .LandingPage .px-xl-3 {
    padding-right: 0.75rem !important;
  }
  .LandingPage .pb-xl-3,
  .LandingPage .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .LandingPage .pl-xl-3,
  .LandingPage .px-xl-3 {
    padding-left: 0.75rem !important;
  }
  .LandingPage .p-xl-4 {
    padding: 1rem !important;
  }
  .LandingPage .pt-xl-4,
  .LandingPage .py-xl-4 {
    padding-top: 1rem !important;
  }
  .LandingPage .pr-xl-4,
  .LandingPage .px-xl-4 {
    padding-right: 1rem !important;
  }
  .LandingPage .pb-xl-4,
  .LandingPage .py-xl-4 {
    padding-bottom: 1rem !important;
  }
  .LandingPage .pl-xl-4,
  .LandingPage .px-xl-4 {
    padding-left: 1rem !important;
  }
  .LandingPage .p-xl-5 {
    padding: 1.5rem !important;
  }
  .LandingPage .pt-xl-5,
  .LandingPage .py-xl-5 {
    padding-top: 1.5rem !important;
  }
  .LandingPage .pr-xl-5,
  .LandingPage .px-xl-5 {
    padding-right: 1.5rem !important;
  }
  .LandingPage .pb-xl-5,
  .LandingPage .py-xl-5 {
    padding-bottom: 1.5rem !important;
  }
  .LandingPage .pl-xl-5,
  .LandingPage .px-xl-5 {
    padding-left: 1.5rem !important;
  }
  .LandingPage .p-xl-6 {
    padding: 2rem !important;
  }
  .LandingPage .pt-xl-6,
  .LandingPage .py-xl-6 {
    padding-top: 2rem !important;
  }
  .LandingPage .pr-xl-6,
  .LandingPage .px-xl-6 {
    padding-right: 2rem !important;
  }
  .LandingPage .pb-xl-6,
  .LandingPage .py-xl-6 {
    padding-bottom: 2rem !important;
  }
  .LandingPage .pl-xl-6,
  .LandingPage .px-xl-6 {
    padding-left: 2rem !important;
  }
  .LandingPage .p-xl-7 {
    padding: 2.5rem !important;
  }
  .LandingPage .pt-xl-7,
  .LandingPage .py-xl-7 {
    padding-top: 2.5rem !important;
  }
  .LandingPage .pr-xl-7,
  .LandingPage .px-xl-7 {
    padding-right: 2.5rem !important;
  }
  .LandingPage .pb-xl-7,
  .LandingPage .py-xl-7 {
    padding-bottom: 2.5rem !important;
  }
  .LandingPage .pl-xl-7,
  .LandingPage .px-xl-7 {
    padding-left: 2.5rem !important;
  }
  .LandingPage .p-xl-8 {
    padding: 3rem !important;
  }
  .LandingPage .pt-xl-8,
  .LandingPage .py-xl-8 {
    padding-top: 3rem !important;
  }
  .LandingPage .pr-xl-8,
  .LandingPage .px-xl-8 {
    padding-right: 3rem !important;
  }
  .LandingPage .pb-xl-8,
  .LandingPage .py-xl-8 {
    padding-bottom: 3rem !important;
  }
  .LandingPage .pl-xl-8,
  .LandingPage .px-xl-8 {
    padding-left: 3rem !important;
  }
  .LandingPage .p-xl-9 {
    padding: 4rem !important;
  }
  .LandingPage .pt-xl-9,
  .LandingPage .py-xl-9 {
    padding-top: 4rem !important;
  }
  .LandingPage .pr-xl-9,
  .LandingPage .px-xl-9 {
    padding-right: 4rem !important;
  }
  .LandingPage .pb-xl-9,
  .LandingPage .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .LandingPage .pl-xl-9,
  .LandingPage .px-xl-9 {
    padding-left: 4rem !important;
  }
  .LandingPage .p-xl-10 {
    padding: 5rem !important;
  }
  .LandingPage .pt-xl-10,
  .LandingPage .py-xl-10 {
    padding-top: 5rem !important;
  }
  .LandingPage .pr-xl-10,
  .LandingPage .px-xl-10 {
    padding-right: 5rem !important;
  }
  .LandingPage .pb-xl-10,
  .LandingPage .py-xl-10 {
    padding-bottom: 5rem !important;
  }
  .LandingPage .pl-xl-10,
  .LandingPage .px-xl-10 {
    padding-left: 5rem !important;
  }
  .LandingPage .p-xl-11 {
    padding: 6rem !important;
  }
  .LandingPage .pt-xl-11,
  .LandingPage .py-xl-11 {
    padding-top: 6rem !important;
  }
  .LandingPage .pr-xl-11,
  .LandingPage .px-xl-11 {
    padding-right: 6rem !important;
  }
  .LandingPage .pb-xl-11,
  .LandingPage .py-xl-11 {
    padding-bottom: 6rem !important;
  }
  .LandingPage .pl-xl-11,
  .LandingPage .px-xl-11 {
    padding-left: 6rem !important;
  }
  .LandingPage .p-xl-12 {
    padding: 8rem !important;
  }
  .LandingPage .pt-xl-12,
  .LandingPage .py-xl-12 {
    padding-top: 8rem !important;
  }
  .LandingPage .pr-xl-12,
  .LandingPage .px-xl-12 {
    padding-right: 8rem !important;
  }
  .LandingPage .pb-xl-12,
  .LandingPage .py-xl-12 {
    padding-bottom: 8rem !important;
  }
  .LandingPage .pl-xl-12,
  .LandingPage .px-xl-12 {
    padding-left: 8rem !important;
  }
  .LandingPage .p-xl-13 {
    padding: 10rem !important;
  }
  .LandingPage .pt-xl-13,
  .LandingPage .py-xl-13 {
    padding-top: 10rem !important;
  }
  .LandingPage .pr-xl-13,
  .LandingPage .px-xl-13 {
    padding-right: 10rem !important;
  }
  .LandingPage .pb-xl-13,
  .LandingPage .py-xl-13 {
    padding-bottom: 10rem !important;
  }
  .LandingPage .pl-xl-13,
  .LandingPage .px-xl-13 {
    padding-left: 10rem !important;
  }
  .LandingPage .p-xl-14 {
    padding: 12rem !important;
  }
  .LandingPage .pt-xl-14,
  .LandingPage .py-xl-14 {
    padding-top: 12rem !important;
  }
  .LandingPage .pr-xl-14,
  .LandingPage .px-xl-14 {
    padding-right: 12rem !important;
  }
  .LandingPage .pb-xl-14,
  .LandingPage .py-xl-14 {
    padding-bottom: 12rem !important;
  }
  .LandingPage .pl-xl-14,
  .LandingPage .px-xl-14 {
    padding-left: 12rem !important;
  }
  .LandingPage .p-xl-15 {
    padding: 16rem !important;
  }
  .LandingPage .pt-xl-15,
  .LandingPage .py-xl-15 {
    padding-top: 16rem !important;
  }
  .LandingPage .pr-xl-15,
  .LandingPage .px-xl-15 {
    padding-right: 16rem !important;
  }
  .LandingPage .pb-xl-15,
  .LandingPage .py-xl-15 {
    padding-bottom: 16rem !important;
  }
  .LandingPage .pl-xl-15,
  .LandingPage .px-xl-15 {
    padding-left: 16rem !important;
  }
  .LandingPage .p-xl-16 {
    padding: 25rem !important;
  }
  .LandingPage .pt-xl-16,
  .LandingPage .py-xl-16 {
    padding-top: 25rem !important;
  }
  .LandingPage .pr-xl-16,
  .LandingPage .px-xl-16 {
    padding-right: 25rem !important;
  }
  .LandingPage .pb-xl-16,
  .LandingPage .py-xl-16 {
    padding-bottom: 25rem !important;
  }
  .LandingPage .pl-xl-16,
  .LandingPage .px-xl-16 {
    padding-left: 25rem !important;
  }
  .LandingPage .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .LandingPage .mt-xl-n1,
  .LandingPage .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .LandingPage .mr-xl-n1,
  .LandingPage .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .LandingPage .mb-xl-n1,
  .LandingPage .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .LandingPage .ml-xl-n1,
  .LandingPage .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .LandingPage .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .LandingPage .mt-xl-n2,
  .LandingPage .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .LandingPage .mr-xl-n2,
  .LandingPage .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .LandingPage .mb-xl-n2,
  .LandingPage .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .LandingPage .ml-xl-n2,
  .LandingPage .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .LandingPage .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .LandingPage .mt-xl-n3,
  .LandingPage .my-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .LandingPage .mr-xl-n3,
  .LandingPage .mx-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .LandingPage .mb-xl-n3,
  .LandingPage .my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .LandingPage .ml-xl-n3,
  .LandingPage .mx-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .LandingPage .m-xl-n4 {
    margin: -1rem !important;
  }
  .LandingPage .mt-xl-n4,
  .LandingPage .my-xl-n4 {
    margin-top: -1rem !important;
  }
  .LandingPage .mr-xl-n4,
  .LandingPage .mx-xl-n4 {
    margin-right: -1rem !important;
  }
  .LandingPage .mb-xl-n4,
  .LandingPage .my-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .LandingPage .ml-xl-n4,
  .LandingPage .mx-xl-n4 {
    margin-left: -1rem !important;
  }
  .LandingPage .m-xl-n5 {
    margin: -1.5rem !important;
  }
  .LandingPage .mt-xl-n5,
  .LandingPage .my-xl-n5 {
    margin-top: -1.5rem !important;
  }
  .LandingPage .mr-xl-n5,
  .LandingPage .mx-xl-n5 {
    margin-right: -1.5rem !important;
  }
  .LandingPage .mb-xl-n5,
  .LandingPage .my-xl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .LandingPage .ml-xl-n5,
  .LandingPage .mx-xl-n5 {
    margin-left: -1.5rem !important;
  }
  .LandingPage .m-xl-n6 {
    margin: -2rem !important;
  }
  .LandingPage .mt-xl-n6,
  .LandingPage .my-xl-n6 {
    margin-top: -2rem !important;
  }
  .LandingPage .mr-xl-n6,
  .LandingPage .mx-xl-n6 {
    margin-right: -2rem !important;
  }
  .LandingPage .mb-xl-n6,
  .LandingPage .my-xl-n6 {
    margin-bottom: -2rem !important;
  }
  .LandingPage .ml-xl-n6,
  .LandingPage .mx-xl-n6 {
    margin-left: -2rem !important;
  }
  .LandingPage .m-xl-n7 {
    margin: -2.5rem !important;
  }
  .LandingPage .mt-xl-n7,
  .LandingPage .my-xl-n7 {
    margin-top: -2.5rem !important;
  }
  .LandingPage .mr-xl-n7,
  .LandingPage .mx-xl-n7 {
    margin-right: -2.5rem !important;
  }
  .LandingPage .mb-xl-n7,
  .LandingPage .my-xl-n7 {
    margin-bottom: -2.5rem !important;
  }
  .LandingPage .ml-xl-n7,
  .LandingPage .mx-xl-n7 {
    margin-left: -2.5rem !important;
  }
  .LandingPage .m-xl-n8 {
    margin: -3rem !important;
  }
  .LandingPage .mt-xl-n8,
  .LandingPage .my-xl-n8 {
    margin-top: -3rem !important;
  }
  .LandingPage .mr-xl-n8,
  .LandingPage .mx-xl-n8 {
    margin-right: -3rem !important;
  }
  .LandingPage .mb-xl-n8,
  .LandingPage .my-xl-n8 {
    margin-bottom: -3rem !important;
  }
  .LandingPage .ml-xl-n8,
  .LandingPage .mx-xl-n8 {
    margin-left: -3rem !important;
  }
  .LandingPage .m-xl-n9 {
    margin: -4rem !important;
  }
  .LandingPage .mt-xl-n9,
  .LandingPage .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .LandingPage .mr-xl-n9,
  .LandingPage .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .LandingPage .mb-xl-n9,
  .LandingPage .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .LandingPage .ml-xl-n9,
  .LandingPage .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .LandingPage .m-xl-n10 {
    margin: -5rem !important;
  }
  .LandingPage .mt-xl-n10,
  .LandingPage .my-xl-n10 {
    margin-top: -5rem !important;
  }
  .LandingPage .mr-xl-n10,
  .LandingPage .mx-xl-n10 {
    margin-right: -5rem !important;
  }
  .LandingPage .mb-xl-n10,
  .LandingPage .my-xl-n10 {
    margin-bottom: -5rem !important;
  }
  .LandingPage .ml-xl-n10,
  .LandingPage .mx-xl-n10 {
    margin-left: -5rem !important;
  }
  .LandingPage .m-xl-n11 {
    margin: -6rem !important;
  }
  .LandingPage .mt-xl-n11,
  .LandingPage .my-xl-n11 {
    margin-top: -6rem !important;
  }
  .LandingPage .mr-xl-n11,
  .LandingPage .mx-xl-n11 {
    margin-right: -6rem !important;
  }
  .LandingPage .mb-xl-n11,
  .LandingPage .my-xl-n11 {
    margin-bottom: -6rem !important;
  }
  .LandingPage .ml-xl-n11,
  .LandingPage .mx-xl-n11 {
    margin-left: -6rem !important;
  }
  .LandingPage .m-xl-n12 {
    margin: -8rem !important;
  }
  .LandingPage .mt-xl-n12,
  .LandingPage .my-xl-n12 {
    margin-top: -8rem !important;
  }
  .LandingPage .mr-xl-n12,
  .LandingPage .mx-xl-n12 {
    margin-right: -8rem !important;
  }
  .LandingPage .mb-xl-n12,
  .LandingPage .my-xl-n12 {
    margin-bottom: -8rem !important;
  }
  .LandingPage .ml-xl-n12,
  .LandingPage .mx-xl-n12 {
    margin-left: -8rem !important;
  }
  .LandingPage .m-xl-n13 {
    margin: -10rem !important;
  }
  .LandingPage .mt-xl-n13,
  .LandingPage .my-xl-n13 {
    margin-top: -10rem !important;
  }
  .LandingPage .mr-xl-n13,
  .LandingPage .mx-xl-n13 {
    margin-right: -10rem !important;
  }
  .LandingPage .mb-xl-n13,
  .LandingPage .my-xl-n13 {
    margin-bottom: -10rem !important;
  }
  .LandingPage .ml-xl-n13,
  .LandingPage .mx-xl-n13 {
    margin-left: -10rem !important;
  }
  .LandingPage .m-xl-n14 {
    margin: -12rem !important;
  }
  .LandingPage .mt-xl-n14,
  .LandingPage .my-xl-n14 {
    margin-top: -12rem !important;
  }
  .LandingPage .mr-xl-n14,
  .LandingPage .mx-xl-n14 {
    margin-right: -12rem !important;
  }
  .LandingPage .mb-xl-n14,
  .LandingPage .my-xl-n14 {
    margin-bottom: -12rem !important;
  }
  .LandingPage .ml-xl-n14,
  .LandingPage .mx-xl-n14 {
    margin-left: -12rem !important;
  }
  .LandingPage .m-xl-n15 {
    margin: -16rem !important;
  }
  .LandingPage .mt-xl-n15,
  .LandingPage .my-xl-n15 {
    margin-top: -16rem !important;
  }
  .LandingPage .mr-xl-n15,
  .LandingPage .mx-xl-n15 {
    margin-right: -16rem !important;
  }
  .LandingPage .mb-xl-n15,
  .LandingPage .my-xl-n15 {
    margin-bottom: -16rem !important;
  }
  .LandingPage .ml-xl-n15,
  .LandingPage .mx-xl-n15 {
    margin-left: -16rem !important;
  }
  .LandingPage .m-xl-n16 {
    margin: -25rem !important;
  }
  .LandingPage .mt-xl-n16,
  .LandingPage .my-xl-n16 {
    margin-top: -25rem !important;
  }
  .LandingPage .mr-xl-n16,
  .LandingPage .mx-xl-n16 {
    margin-right: -25rem !important;
  }
  .LandingPage .mb-xl-n16,
  .LandingPage .my-xl-n16 {
    margin-bottom: -25rem !important;
  }
  .LandingPage .ml-xl-n16,
  .LandingPage .mx-xl-n16 {
    margin-left: -25rem !important;
  }
  .LandingPage .m-xl-auto {
    margin: auto !important;
  }
  .LandingPage .mt-xl-auto,
  .LandingPage .my-xl-auto {
    margin-top: auto !important;
  }
  .LandingPage .mr-xl-auto,
  .LandingPage .mx-xl-auto {
    margin-right: auto !important;
  }
  .LandingPage .mb-xl-auto,
  .LandingPage .my-xl-auto {
    margin-bottom: auto !important;
  }
  .LandingPage .ml-xl-auto,
  .LandingPage .mx-xl-auto {
    margin-left: auto !important;
  }
}
.LandingPage .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}
.LandingPage .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}
.LandingPage .text-justify {
  text-align: justify !important;
}
.LandingPage .text-wrap {
  white-space: normal !important;
}
.LandingPage .text-nowrap {
  white-space: nowrap !important;
}
.LandingPage .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.LandingPage .text-left {
  text-align: left !important;
}
.LandingPage .text-right {
  text-align: right !important;
}
.LandingPage .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .LandingPage .text-sm-left {
    text-align: left !important;
  }
  .LandingPage .text-sm-right {
    text-align: right !important;
  }
  .LandingPage .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .text-md-left {
    text-align: left !important;
  }
  .LandingPage .text-md-right {
    text-align: right !important;
  }
  .LandingPage .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .LandingPage .text-lg-left {
    text-align: left !important;
  }
  .LandingPage .text-lg-right {
    text-align: right !important;
  }
  .LandingPage .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .LandingPage .text-xl-left {
    text-align: left !important;
  }
  .LandingPage .text-xl-right {
    text-align: right !important;
  }
  .LandingPage .text-xl-center {
    text-align: center !important;
  }
}
.LandingPage .text-lowercase {
  text-transform: lowercase !important;
}
.LandingPage .text-uppercase {
  text-transform: uppercase !important;
}
.LandingPage .text-capitalize {
  text-transform: capitalize !important;
}
.LandingPage .font-weight-light {
  font-weight: 300 !important;
}
.LandingPage .font-weight-lighter {
  font-weight: lighter !important;
}
.LandingPage .font-weight-normal {
  font-weight: 400 !important;
}
.LandingPage .font-weight-bold {
  font-weight: 600 !important;
}
.LandingPage .font-weight-bolder {
  font-weight: bolder !important;
}
.LandingPage .font-italic {
  font-style: italic !important;
}
.LandingPage .text-white {
  color: #ffffff !important;
}
.LandingPage .text-primary {
  color: #335eea !important;
}
.LandingPage a.text-primary:hover,
.LandingPage a.text-primary:focus {
  color: #133bbd !important;
}
.LandingPage .text-secondary {
  color: #506690 !important;
}
.LandingPage a.text-secondary:hover,
.LandingPage a.text-secondary:focus {
  color: #35435f !important;
}
.LandingPage .text-success {
  color: #42ba96 !important;
}
.LandingPage a.text-success:hover,
.LandingPage a.text-success:focus {
  color: #2e8268 !important;
}
.LandingPage .text-info {
  color: #7c69ef !important;
}
.LandingPage a.text-info:hover,
.LandingPage a.text-info:focus {
  color: #4024e8 !important;
}
.LandingPage .text-warning {
  color: #fad776 !important;
}
.LandingPage a.text-warning:hover,
.LandingPage a.text-warning:focus {
  color: #f7c12c !important;
}
.LandingPage .text-danger {
  color: #df4759 !important;
}
.LandingPage a.text-danger:hover,
.LandingPage a.text-danger:focus {
  color: #b92032 !important;
}
.LandingPage .text-light {
  color: #f9fbfd !important;
}
.LandingPage a.text-light:hover,
.LandingPage a.text-light:focus {
  color: #c0d5ea !important;
}
.LandingPage .text-dark {
  color: #1b2a4e !important;
}
.LandingPage a.text-dark:hover,
.LandingPage a.text-dark:focus {
  color: #070b15 !important;
}
.LandingPage .text-primary-desat {
  color: #6c8aec !important;
}
.LandingPage a.text-primary-desat:hover,
.LandingPage a.text-primary-desat:focus {
  color: #2854e3 !important;
}
.LandingPage .text-black {
  color: #161c2d !important;
}
.LandingPage a.text-black:hover,
.LandingPage a.text-black:focus {
  color: black !important;
}
.LandingPage .text-white {
  color: #ffffff !important;
}
.LandingPage a.text-white:hover,
.LandingPage a.text-white:focus {
  color: #d9d9d9 !important;
}
.LandingPage .text-body {
  color: #161c2d !important;
}
.LandingPage .text-muted {
  color: #869ab8 !important;
}
.LandingPage .text-black-50 {
  color: rgba(22, 28, 45, 0.5) !important;
}
.LandingPage .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.LandingPage .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.LandingPage .text-decoration-none {
  text-decoration: none !important;
}
.LandingPage .text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.LandingPage .text-reset {
  color: inherit !important;
}
.LandingPage .visible {
  visibility: visible !important;
}
.LandingPage .invisible {
  visibility: hidden !important;
}
@media print {
  .LandingPage *,
  .LandingPage *::before,
  .LandingPage *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  .LandingPage a:not(.btn) {
    text-decoration: underline;
  }
  .LandingPage abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  .LandingPage pre {
    white-space: pre-wrap !important;
  }
  .LandingPage pre,
  .LandingPage blockquote {
    border: 1px solid #abbcd5;
    page-break-inside: avoid;
  }
  .LandingPage thead {
    display: table-header-group;
  }
  .LandingPage tr,
  .LandingPage img {
    page-break-inside: avoid;
  }
  .LandingPage p,
  .LandingPage h2,
  .LandingPage h3 {
    orphans: 3;
    widows: 3;
  }
  .LandingPage h2,
  .LandingPage h3 {
    page-break-after: avoid;
  }
  @page {
    .LandingPage {
      size: a3;
    }
  }
  .LandingPage .body {
    min-width: 992px !important;
  }
  .LandingPage .container {
    min-width: 992px !important;
  }
  .LandingPage .navbar {
    display: none;
  }
  .LandingPage .badge {
    border: 1px solid #161c2d;
  }
  .LandingPage .table {
    border-collapse: collapse !important;
  }
  .LandingPage .table td,
  .LandingPage .table th {
    background-color: #ffffff !important;
  }
  .LandingPage .table-bordered th,
  .LandingPage .table-bordered td {
    border: 1px solid #d9e2ef !important;
  }
  .LandingPage .table-dark {
    color: inherit;
  }
  .LandingPage .table-dark th,
  .LandingPage .table-dark td,
  .LandingPage .table-dark thead th,
  .LandingPage .table-dark tbody + tbody {
    border-color: #f1f4f8;
  }
  .LandingPage .table .thead-dark th {
    color: inherit;
    border-color: #f1f4f8;
  }
}
.LandingPage .alert-primary {
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .alert-primary hr {
  border-top-color: #1c4ce8;
}
.LandingPage .alert-primary .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-primary .close,
.LandingPage .alert-primary .alert-link {
  color: #ffffff;
}
.LandingPage .alert-secondary {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
}
.LandingPage .alert-secondary hr {
  border-top-color: #475a80;
}
.LandingPage .alert-secondary .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-secondary .close,
.LandingPage .alert-secondary .alert-link {
  color: #ffffff;
}
.LandingPage .alert-success {
  color: #ffffff;
  background-color: #42ba96;
  border-color: #42ba96;
}
.LandingPage .alert-success hr {
  border-top-color: #3ba787;
}
.LandingPage .alert-success .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-success .close,
.LandingPage .alert-success .alert-link {
  color: #ffffff;
}
.LandingPage .alert-info {
  color: #ffffff;
  background-color: #7c69ef;
  border-color: #7c69ef;
}
.LandingPage .alert-info hr {
  border-top-color: #6852ed;
}
.LandingPage .alert-info .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-info .close,
.LandingPage .alert-info .alert-link {
  color: #ffffff;
}
.LandingPage .alert-warning {
  color: #1b2a4e;
  background-color: #fad776;
  border-color: #fad776;
}
.LandingPage .alert-warning hr {
  border-top-color: #f9d05d;
}
.LandingPage .alert-warning .alert-link {
  color: #0e1628;
}
.LandingPage .alert-warning .close,
.LandingPage .alert-warning .alert-link {
  color: #1b2a4e;
}
.LandingPage .alert-danger {
  color: #ffffff;
  background-color: #df4759;
  border-color: #df4759;
}
.LandingPage .alert-danger hr {
  border-top-color: #db3145;
}
.LandingPage .alert-danger .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-danger .close,
.LandingPage .alert-danger .alert-link {
  color: #ffffff;
}
.LandingPage .alert-light {
  color: #1b2a4e;
  background-color: #f9fbfd;
  border-color: #f9fbfd;
}
.LandingPage .alert-light hr {
  border-top-color: #e6eef7;
}
.LandingPage .alert-light .alert-link {
  color: #0e1628;
}
.LandingPage .alert-light .close,
.LandingPage .alert-light .alert-link {
  color: #1b2a4e;
}
.LandingPage .alert-dark {
  color: #ffffff;
  background-color: #1b2a4e;
  border-color: #1b2a4e;
}
.LandingPage .alert-dark hr {
  border-top-color: #14203b;
}
.LandingPage .alert-dark .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-dark .close,
.LandingPage .alert-dark .alert-link {
  color: #ffffff;
}
.LandingPage .alert-primary-desat {
  color: #ffffff;
  background-color: #6c8aec;
  border-color: #6c8aec;
}
.LandingPage .alert-primary-desat hr {
  border-top-color: #5578e9;
}
.LandingPage .alert-primary-desat .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-primary-desat .close,
.LandingPage .alert-primary-desat .alert-link {
  color: #ffffff;
}
.LandingPage .alert-black {
  color: #ffffff;
  background-color: #161c2d;
  border-color: #161c2d;
}
.LandingPage .alert-black hr {
  border-top-color: #0e111c;
}
.LandingPage .alert-black .alert-link {
  color: #e6e6e6;
}
.LandingPage .alert-black .close,
.LandingPage .alert-black .alert-link {
  color: #ffffff;
}
.LandingPage .alert-white {
  color: #1b2a4e;
  background-color: white;
  border-color: white;
}
.LandingPage .alert-white hr {
  border-top-color: #f2f2f2;
}
.LandingPage .alert-white .alert-link {
  color: #0e1628;
}
.LandingPage .alert-white .close,
.LandingPage .alert-white .alert-link {
  color: #1b2a4e;
}
.LandingPage .badge-primary-soft {
  background-color: rgba(51, 94, 234, 0.1);
  color: #335eea;
}
a.LandingPage .badge-primary-soft:hover,
a.LandingPage .badge-primary-soft:focus {
  background-color: rgba(51, 94, 234, 0.2);
  color: #335eea;
}
.LandingPage .badge-secondary-soft {
  background-color: rgba(80, 102, 144, 0.1);
  color: #506690;
}
a.LandingPage .badge-secondary-soft:hover,
a.LandingPage .badge-secondary-soft:focus {
  background-color: rgba(80, 102, 144, 0.2);
  color: #506690;
}
.LandingPage .badge-success-soft {
  background-color: rgba(66, 186, 150, 0.1);
  color: #42ba96;
}
a.LandingPage .badge-success-soft:hover,
a.LandingPage .badge-success-soft:focus {
  background-color: rgba(66, 186, 150, 0.2);
  color: #42ba96;
}
.LandingPage .badge-info-soft {
  background-color: rgba(124, 105, 239, 0.1);
  color: #7c69ef;
}
a.LandingPage .badge-info-soft:hover,
a.LandingPage .badge-info-soft:focus {
  background-color: rgba(124, 105, 239, 0.2);
  color: #7c69ef;
}
.LandingPage .badge-warning-soft {
  background-color: rgba(250, 215, 118, 0.1);
  color: #fad776;
}
a.LandingPage .badge-warning-soft:hover,
a.LandingPage .badge-warning-soft:focus {
  background-color: rgba(250, 215, 118, 0.2);
  color: #fad776;
}
.LandingPage .badge-danger-soft {
  background-color: rgba(223, 71, 89, 0.1);
  color: #df4759;
}
a.LandingPage .badge-danger-soft:hover,
a.LandingPage .badge-danger-soft:focus {
  background-color: rgba(223, 71, 89, 0.2);
  color: #df4759;
}
.LandingPage .badge-light-soft {
  background-color: rgba(249, 251, 253, 0.1);
  color: #f9fbfd;
}
a.LandingPage .badge-light-soft:hover,
a.LandingPage .badge-light-soft:focus {
  background-color: rgba(249, 251, 253, 0.2);
  color: #f9fbfd;
}
.LandingPage .badge-dark-soft {
  background-color: rgba(27, 42, 78, 0.1);
  color: #1b2a4e;
}
a.LandingPage .badge-dark-soft:hover,
a.LandingPage .badge-dark-soft:focus {
  background-color: rgba(27, 42, 78, 0.2);
  color: #1b2a4e;
}
.LandingPage .badge-primary-desat-soft {
  background-color: rgba(108, 138, 236, 0.1);
  color: #6c8aec;
}
a.LandingPage .badge-primary-desat-soft:hover,
a.LandingPage .badge-primary-desat-soft:focus {
  background-color: rgba(108, 138, 236, 0.2);
  color: #6c8aec;
}
.LandingPage .badge-black-soft {
  background-color: rgba(22, 28, 45, 0.1);
  color: #161c2d;
}
a.LandingPage .badge-black-soft:hover,
a.LandingPage .badge-black-soft:focus {
  background-color: rgba(22, 28, 45, 0.2);
  color: #161c2d;
}
.LandingPage .badge-white-soft {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
a.LandingPage .badge-white-soft:hover,
a.LandingPage .badge-white-soft:focus {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.LandingPage .badge-gray-700-soft {
  background-color: rgba(80, 102, 144, 0.1);
  color: #506690;
}
a.LandingPage .badge-gray-700-soft:hover,
a.LandingPage .badge-gray-700-soft:focus {
  background-color: rgba(80, 102, 144, 0.2);
  color: #506690;
}
.LandingPage .badge-gray-600 {
  background-color: #869ab8;
  color: #ffffff;
}
.LandingPage .badge-lg {
  padding: 0.7em 1.15em;
}
.LandingPage .badge-rounded-circle {
  height: calc(1em + 0.5rem);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 10rem;
}
.LandingPage .badge-rounded-circle > * {
  display: block;
  width: 1em;
}
.LandingPage .badge-rounded-circle.badge-lg {
  height: calc(1em + 1.4em);
  padding-left: 0.7em;
  padding-right: 0.7em;
}
.LandingPage .badge-float {
  position: absolute;
  z-index: 1000;
}
.LandingPage .badge-float-outside {
  top: -0.5rem;
  right: -0.5rem;
}
.LandingPage .badge-float-inside {
  top: 1rem;
  right: 1rem;
}
.LandingPage .badge-white-soft.active {
  background-color: #ffffff;
  color: #335eea;
}
.LandingPage .badge-white-soft.active:hover,
.LandingPage .badge-white-soft.active:focus {
  background-color: #f2f2f2;
  color: #335eea;
}
.LandingPage .badge-secondary-soft.active {
  background-color: #335eea;
  color: #ffffff;
}
.LandingPage .badge-secondary-soft.active:hover,
.LandingPage .badge-secondary-soft.active:focus {
  background-color: #1c4ce8;
  color: #ffffff;
}
.LandingPage .breadcrumb-item {
  font-size: 0.9375rem;
}
.LandingPage .breadcrumb-item + .breadcrumb-item::before {
  content: '\e930';
  font-family: 'Feather';
}
.LandingPage .breadcrumb-scroll {
  display: -webkit-box;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
}
.LandingPage .breadcrumb-scroll .breadcrumb-item {
  white-space: nowrap;
}
.LandingPage .breadcrumb-scroll::-webkit-scrollbar {
  display: none;
}
.LandingPage .btn > img {
  max-height: 1em;
  width: auto;
}
.LandingPage .btn:not([class*='btn-outline']) {
  border-color: transparent !important;
}
.LandingPage .btn-xs {
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem;
}
.LandingPage .btn-white {
  background-color: #ffffff;
  color: #335eea;
}
.LandingPage .btn-white:hover,
.LandingPage .btn-white:focus {
  background-color: #ffffff;
  color: #1c4ce8;
}
.LandingPage .btn-white.active {
  background-color: #f2f2f2;
  color: #1c4ce8;
}
.LandingPage .btn-group .btn-white {
  border-color: #d9e2ef !important;
  color: #869ab8;
}
.LandingPage .btn-group .btn-white.active {
  background-color: #f2f2f2;
  color: #768daf;
  box-shadow: none;
}
.LandingPage .btn-gray-300-20 {
  background-color: rgba(217, 226, 239, 0.2);
  color: #ffffff;
}
.LandingPage .btn-gray-300-20:hover,
.LandingPage .btn-gray-300-20:focus {
  background-color: rgba(217, 226, 239, 0.25);
  color: #ffffff;
}
.LandingPage .btn-gray-300-20.active {
  background-color: rgba(217, 226, 239, 0.25);
  color: #ffffff;
}
.LandingPage .btn-gray-400 {
  background-color: #c6d3e6;
  color: #ffffff;
}
.LandingPage .btn-gray-400:hover,
.LandingPage .btn-gray-400:focus {
  background-color: #b4c5de;
  color: #f2f2f2;
}
.LandingPage .btn-gray-400.active {
  background-color: #b4c5de;
  color: #f2f2f2;
}
.LandingPage .btn-gray-400-10 {
  background-color: rgba(198, 211, 230, 0.1);
  color: #ffffff;
}
.LandingPage .btn-gray-400-10:hover,
.LandingPage .btn-gray-400-10:focus {
  background-color: rgba(198, 211, 230, 0.15);
  color: #ffffff;
}
.LandingPage .btn-gray-400-10.active {
  background-color: rgba(198, 211, 230, 0.15);
  color: #ffffff;
}
.LandingPage .btn-outline-white {
  border-color: #d9e2ef;
  color: #161c2d;
}
.LandingPage .btn-outline-white:hover,
.LandingPage .btn-outline-white:focus {
  background-color: #f2f2f2;
  border-color: #c6d3e6;
  color: #161c2d;
}
.LandingPage .btn-outline-white.active {
  background-color: #f2f2f2;
  border-color: #c6d3e6;
  color: #161c2d;
}
.LandingPage .btn-outline-gray-300 {
  border-color: #d9e2ef;
  color: #335eea;
}
.LandingPage .btn-outline-gray-300:hover,
.LandingPage .btn-outline-gray-300:focus {
  background-color: #335eea;
  border-color: #335eea;
  color: #ffffff;
}
.LandingPage .btn-outline-gray-300.active {
  background-color: #335eea;
  border-color: #335eea;
  color: #ffffff;
}
.LandingPage .btn-primary-soft {
  background-color: rgba(51, 94, 234, 0.1);
  color: #335eea;
}
.LandingPage .btn-primary-soft:hover,
.LandingPage .btn-primary-soft:focus {
  background-color: rgba(51, 94, 234, 0.15);
  color: #335eea;
}
.LandingPage .btn-primary-soft.active {
  background-color: rgba(51, 94, 234, 0.15);
  color: #335eea;
}
.LandingPage .btn-secondary-soft {
  background-color: rgba(80, 102, 144, 0.1);
  color: #506690;
}
.LandingPage .btn-secondary-soft:hover,
.LandingPage .btn-secondary-soft:focus {
  background-color: rgba(80, 102, 144, 0.15);
  color: #506690;
}
.LandingPage .btn-secondary-soft.active {
  background-color: rgba(80, 102, 144, 0.15);
  color: #506690;
}
.LandingPage .btn-success-soft {
  background-color: rgba(66, 186, 150, 0.1);
  color: #42ba96;
}
.LandingPage .btn-success-soft:hover,
.LandingPage .btn-success-soft:focus {
  background-color: rgba(66, 186, 150, 0.15);
  color: #42ba96;
}
.LandingPage .btn-success-soft.active {
  background-color: rgba(66, 186, 150, 0.15);
  color: #42ba96;
}
.LandingPage .btn-info-soft {
  background-color: rgba(124, 105, 239, 0.1);
  color: #7c69ef;
}
.LandingPage .btn-info-soft:hover,
.LandingPage .btn-info-soft:focus {
  background-color: rgba(124, 105, 239, 0.15);
  color: #7c69ef;
}
.LandingPage .btn-info-soft.active {
  background-color: rgba(124, 105, 239, 0.15);
  color: #7c69ef;
}
.LandingPage .btn-warning-soft {
  background-color: rgba(250, 215, 118, 0.1);
  color: #fad776;
}
.LandingPage .btn-warning-soft:hover,
.LandingPage .btn-warning-soft:focus {
  background-color: rgba(250, 215, 118, 0.15);
  color: #fad776;
}
.LandingPage .btn-warning-soft.active {
  background-color: rgba(250, 215, 118, 0.15);
  color: #fad776;
}
.LandingPage .btn-danger-soft {
  background-color: rgba(223, 71, 89, 0.1);
  color: #df4759;
}
.LandingPage .btn-danger-soft:hover,
.LandingPage .btn-danger-soft:focus {
  background-color: rgba(223, 71, 89, 0.15);
  color: #df4759;
}
.LandingPage .btn-danger-soft.active {
  background-color: rgba(223, 71, 89, 0.15);
  color: #df4759;
}
.LandingPage .btn-light-soft {
  background-color: rgba(249, 251, 253, 0.1);
  color: #f9fbfd;
}
.LandingPage .btn-light-soft:hover,
.LandingPage .btn-light-soft:focus {
  background-color: rgba(249, 251, 253, 0.15);
  color: #f9fbfd;
}
.LandingPage .btn-light-soft.active {
  background-color: rgba(249, 251, 253, 0.15);
  color: #f9fbfd;
}
.LandingPage .btn-dark-soft {
  background-color: rgba(27, 42, 78, 0.1);
  color: #1b2a4e;
}
.LandingPage .btn-dark-soft:hover,
.LandingPage .btn-dark-soft:focus {
  background-color: rgba(27, 42, 78, 0.15);
  color: #1b2a4e;
}
.LandingPage .btn-dark-soft.active {
  background-color: rgba(27, 42, 78, 0.15);
  color: #1b2a4e;
}
.LandingPage .btn-primary-desat-soft {
  background-color: rgba(108, 138, 236, 0.1);
  color: #6c8aec;
}
.LandingPage .btn-primary-desat-soft:hover,
.LandingPage .btn-primary-desat-soft:focus {
  background-color: rgba(108, 138, 236, 0.15);
  color: #6c8aec;
}
.LandingPage .btn-primary-desat-soft.active {
  background-color: rgba(108, 138, 236, 0.15);
  color: #6c8aec;
}
.LandingPage .btn-black-soft {
  background-color: rgba(22, 28, 45, 0.1);
  color: #161c2d;
}
.LandingPage .btn-black-soft:hover,
.LandingPage .btn-black-soft:focus {
  background-color: rgba(22, 28, 45, 0.15);
  color: #161c2d;
}
.LandingPage .btn-black-soft.active {
  background-color: rgba(22, 28, 45, 0.15);
  color: #161c2d;
}
.LandingPage .btn-white-soft {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.LandingPage .btn-white-soft:hover,
.LandingPage .btn-white-soft:focus {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
}
.LandingPage .btn-white-soft.active {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
}
.LandingPage .btn-pill {
  border-radius: 50rem;
}
.LandingPage .btn-rounded-circle {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  border-radius: 50rem;
}
.LandingPage .btn-rounded-circle > * {
  display: block;
  line-height: 1;
  width: 1em;
}
.LandingPage .btn-rounded-circle.btn-lg,
.LandingPage .btn-group-lg > .btn-rounded-circle.btn {
  padding-left: 1.122rem;
  padding-right: 1.122rem;
}
.LandingPage .btn-rounded-circle.btn-sm,
.LandingPage .btn-group-sm > .btn-rounded-circle.btn {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}
.LandingPage .btn-rounded-circle.btn-xs {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.LandingPage .card {
  position: relative;
  width: 100%;
}
.LandingPage .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f4f8;
}
.LandingPage .card-body {
  display: block;
  -webkit-box-flex: 0;
  flex-grow: 0;
}
.LandingPage .card-footer {
  background-color: unset;
}
.LandingPage .card-body,
.LandingPage .card-footer,
.LandingPage .card-meta,
.LandingPage .card-img,
.LandingPage .card-img-top,
.LandingPage .card-img-left,
.LandingPage .card-img-right {
  position: relative;
  min-height: 1px;
}
.LandingPage a.card,
.LandingPage a.card-body,
.LandingPage a.card-footer,
.LandingPage a.card-meta {
  color: inherit;
}
.LandingPage a.card:hover,
.LandingPage a.card-body:hover,
.LandingPage a.card-footer:hover,
.LandingPage a.card-meta:hover {
  text-decoration: none;
}
.LandingPage .card-flush {
  background-color: unset;
}
.LandingPage .card-flush > *:not(.card-btn) {
  padding-left: 0;
  padding-right: 0;
}
.LandingPage .card-border {
  position: relative;
}
.LandingPage .card-border::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top-width: 2px;
  border-bottom-width: calc(0.375rem - 2px);
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: inherit;
  border-bottom-color: transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.LandingPage .card-border-lg::after {
  border-top-width: 3px;
  border-bottom-width: calc(0.375rem - 3px);
}
.LandingPage .card-border-xl::after {
  border-top-width: 4px;
  border-bottom-width: calc(0.375rem - 3px);
}
@media (min-width: 768px) {
  .LandingPage .card-row .card-body {
    padding: 4rem 2.5rem;
  }
}
@media (min-width: 768px) {
  .LandingPage .card-row .card-meta {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.LandingPage .card-img-right {
  border-radius: 0.375rem 0.375rem 0 0;
}
@media (min-width: 768px) {
  .LandingPage .card-img-right {
    border-radius: 0 0.375rem 0.375rem 0;
  }
}
.LandingPage .card-img-left {
  border-radius: 0.375rem 0.375rem 0 0;
}
@media (min-width: 768px) {
  .LandingPage .card-img-left {
    border-radius: 0.375rem 0 0 0.375rem;
  }
}
@media (min-width: 768px) {
  .LandingPage .card-img-slider {
    height: 100%;
    width: 100%;
  }
  .LandingPage .card-img-slider * {
    height: inherit !important;
    width: inherit !important;
  }
}
.LandingPage .card-img-overlay {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2rem;
}
.LandingPage .card-img-overlay .card-body {
  margin: -2rem;
  margin-top: auto;
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px);
}
.LandingPage .card-img-overlay-hover {
  overflow: hidden;
}
.LandingPage .card-img-overlay-hover .card-body {
  -webkit-transform: translateY(110%);
  transform: translateY(110%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.LandingPage .card-img-overlay-hover:hover .card-body {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .LandingPage .card-group {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .LandingPage .card-group > .card {
    -webkit-box-flex: 1;
    flex: auto;
  }
}
.LandingPage .card-meta {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 2rem 1.5rem;
}
.LandingPage .card-meta-divider {
  width: 100%;
  margin: 0 0 1.5rem;
}
.LandingPage .card-btn:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.LandingPage .card-zoom {
  overflow: hidden !important;
  border-radius: inherit;
}
.LandingPage .card-zoom > [class*='card-img'] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.LandingPage .card:hover > .card-zoom > [class*='card-img'] {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.LandingPage .card-list .list-link {
  position: relative;
}
.LandingPage .card-list .list-link::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2rem;
  border-right: 2px solid #335eea;
  display: none;
}
.LandingPage .card-list .list-link:hover {
  color: #161c2d !important;
}
.LandingPage .card-list .active .list-link {
  color: #161c2d !important;
}
.LandingPage .card-list .active .list-link::before {
  display: block;
}
@media (max-width: 767.98px) {
  .LandingPage .card-bleed {
    width: auto;
    min-width: 100%;
    margin-right: -20px;
    margin-left: -20px;
    border-radius: 0;
  }
  .LandingPage .card-bleed .card-footer,
  .LandingPage .card-bleed .card-header,
  .LandingPage .card-bleed .card-body {
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 0;
  }
  .LandingPage .card-bleed .card-list .list-link::before {
    left: -20px;
  }
}
.LandingPage .close:hover,
.LandingPage .close:focus {
  outline: none;
}
.LandingPage .custom-switch {
  min-height: 1.375rem;
}
.LandingPage .custom-switch .custom-control-label::before {
  top: 0.1625rem;
  height: 1.375rem;
  border-radius: 0.6875rem;
}
.LandingPage .custom-switch .custom-control-label::after {
  top: 0.35rem;
  left: -2.8125rem;
  background-color: #ffffff;
}
.LandingPage .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  -webkit-transform: translateX(1.125rem);
  transform: translateX(1.125rem);
}
.LandingPage .custom-switch-dark .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.LandingPage .custom-switch-dark .custom-control-label::after {
  background-color: #ffffff;
}
.LandingPage .custom-switch-dark .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffffff;
}
.LandingPage .custom-switch-dark .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #335eea;
}
.LandingPage .custom-select-sm {
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1rem center/auto 50%;
}
.LandingPage .custom-select-xs {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 1.875rem 0.25rem 0.625rem;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 0.625rem center/auto 50%;
  line-height: 1.6;
  font-size: 0.9375rem;
}
.LandingPage .dropdown-menu {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.LandingPage .dropdown-item {
  width: auto;
  min-width: 100%;
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}
.LandingPage .dropdown-item:hover,
.LandingPage .dropdown-item:focus {
  outline: none;
}
.LandingPage .dropdown-item + .dropdown-item {
  margin-top: 0.45rem;
}
.LandingPage .dropdown-link {
  color: inherit;
}
.LandingPage .dropdown-link:hover {
  text-decoration: inherit;
}
.LandingPage .dropdown-link:hover,
.LandingPage .dropdown-link:focus {
  outline: none;
}
.LandingPage .dropdown-header {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.55;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}
.LandingPage .dropdown-toggle::after {
  font-family: 'Feather';
  vertical-align: middle;
  border: none !important;
  content: '\e92e';
}
.LandingPage .dropright > .dropdown-toggle::after {
  content: '\e930';
}
.LandingPage .dropdown-menu-xs {
  min-width: 0;
  padding: 1rem 1.25rem;
}
.LandingPage .dropdown-menu-md {
  min-width: 22rem;
}
.LandingPage .dropdown-menu-lg {
  min-width: 35rem;
}
.LandingPage .dropdown-menu-xl {
  min-width: 42rem;
}
.LandingPage .dropdown-menu-lg,
.LandingPage .dropdown-menu-xl {
  padding: 2.375rem 2.5rem;
}
.LandingPage .dropdown-menu-lg .dropdown-item,
.LandingPage .dropdown-menu-xl .dropdown-header {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.LandingPage .dropright > .dropdown-menu {
  top: -1.625rem;
}
.LandingPage .form-control-xs {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem;
}
.LandingPage .form-control-flush {
  padding-left: 0;
  padding-right: 0;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.LandingPage .custom-file-label::after {
  font-weight: 600;
}
.LandingPage .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
.LandingPage .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 1.25rem;
  margin-bottom: 0;
  line-height: 1.6;
  font-size: 1.0625rem;
  color: #869ab8;
  pointer-events: none;
  cursor: text;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.LandingPage .form-label-group > .form-control-flush ~ label {
  padding-left: 0;
  padding-right: 0;
}
.LandingPage .form-label-group > .form-control::-webkit-input-placeholder {
  color: transparent;
}
.LandingPage .form-label-group > .form-control::-moz-placeholder {
  color: transparent;
}
.LandingPage .form-label-group > .form-control:-ms-input-placeholder {
  color: transparent;
}
.LandingPage .form-label-group > .form-control::-ms-input-placeholder {
  color: transparent;
}
.LandingPage .form-label-group > .form-control::placeholder {
  color: transparent;
}
.LandingPage .form-label-group > .form-control:not(:placeholder-shown) {
  padding-top: 1.21875rem;
  padding-bottom: 0.40625rem;
}
.LandingPage .form-label-group > .form-control:not(:placeholder-shown) ~ label {
  padding-top: 0.00937rem;
  padding-bottom: 0.00937rem;
  font-size: 0.75rem;
}
@supports (-ms-ime-align: auto) {
  .LandingPage .form-label-group > label {
    display: none;
  }
  .LandingPage .form-label-group .form-control::-webkit-input-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control::-moz-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control:-ms-input-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control::-ms-input-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control::placeholder {
    color: #869ab8;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .LandingPage .form-label-group > label {
    display: none;
  }
  .LandingPage .form-label-group .form-control::-webkit-input-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control::-moz-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control:-ms-input-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control::-ms-input-placeholder {
    color: #869ab8;
  }
  .LandingPage .form-label-group .form-control::placeholder {
    color: #869ab8;
  }
}
.LandingPage [class^='container'] {
  position: relative;
  z-index: 1;
}
.LandingPage .img-cover {
  width: 100vw;
  height: auto;
  margin-left: calc(50% - 50vw);
}
.LandingPage .img-fluid > img,
.LandingPage .img-fluid > svg {
  max-width: inherit;
  height: inherit;
}
.LandingPage .figure {
  display: block;
}
.LandingPage .figure-img {
  margin-bottom: 1rem;
}
.LandingPage .input-group-xs .form-control {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem;
}
.LandingPage .input-group-xs .input-group-text {
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem;
}
.LandingPage .list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
.LandingPage .list-group-flush .list-group-item:first-child {
  padding-top: 0 !important;
  border-top: 0;
}
.LandingPage .list-group-flush .list-group-item:last-child {
  padding-bottom: 0 !important;
  border-bottom: 0;
}
.LandingPage .modal-open .navbar.fixed-top {
  padding-right: inherit;
}
.LandingPage .modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1000;
}
.LandingPage .navbar {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  z-index: 1030;
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-dark .navbar-nav .nav-item .nav-link {
    color: #506690;
  }
  .LandingPage .navbar-dark .navbar-nav .nav-item .nav-link:hover,
  .LandingPage .navbar-dark .navbar-nav .nav-item .nav-link:focus {
    color: #335eea;
  }
  .LandingPage .navbar-dark .navbar-nav .nav-item.active .nav-link,
  .LandingPage .navbar-dark .navbar-nav .nav-item .nav-link.active {
    color: #335eea;
  }
  .LandingPage .navbar-dark .navbar-collapse .navbar-toggler {
    color: #506690;
  }
}
.LandingPage .navbar > .container,
.LandingPage .navbar > .container-fluid,
.LandingPage .navbar > .container-sm,
.LandingPage .navbar > .container-md,
.LandingPage .navbar > .container-lg,
.LandingPage .navbar > .container-xl {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.LandingPage .navbar-brand {
  font-weight: 600;
}
.LandingPage .navbar-brand-img {
  max-height: 2.4rem;
  width: auto;
}
.LandingPage .navbar-dark .navbar-brand {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-btn {
    width: 100%;
    padding: 0.8125rem 1.25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 1.0625rem;
  }
}
.LandingPage .navbar-nav .nav-link {
  font-weight: 600;
}
.LandingPage .navbar-nav .nav-link:hover,
.LandingPage .navbar-nav .nav-link:focus {
  outline: none;
}
.LandingPage .navbar-dark.fixed-top > .container {
  position: relative;
}
.LandingPage .navbar-dark.fixed-top > .container::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -1.25rem;
  left: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse {
    position: fixed;
    top: 1rem;
    left: 1rem;
    height: auto;
    max-height: calc(100% - 2rem) !important;
    width: calc(100% - 2rem);
    background-color: #ffffff;
    border-radius: 0.375rem;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .LandingPage .navbar-collapse.show,
  .LandingPage .navbar-collapse.collapsing {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform, -webkit-transform;
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .LandingPage .navbar-collapse.show {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .LandingPage .navbar-collapse.collapsing {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.LandingPage .navbar .navbar-toggler {
  margin-left: auto;
}
.LandingPage .navbar-collapse .navbar-toggler {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}
@media (min-width: 992px) {
  .LandingPage .navbar-collapse .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .nav-item {
    padding: 1.5rem;
  }
  .LandingPage .navbar-collapse .navbar-nav .nav-item + .nav-item {
    border-top: 1px solid #f1f4f8;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.LandingPage .navbar-nav .dropdown-menu {
  box-shadow: none;
}
@media (min-width: 992px) {
  .LandingPage .navbar-nav .dropdown-menu {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
  }
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .dropdown-menu {
    min-width: 0;
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .dropdown > .dropdown-menu {
    display: block !important;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .dropright > .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .dropdown-item,
  .LandingPage .navbar-collapse .navbar-nav .dropdown-header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.LandingPage .navbar-nav .dropdown > .dropdown-toggle::after {
  display: none;
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .dropdown > .dropdown-toggle {
    margin-bottom: 1.5rem;
    pointer-events: none;
  }
}
.LandingPage .navbar-nav .dropright > .dropdown-toggle {
  display: -webkit-box;
  display: flex;
}
.LandingPage .navbar-nav .dropright > .dropdown-toggle::after {
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .LandingPage .navbar-collapse .navbar-nav .dropright > .dropdown-toggle::after {
    content: '\e92e';
  }
  .LandingPage .navbar-collapse .navbar-nav .dropright > .dropdown-toggle[aria-expanded='true']::after {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.LandingPage .navbar-nav .dropdown-img-left {
  height: 100%;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 1.625rem 1.75rem;
  background: no-repeat center center / cover;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
@media (min-width: 992px) {
  .LandingPage .navbar-nav .dropdown-img-left {
    display: -webkit-box;
    display: flex;
  }
}
.LandingPage .navbar-nav .dropdown-img-left::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 94, 234, 0.8);
  border-radius: inherit;
}
.LandingPage .navbar-nav .dropdown-img-left * {
  position: relative;
}
@media (min-width: 992px) {
  .LandingPage .navbar-nav .dropdown-menu-lg .dropdown-img-left,
  .LandingPage .navbar-nav .dropdown-menu-xl .dropdown-img-left {
    padding: 2.375rem 2.5rem;
  }
}
@media (min-width: 992px) {
  .LandingPage .navbar-nav .dropdown-body {
    padding: 1.625rem 1.75rem;
  }
}
@media (min-width: 992px) {
  .LandingPage .navbar-nav .dropdown-menu-lg .dropdown-body,
  .LandingPage .navbar-nav .dropdown-menu-xl .dropdown-body {
    padding: 2.375rem 2.5rem;
  }
}
.LandingPage .navbar-nav .dropdown-menu .list-group-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: inherit;
}
.LandingPage .navbar-nav .dropdown-menu .list-group-item:hover {
  text-decoration: none;
}
@media (min-width: 992px) {
  .LandingPage .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform, -webkit-transform;
  }
  .LandingPage .navbar-nav .dropright > .dropdown-menu {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  .LandingPage .navbar-nav .dropdown > .dropdown-menu {
    left: 50%;
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
  }
  .LandingPage .navbar-nav .dropdown-menu.showing {
    display: block;
  }
  .LandingPage .navbar-nav .dropdown-menu.show {
    display: block;
    opacity: 1;
  }
  .LandingPage .navbar-nav .dropright > .dropdown-menu.show {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .LandingPage .navbar-nav .dropdown > .dropdown-menu.show {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
.LandingPage .pagination-sm .page-link {
  line-height: 1.6;
  font-size: 1.0625rem;
}
.LandingPage .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.LandingPage .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.LandingPage .pagination-lg .page-link {
  line-height: 1.6;
  font-size: 1.0625rem;
}
.LandingPage .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.LandingPage .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.LandingPage .popover-header {
  padding-bottom: 0;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #335eea;
}
.LandingPage .popover-header:not(:empty) ~ .popover-body {
  padding-top: 0;
}
.LandingPage html,
.LandingPage body {
  overflow-x: hidden;
}
.LandingPage .table thead > tr > th {
  border-top-width: 0;
  border-bottom-width: 1px;
}
.LandingPage .table-align-middle thead > tr > th,
.LandingPage .table-align-middle tbody > tr > td {
  vertical-align: middle;
}

@font-face {
  .LandingPage {
    font-family: 'HKGroteskPro';
    font-weight: 400;
    src: url('../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff2') format('woff2'), url('../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff') format('woff');
  }
}

@font-face {
  .LandingPage {
    font-family: 'HKGroteskPro';
    font-weight: 600;
    src: url('../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff2') format('woff2'), url('../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff') format('woff');
  }
}
.LandingPage h1,
.LandingPage h2,
.LandingPage .h1,
.LandingPage .h2 {
  margin-bottom: 1rem;
}
.LandingPage h1,
.LandingPage h2,
.LandingPage h3,
.LandingPage h4,
.LandingPage .h1,
.LandingPage .h2,
.LandingPage .h3,
.LandingPage .h4 {
  letter-spacing: -0.01em;
}
.LandingPage h1,
.LandingPage .h1 {
  line-height: 1.3;
}
@media (max-width: 991.98px) {
  .LandingPage h1,
  .LandingPage .h1 {
    font-size: 1.875rem;
  }
}
.LandingPage h2,
.LandingPage .h2 {
  line-height: 1.35;
}
@media (max-width: 991.98px) {
  .LandingPage h2,
  .LandingPage .h2 {
    font-size: 1.5rem;
  }
}
.LandingPage h3,
.LandingPage .h3 {
  line-height: 1.4;
}
@media (max-width: 991.98px) {
  .LandingPage h3,
  .LandingPage .h3 {
    font-size: 1.125rem;
  }
}
.LandingPage h4,
.LandingPage .h4 {
  line-height: 1.45;
}
@media (max-width: 991.98px) {
  .LandingPage h4,
  .LandingPage .h4 {
    font-size: 1.0625rem;
  }
}
.LandingPage h5,
.LandingPage .h5 {
  line-height: 1.5;
}
.LandingPage h6,
.LandingPage .h6 {
  line-height: 1.55;
}
.LandingPage h6.text-uppercase,
.LandingPage .h6.text-uppercase {
  letter-spacing: 0.08em;
}
.LandingPage .display-1,
.LandingPage .display-2,
.LandingPage .display-3,
.LandingPage .display-4 {
  letter-spacing: -0.02em;
}
.LandingPage .display-1 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .LandingPage .display-1 {
    font-size: 3.125rem;
  }
}
.LandingPage .display-2 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .LandingPage .display-2 {
    font-size: 2.5rem;
  }
}
.LandingPage .display-3 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .LandingPage .display-3 {
    font-size: 2.25rem;
  }
}
.LandingPage .display-4 {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .LandingPage .display-4 {
    font-size: 2rem;
  }
}
@media (max-width: 991.98px) {
  .LandingPage .lead {
    font-size: 1.1875rem;
  }
}
.LandingPage .blockquote-img {
  max-height: 2.5rem;
  max-width: 100%;
  width: auto;
}
.LandingPage .blockquote-footer::before {
  display: none;
}
.LandingPage .list-social-icon {
  max-width: 1.25rem;
  height: auto;
}
.LandingPage .hr-sm {
  width: 60px;
  border-top-width: 2px;
}
.LandingPage .hr-md {
  width: 100px;
  border-top-width: 2px;
}
.LandingPage code {
  line-height: 1.35;
}
.LandingPage .bg-cover {
  background: no-repeat center center / cover;
}
@media (max-width: 991.98px) {
  .LandingPage .bg-between {
    background-image: none !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .bg-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 70%;
  }
}
.LandingPage .bg-gradient-light {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f9fbfd), to(#ffffff));
  background-image: linear-gradient(to bottom, #f9fbfd 0%, #ffffff 100%);
}
.LandingPage .bg-gray-200 {
  background-color: #f1f4f8 !important;
}
.LandingPage .bg-gray-300 {
  background-color: #d9e2ef !important;
}
.LandingPage .bg-gray-800 {
  background-color: #384c74 !important;
}
.LandingPage .bg-gray-900 {
  background-color: #1b2a4e !important;
}
.LandingPage .bg-black {
  background-color: #161c2d !important;
}
@media (min-width: 576px) {
  .LandingPage .border-sm {
    border: 1px solid #f1f4f8 !important;
    color: rose;
  }
  .LandingPage .border-top-sm {
    border-top: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-right-sm {
    border-right: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-bottom-sm {
    border-bottom: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-left-sm {
    border-left: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-sm-0 {
    border: 0 !important;
  }
  .LandingPage .border-top-sm-0 {
    border-top: 0 !important;
  }
  .LandingPage .border-right-sm-0 {
    border-right: 0 !important;
  }
  .LandingPage .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .LandingPage .border-left-sm-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .border-md {
    border: 1px solid #f1f4f8 !important;
    color: rose;
  }
  .LandingPage .border-top-md {
    border-top: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-right-md {
    border-right: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-bottom-md {
    border-bottom: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-left-md {
    border-left: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-md-0 {
    border: 0 !important;
  }
  .LandingPage .border-top-md-0 {
    border-top: 0 !important;
  }
  .LandingPage .border-right-md-0 {
    border-right: 0 !important;
  }
  .LandingPage .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .LandingPage .border-left-md-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .LandingPage .border-lg {
    border: 1px solid #f1f4f8 !important;
    color: rose;
  }
  .LandingPage .border-top-lg {
    border-top: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-right-lg {
    border-right: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-bottom-lg {
    border-bottom: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-left-lg {
    border-left: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-lg-0 {
    border: 0 !important;
  }
  .LandingPage .border-top-lg-0 {
    border-top: 0 !important;
  }
  .LandingPage .border-right-lg-0 {
    border-right: 0 !important;
  }
  .LandingPage .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .LandingPage .border-left-lg-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .LandingPage .border-xl {
    border: 1px solid #f1f4f8 !important;
    color: rose;
  }
  .LandingPage .border-top-xl {
    border-top: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-right-xl {
    border-right: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-bottom-xl {
    border-bottom: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-left-xl {
    border-left: 1px solid #f1f4f8 !important;
  }
  .LandingPage .border-xl-0 {
    border: 0 !important;
  }
  .LandingPage .border-top-xl-0 {
    border-top: 0 !important;
  }
  .LandingPage .border-right-xl-0 {
    border-right: 0 !important;
  }
  .LandingPage .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .LandingPage .border-left-xl-0 {
    border-left: 0 !important;
  }
}
.LandingPage .border-white-10 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.LandingPage .border-white-20 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
.LandingPage .border-gray-300 {
  border-color: #d9e2ef !important;
}
.LandingPage .border-gray-800 {
  border-color: #384c74 !important;
}
.LandingPage .border-gray-800-50 {
  border-color: rgba(56, 76, 116, 0.5) !important;
}
.LandingPage .border-gray-900-50 {
  border-color: rgba(27, 42, 78, 0.5) !important;
}
.LandingPage .img-skewed {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.LandingPage .img-skewed-left {
  -webkit-perspective-origin: left center;
  perspective-origin: left center;
}
.LandingPage .img-skewed-left .img-skewed-item {
  -webkit-transform: rotateY(-35deg) rotateX(15deg);
  transform: rotateY(-35deg) rotateX(15deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.LandingPage .img-skewed-right {
  -webkit-perspective-origin: right center;
  perspective-origin: right center;
}
.LandingPage .img-skewed-right .img-skewed-item {
  -webkit-transform: rotateY(35deg) rotateX(15deg);
  transform: rotateY(35deg) rotateX(15deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.LandingPage .overlay {
  position: relative;
}
.LandingPage .overlay::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.LandingPage .overlay-primary::before {
  background-color: #335eea;
}
.LandingPage .overlay-gradient-primary-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#335eea));
  background-image: linear-gradient(to bottom, transparent, #335eea);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-primary-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #335eea), to(#335eea));
    background-image: linear-gradient(to right, transparent, #335eea 50%, #335eea);
  }
}
.LandingPage .overlay-gradient-primary-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#335eea));
  background-image: linear-gradient(to bottom, transparent, #335eea);
}
.LandingPage .overlay-gradient-primary-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#335eea));
  background-image: linear-gradient(to bottom, transparent, #335eea);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-primary-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #335eea), to(#335eea));
    background-image: linear-gradient(to left, transparent, #335eea 50%, #335eea);
  }
}
.LandingPage .overlay-secondary::before {
  background-color: #506690;
}
.LandingPage .overlay-gradient-secondary-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#506690));
  background-image: linear-gradient(to bottom, transparent, #506690);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-secondary-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #506690), to(#506690));
    background-image: linear-gradient(to right, transparent, #506690 50%, #506690);
  }
}
.LandingPage .overlay-gradient-secondary-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#506690));
  background-image: linear-gradient(to bottom, transparent, #506690);
}
.LandingPage .overlay-gradient-secondary-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#506690));
  background-image: linear-gradient(to bottom, transparent, #506690);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-secondary-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #506690), to(#506690));
    background-image: linear-gradient(to left, transparent, #506690 50%, #506690);
  }
}
.LandingPage .overlay-success::before {
  background-color: #42ba96;
}
.LandingPage .overlay-gradient-success-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#42ba96));
  background-image: linear-gradient(to bottom, transparent, #42ba96);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-success-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #42ba96), to(#42ba96));
    background-image: linear-gradient(to right, transparent, #42ba96 50%, #42ba96);
  }
}
.LandingPage .overlay-gradient-success-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#42ba96));
  background-image: linear-gradient(to bottom, transparent, #42ba96);
}
.LandingPage .overlay-gradient-success-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#42ba96));
  background-image: linear-gradient(to bottom, transparent, #42ba96);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-success-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #42ba96), to(#42ba96));
    background-image: linear-gradient(to left, transparent, #42ba96 50%, #42ba96);
  }
}
.LandingPage .overlay-info::before {
  background-color: #7c69ef;
}
.LandingPage .overlay-gradient-info-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#7c69ef));
  background-image: linear-gradient(to bottom, transparent, #7c69ef);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-info-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #7c69ef), to(#7c69ef));
    background-image: linear-gradient(to right, transparent, #7c69ef 50%, #7c69ef);
  }
}
.LandingPage .overlay-gradient-info-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#7c69ef));
  background-image: linear-gradient(to bottom, transparent, #7c69ef);
}
.LandingPage .overlay-gradient-info-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#7c69ef));
  background-image: linear-gradient(to bottom, transparent, #7c69ef);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-info-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #7c69ef), to(#7c69ef));
    background-image: linear-gradient(to left, transparent, #7c69ef 50%, #7c69ef);
  }
}
.LandingPage .overlay-warning::before {
  background-color: #fad776;
}
.LandingPage .overlay-gradient-warning-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fad776));
  background-image: linear-gradient(to bottom, transparent, #fad776);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-warning-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #fad776), to(#fad776));
    background-image: linear-gradient(to right, transparent, #fad776 50%, #fad776);
  }
}
.LandingPage .overlay-gradient-warning-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fad776));
  background-image: linear-gradient(to bottom, transparent, #fad776);
}
.LandingPage .overlay-gradient-warning-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fad776));
  background-image: linear-gradient(to bottom, transparent, #fad776);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-warning-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #fad776), to(#fad776));
    background-image: linear-gradient(to left, transparent, #fad776 50%, #fad776);
  }
}
.LandingPage .overlay-danger::before {
  background-color: #df4759;
}
.LandingPage .overlay-gradient-danger-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#df4759));
  background-image: linear-gradient(to bottom, transparent, #df4759);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-danger-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #df4759), to(#df4759));
    background-image: linear-gradient(to right, transparent, #df4759 50%, #df4759);
  }
}
.LandingPage .overlay-gradient-danger-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#df4759));
  background-image: linear-gradient(to bottom, transparent, #df4759);
}
.LandingPage .overlay-gradient-danger-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#df4759));
  background-image: linear-gradient(to bottom, transparent, #df4759);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-danger-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #df4759), to(#df4759));
    background-image: linear-gradient(to left, transparent, #df4759 50%, #df4759);
  }
}
.LandingPage .overlay-light::before {
  background-color: #f9fbfd;
}
.LandingPage .overlay-gradient-light-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfd));
  background-image: linear-gradient(to bottom, transparent, #f9fbfd);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-light-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #f9fbfd), to(#f9fbfd));
    background-image: linear-gradient(to right, transparent, #f9fbfd 50%, #f9fbfd);
  }
}
.LandingPage .overlay-gradient-light-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfd));
  background-image: linear-gradient(to bottom, transparent, #f9fbfd);
}
.LandingPage .overlay-gradient-light-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfd));
  background-image: linear-gradient(to bottom, transparent, #f9fbfd);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-light-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #f9fbfd), to(#f9fbfd));
    background-image: linear-gradient(to left, transparent, #f9fbfd 50%, #f9fbfd);
  }
}
.LandingPage .overlay-dark::before {
  background-color: #1b2a4e;
}
.LandingPage .overlay-gradient-dark-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#1b2a4e));
  background-image: linear-gradient(to bottom, transparent, #1b2a4e);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-dark-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #1b2a4e), to(#1b2a4e));
    background-image: linear-gradient(to right, transparent, #1b2a4e 50%, #1b2a4e);
  }
}
.LandingPage .overlay-gradient-dark-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#1b2a4e));
  background-image: linear-gradient(to bottom, transparent, #1b2a4e);
}
.LandingPage .overlay-gradient-dark-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#1b2a4e));
  background-image: linear-gradient(to bottom, transparent, #1b2a4e);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-dark-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #1b2a4e), to(#1b2a4e));
    background-image: linear-gradient(to left, transparent, #1b2a4e 50%, #1b2a4e);
  }
}
.LandingPage .overlay-primary-desat::before {
  background-color: #6c8aec;
}
.LandingPage .overlay-gradient-primary-desat-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
  background-image: linear-gradient(to bottom, transparent, #6c8aec);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-primary-desat-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #6c8aec), to(#6c8aec));
    background-image: linear-gradient(to right, transparent, #6c8aec 50%, #6c8aec);
  }
}
.LandingPage .overlay-gradient-primary-desat-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
  background-image: linear-gradient(to bottom, transparent, #6c8aec);
}
.LandingPage .overlay-gradient-primary-desat-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
  background-image: linear-gradient(to bottom, transparent, #6c8aec);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-primary-desat-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #6c8aec), to(#6c8aec));
    background-image: linear-gradient(to left, transparent, #6c8aec 50%, #6c8aec);
  }
}
.LandingPage .overlay-black::before {
  background-color: #161c2d;
}
.LandingPage .overlay-gradient-black-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
  background-image: linear-gradient(to bottom, transparent, #161c2d);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-black-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #161c2d), to(#161c2d));
    background-image: linear-gradient(to right, transparent, #161c2d 50%, #161c2d);
  }
}
.LandingPage .overlay-gradient-black-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
  background-image: linear-gradient(to bottom, transparent, #161c2d);
}
.LandingPage .overlay-gradient-black-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
  background-image: linear-gradient(to bottom, transparent, #161c2d);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-black-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #161c2d), to(#161c2d));
    background-image: linear-gradient(to left, transparent, #161c2d 50%, #161c2d);
  }
}
.LandingPage .overlay-white::before {
  background-color: #ffffff;
}
.LandingPage .overlay-gradient-white-right::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffffff));
  background-image: linear-gradient(to bottom, transparent, #ffffff);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-white-right::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #ffffff), to(#ffffff));
    background-image: linear-gradient(to right, transparent, #ffffff 50%, #ffffff);
  }
}
.LandingPage .overlay-gradient-white-down::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffffff));
  background-image: linear-gradient(to bottom, transparent, #ffffff);
}
.LandingPage .overlay-gradient-white-left::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffffff));
  background-image: linear-gradient(to bottom, transparent, #ffffff);
}
@media (min-width: 768px) {
  .LandingPage .overlay-gradient-white-left::before {
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #ffffff), to(#ffffff));
    background-image: linear-gradient(to left, transparent, #ffffff 50%, #ffffff);
  }
}
.LandingPage .overlay-10::before {
  opacity: 0.1;
}
.LandingPage .overlay-20::before {
  opacity: 0.2;
}
.LandingPage .overlay-30::before {
  opacity: 0.3;
}
.LandingPage .overlay-40::before {
  opacity: 0.4;
}
.LandingPage .overlay-50::before {
  opacity: 0.5;
}
.LandingPage .overlay-60::before {
  opacity: 0.6;
}
.LandingPage .overlay-70::before {
  opacity: 0.7;
}
.LandingPage .overlay-80::before {
  opacity: 0.8;
}
.LandingPage .overlay-90::before {
  opacity: 0.9;
}
.LandingPage .lift {
  -webkit-transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease, -webkit-transform 0.25s ease;
}
.LandingPage .lift:hover,
.LandingPage .lift:focus {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
  -webkit-transform: translate3d(0, -3px, 0);
  transform: translate3d(0, -3px, 0);
}
.LandingPage .lift-lg:hover,
.LandingPage .lift-lg:focus {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
}
.LandingPage .top-0 {
  top: 0 !important;
}
.LandingPage .right-0 {
  right: 0 !important;
}
.LandingPage .bottom-0 {
  bottom: 0 !important;
}
.LandingPage .left-0 {
  left: 0 !important;
}
.LandingPage .center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (min-width: 576px) {
  .LandingPage .position-sm-static {
    position: static !important;
  }
  .LandingPage .position-sm-relative {
    position: relative !important;
  }
  .LandingPage .position-sm-absolute {
    position: absolute !important;
  }
  .LandingPage .position-sm-fixed {
    position: fixed !important;
  }
  .LandingPage .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
@media (min-width: 768px) {
  .LandingPage .position-md-static {
    position: static !important;
  }
  .LandingPage .position-md-relative {
    position: relative !important;
  }
  .LandingPage .position-md-absolute {
    position: absolute !important;
  }
  .LandingPage .position-md-fixed {
    position: fixed !important;
  }
  .LandingPage .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
@media (min-width: 992px) {
  .LandingPage .position-lg-static {
    position: static !important;
  }
  .LandingPage .position-lg-relative {
    position: relative !important;
  }
  .LandingPage .position-lg-absolute {
    position: absolute !important;
  }
  .LandingPage .position-lg-fixed {
    position: fixed !important;
  }
  .LandingPage .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
@media (min-width: 1200px) {
  .LandingPage .position-xl-static {
    position: static !important;
  }
  .LandingPage .position-xl-relative {
    position: relative !important;
  }
  .LandingPage .position-xl-absolute {
    position: absolute !important;
  }
  .LandingPage .position-xl-fixed {
    position: fixed !important;
  }
  .LandingPage .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
.LandingPage .shadow-light {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05) !important;
}
.LandingPage .shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important;
}
.LandingPage .shadow-dark {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.15) !important;
}
.LandingPage .shadow-dark-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15) !important;
}
.LandingPage .shadow-lift {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
}
.LandingPage .mw-25 {
  max-width: 25% !important;
}
.LandingPage .vw-25 {
  width: 25vw !important;
}
.LandingPage .mw-50 {
  max-width: 50% !important;
}
.LandingPage .vw-50 {
  width: 50vw !important;
}
.LandingPage .mw-75 {
  max-width: 75% !important;
}
.LandingPage .vw-75 {
  width: 75vw !important;
}
.LandingPage .mw-100 {
  max-width: 100% !important;
}
.LandingPage .vw-100 {
  width: 100vw !important;
}
.LandingPage .mw-auto {
  max-width: auto !important;
}
.LandingPage .vw-auto {
  width: autovw !important;
}
.LandingPage .mw-110 {
  max-width: 110% !important;
}
.LandingPage .vw-110 {
  width: 110vw !important;
}
.LandingPage .mw-120 {
  max-width: 120% !important;
}
.LandingPage .vw-120 {
  width: 120vw !important;
}
.LandingPage .mw-130 {
  max-width: 130% !important;
}
.LandingPage .vw-130 {
  width: 130vw !important;
}
.LandingPage .mw-140 {
  max-width: 140% !important;
}
.LandingPage .vw-140 {
  width: 140vw !important;
}
.LandingPage .mw-150 {
  max-width: 150% !important;
}
.LandingPage .vw-150 {
  width: 150vw !important;
}
@media (min-width: 576px) {
  .LandingPage .h-sm-25 {
    height: 25% !important;
  }
  .LandingPage .w-sm-25 {
    width: 25% !important;
  }
  .LandingPage .mw-sm-25 {
    max-width: 25% !important;
  }
  .LandingPage .vh-sm-25 {
    height: 25vh;
  }
  .LandingPage .vw-sm-25 {
    width: 25vw;
  }
  .LandingPage .h-sm-50 {
    height: 50% !important;
  }
  .LandingPage .w-sm-50 {
    width: 50% !important;
  }
  .LandingPage .mw-sm-50 {
    max-width: 50% !important;
  }
  .LandingPage .vh-sm-50 {
    height: 50vh;
  }
  .LandingPage .vw-sm-50 {
    width: 50vw;
  }
  .LandingPage .h-sm-75 {
    height: 75% !important;
  }
  .LandingPage .w-sm-75 {
    width: 75% !important;
  }
  .LandingPage .mw-sm-75 {
    max-width: 75% !important;
  }
  .LandingPage .vh-sm-75 {
    height: 75vh;
  }
  .LandingPage .vw-sm-75 {
    width: 75vw;
  }
  .LandingPage .h-sm-100 {
    height: 100% !important;
  }
  .LandingPage .w-sm-100 {
    width: 100% !important;
  }
  .LandingPage .mw-sm-100 {
    max-width: 100% !important;
  }
  .LandingPage .vh-sm-100 {
    height: 100vh;
  }
  .LandingPage .vw-sm-100 {
    width: 100vw;
  }
  .LandingPage .h-sm-auto {
    height: auto !important;
  }
  .LandingPage .w-sm-auto {
    width: auto !important;
  }
  .LandingPage .mw-sm-auto {
    max-width: auto !important;
  }
  .LandingPage .vh-sm-auto {
    height: autovh;
  }
  .LandingPage .vw-sm-auto {
    width: autovw;
  }
  .LandingPage .h-sm-110 {
    height: 110% !important;
  }
  .LandingPage .w-sm-110 {
    width: 110% !important;
  }
  .LandingPage .mw-sm-110 {
    max-width: 110% !important;
  }
  .LandingPage .vh-sm-110 {
    height: 110vh;
  }
  .LandingPage .vw-sm-110 {
    width: 110vw;
  }
  .LandingPage .h-sm-120 {
    height: 120% !important;
  }
  .LandingPage .w-sm-120 {
    width: 120% !important;
  }
  .LandingPage .mw-sm-120 {
    max-width: 120% !important;
  }
  .LandingPage .vh-sm-120 {
    height: 120vh;
  }
  .LandingPage .vw-sm-120 {
    width: 120vw;
  }
  .LandingPage .h-sm-130 {
    height: 130% !important;
  }
  .LandingPage .w-sm-130 {
    width: 130% !important;
  }
  .LandingPage .mw-sm-130 {
    max-width: 130% !important;
  }
  .LandingPage .vh-sm-130 {
    height: 130vh;
  }
  .LandingPage .vw-sm-130 {
    width: 130vw;
  }
  .LandingPage .h-sm-140 {
    height: 140% !important;
  }
  .LandingPage .w-sm-140 {
    width: 140% !important;
  }
  .LandingPage .mw-sm-140 {
    max-width: 140% !important;
  }
  .LandingPage .vh-sm-140 {
    height: 140vh;
  }
  .LandingPage .vw-sm-140 {
    width: 140vw;
  }
  .LandingPage .h-sm-150 {
    height: 150% !important;
  }
  .LandingPage .w-sm-150 {
    width: 150% !important;
  }
  .LandingPage .mw-sm-150 {
    max-width: 150% !important;
  }
  .LandingPage .vh-sm-150 {
    height: 150vh;
  }
  .LandingPage .vw-sm-150 {
    width: 150vw;
  }
}
@media (min-width: 768px) {
  .LandingPage .h-md-25 {
    height: 25% !important;
  }
  .LandingPage .w-md-25 {
    width: 25% !important;
  }
  .LandingPage .mw-md-25 {
    max-width: 25% !important;
  }
  .LandingPage .vh-md-25 {
    height: 25vh;
  }
  .LandingPage .vw-md-25 {
    width: 25vw;
  }
  .LandingPage .h-md-50 {
    height: 50% !important;
  }
  .LandingPage .w-md-50 {
    width: 50% !important;
  }
  .LandingPage .mw-md-50 {
    max-width: 50% !important;
  }
  .LandingPage .vh-md-50 {
    height: 50vh;
  }
  .LandingPage .vw-md-50 {
    width: 50vw;
  }
  .LandingPage .h-md-75 {
    height: 75% !important;
  }
  .LandingPage .w-md-75 {
    width: 75% !important;
  }
  .LandingPage .mw-md-75 {
    max-width: 75% !important;
  }
  .LandingPage .vh-md-75 {
    height: 75vh;
  }
  .LandingPage .vw-md-75 {
    width: 75vw;
  }
  .LandingPage .h-md-100 {
    height: 100% !important;
  }
  .LandingPage .w-md-100 {
    width: 100% !important;
  }
  .LandingPage .mw-md-100 {
    max-width: 100% !important;
  }
  .LandingPage .vh-md-100 {
    height: 100vh;
  }
  .LandingPage .vw-md-100 {
    width: 100vw;
  }
  .LandingPage .h-md-auto {
    height: auto !important;
  }
  .LandingPage .w-md-auto {
    width: auto !important;
  }
  .LandingPage .mw-md-auto {
    max-width: auto !important;
  }
  .LandingPage .vh-md-auto {
    height: autovh;
  }
  .LandingPage .vw-md-auto {
    width: autovw;
  }
  .LandingPage .h-md-110 {
    height: 110% !important;
  }
  .LandingPage .w-md-110 {
    width: 110% !important;
  }
  .LandingPage .mw-md-110 {
    max-width: 110% !important;
  }
  .LandingPage .vh-md-110 {
    height: 110vh;
  }
  .LandingPage .vw-md-110 {
    width: 110vw;
  }
  .LandingPage .h-md-120 {
    height: 120% !important;
  }
  .LandingPage .w-md-120 {
    width: 120% !important;
  }
  .LandingPage .mw-md-120 {
    max-width: 120% !important;
  }
  .LandingPage .vh-md-120 {
    height: 120vh;
  }
  .LandingPage .vw-md-120 {
    width: 120vw;
  }
  .LandingPage .h-md-130 {
    height: 130% !important;
  }
  .LandingPage .w-md-130 {
    width: 130% !important;
  }
  .LandingPage .mw-md-130 {
    max-width: 130% !important;
  }
  .LandingPage .vh-md-130 {
    height: 130vh;
  }
  .LandingPage .vw-md-130 {
    width: 130vw;
  }
  .LandingPage .h-md-140 {
    height: 140% !important;
  }
  .LandingPage .w-md-140 {
    width: 140% !important;
  }
  .LandingPage .mw-md-140 {
    max-width: 140% !important;
  }
  .LandingPage .vh-md-140 {
    height: 140vh;
  }
  .LandingPage .vw-md-140 {
    width: 140vw;
  }
  .LandingPage .h-md-150 {
    height: 150% !important;
  }
  .LandingPage .w-md-150 {
    width: 150% !important;
  }
  .LandingPage .mw-md-150 {
    max-width: 150% !important;
  }
  .LandingPage .vh-md-150 {
    height: 150vh;
  }
  .LandingPage .vw-md-150 {
    width: 150vw;
  }
}
@media (min-width: 992px) {
  .LandingPage .h-lg-25 {
    height: 25% !important;
  }
  .LandingPage .w-lg-25 {
    width: 25% !important;
  }
  .LandingPage .mw-lg-25 {
    max-width: 25% !important;
  }
  .LandingPage .vh-lg-25 {
    height: 25vh;
  }
  .LandingPage .vw-lg-25 {
    width: 25vw;
  }
  .LandingPage .h-lg-50 {
    height: 50% !important;
  }
  .LandingPage .w-lg-50 {
    width: 50% !important;
  }
  .LandingPage .mw-lg-50 {
    max-width: 50% !important;
  }
  .LandingPage .vh-lg-50 {
    height: 50vh;
  }
  .LandingPage .vw-lg-50 {
    width: 50vw;
  }
  .LandingPage .h-lg-75 {
    height: 75% !important;
  }
  .LandingPage .w-lg-75 {
    width: 75% !important;
  }
  .LandingPage .mw-lg-75 {
    max-width: 75% !important;
  }
  .LandingPage .vh-lg-75 {
    height: 75vh;
  }
  .LandingPage .vw-lg-75 {
    width: 75vw;
  }
  .LandingPage .h-lg-100 {
    height: 100% !important;
  }
  .LandingPage .w-lg-100 {
    width: 100% !important;
  }
  .LandingPage .mw-lg-100 {
    max-width: 100% !important;
  }
  .LandingPage .vh-lg-100 {
    height: 100vh;
  }
  .LandingPage .vw-lg-100 {
    width: 100vw;
  }
  .LandingPage .h-lg-auto {
    height: auto !important;
  }
  .LandingPage .w-lg-auto {
    width: auto !important;
  }
  .LandingPage .mw-lg-auto {
    max-width: auto !important;
  }
  .LandingPage .vh-lg-auto {
    height: autovh;
  }
  .LandingPage .vw-lg-auto {
    width: autovw;
  }
  .LandingPage .h-lg-110 {
    height: 110% !important;
  }
  .LandingPage .w-lg-110 {
    width: 110% !important;
  }
  .LandingPage .mw-lg-110 {
    max-width: 110% !important;
  }
  .LandingPage .vh-lg-110 {
    height: 110vh;
  }
  .LandingPage .vw-lg-110 {
    width: 110vw;
  }
  .LandingPage .h-lg-120 {
    height: 120% !important;
  }
  .LandingPage .w-lg-120 {
    width: 120% !important;
  }
  .LandingPage .mw-lg-120 {
    max-width: 120% !important;
  }
  .LandingPage .vh-lg-120 {
    height: 120vh;
  }
  .LandingPage .vw-lg-120 {
    width: 120vw;
  }
  .LandingPage .h-lg-130 {
    height: 130% !important;
  }
  .LandingPage .w-lg-130 {
    width: 130% !important;
  }
  .LandingPage .mw-lg-130 {
    max-width: 130% !important;
  }
  .LandingPage .vh-lg-130 {
    height: 130vh;
  }
  .LandingPage .vw-lg-130 {
    width: 130vw;
  }
  .LandingPage .h-lg-140 {
    height: 140% !important;
  }
  .LandingPage .w-lg-140 {
    width: 140% !important;
  }
  .LandingPage .mw-lg-140 {
    max-width: 140% !important;
  }
  .LandingPage .vh-lg-140 {
    height: 140vh;
  }
  .LandingPage .vw-lg-140 {
    width: 140vw;
  }
  .LandingPage .h-lg-150 {
    height: 150% !important;
  }
  .LandingPage .w-lg-150 {
    width: 150% !important;
  }
  .LandingPage .mw-lg-150 {
    max-width: 150% !important;
  }
  .LandingPage .vh-lg-150 {
    height: 150vh;
  }
  .LandingPage .vw-lg-150 {
    width: 150vw;
  }
}
@media (min-width: 1200px) {
  .LandingPage .h-xl-25 {
    height: 25% !important;
  }
  .LandingPage .w-xl-25 {
    width: 25% !important;
  }
  .LandingPage .mw-xl-25 {
    max-width: 25% !important;
  }
  .LandingPage .vh-xl-25 {
    height: 25vh;
  }
  .LandingPage .vw-xl-25 {
    width: 25vw;
  }
  .LandingPage .h-xl-50 {
    height: 50% !important;
  }
  .LandingPage .w-xl-50 {
    width: 50% !important;
  }
  .LandingPage .mw-xl-50 {
    max-width: 50% !important;
  }
  .LandingPage .vh-xl-50 {
    height: 50vh;
  }
  .LandingPage .vw-xl-50 {
    width: 50vw;
  }
  .LandingPage .h-xl-75 {
    height: 75% !important;
  }
  .LandingPage .w-xl-75 {
    width: 75% !important;
  }
  .LandingPage .mw-xl-75 {
    max-width: 75% !important;
  }
  .LandingPage .vh-xl-75 {
    height: 75vh;
  }
  .LandingPage .vw-xl-75 {
    width: 75vw;
  }
  .LandingPage .h-xl-100 {
    height: 100% !important;
  }
  .LandingPage .w-xl-100 {
    width: 100% !important;
  }
  .LandingPage .mw-xl-100 {
    max-width: 100% !important;
  }
  .LandingPage .vh-xl-100 {
    height: 100vh;
  }
  .LandingPage .vw-xl-100 {
    width: 100vw;
  }
  .LandingPage .h-xl-auto {
    height: auto !important;
  }
  .LandingPage .w-xl-auto {
    width: auto !important;
  }
  .LandingPage .mw-xl-auto {
    max-width: auto !important;
  }
  .LandingPage .vh-xl-auto {
    height: autovh;
  }
  .LandingPage .vw-xl-auto {
    width: autovw;
  }
  .LandingPage .h-xl-110 {
    height: 110% !important;
  }
  .LandingPage .w-xl-110 {
    width: 110% !important;
  }
  .LandingPage .mw-xl-110 {
    max-width: 110% !important;
  }
  .LandingPage .vh-xl-110 {
    height: 110vh;
  }
  .LandingPage .vw-xl-110 {
    width: 110vw;
  }
  .LandingPage .h-xl-120 {
    height: 120% !important;
  }
  .LandingPage .w-xl-120 {
    width: 120% !important;
  }
  .LandingPage .mw-xl-120 {
    max-width: 120% !important;
  }
  .LandingPage .vh-xl-120 {
    height: 120vh;
  }
  .LandingPage .vw-xl-120 {
    width: 120vw;
  }
  .LandingPage .h-xl-130 {
    height: 130% !important;
  }
  .LandingPage .w-xl-130 {
    width: 130% !important;
  }
  .LandingPage .mw-xl-130 {
    max-width: 130% !important;
  }
  .LandingPage .vh-xl-130 {
    height: 130vh;
  }
  .LandingPage .vw-xl-130 {
    width: 130vw;
  }
  .LandingPage .h-xl-140 {
    height: 140% !important;
  }
  .LandingPage .w-xl-140 {
    width: 140% !important;
  }
  .LandingPage .mw-xl-140 {
    max-width: 140% !important;
  }
  .LandingPage .vh-xl-140 {
    height: 140vh;
  }
  .LandingPage .vw-xl-140 {
    width: 140vw;
  }
  .LandingPage .h-xl-150 {
    height: 150% !important;
  }
  .LandingPage .w-xl-150 {
    width: 150% !important;
  }
  .LandingPage .mw-xl-150 {
    max-width: 150% !important;
  }
  .LandingPage .vh-xl-150 {
    height: 150vh;
  }
  .LandingPage .vw-xl-150 {
    width: 150vw;
  }
}
@media (min-width: 576px) {
  .LandingPage .w-cover {
    width: calc(100% + (100vw - 540px) / 2 + 40px);
  }
}
@media (min-width: 768px) {
  .LandingPage .w-cover {
    width: calc(100% + (100vw - 720px) / 2 + 40px);
  }
}
@media (min-width: 992px) {
  .LandingPage .w-cover {
    width: calc(100% + (100vw - 960px) / 2 + 40px);
  }
}
@media (min-width: 1200px) {
  .LandingPage .w-cover {
    width: calc(100% + (100vw - 1040px) / 2 + 40px);
  }
}
.LandingPage .font-size-sm {
  font-size: 0.9375rem !important;
}
.LandingPage .font-size-lg {
  font-size: 1.1875rem !important;
}
@media (max-width: 991.98px) {
  .LandingPage .font-size-lg {
    font-size: 1.0625rem !important;
  }
}
.LandingPage .text-gray-100 {
  color: #f9fbfd !important;
}
.LandingPage .text-gray-200 {
  color: #f1f4f8 !important;
}
.LandingPage .text-gray-300 {
  color: #d9e2ef !important;
}
.LandingPage .text-gray-400 {
  color: #c6d3e6 !important;
}
.LandingPage .text-gray-500 {
  color: #abbcd5 !important;
}
.LandingPage .text-gray-600 {
  color: #869ab8 !important;
}
.LandingPage .text-gray-700 {
  color: #506690 !important;
}
.LandingPage .text-gray-800 {
  color: #384c74 !important;
}
.LandingPage .text-gray-900 {
  color: #1b2a4e !important;
}
.LandingPage .text-white-70 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.LandingPage .text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}
.LandingPage .text-white-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.LandingPage .text-muted-80 {
  color: rgba(134, 154, 184, 0.8) !important;
}
.LandingPage .letter-spacing-lg {
  letter-spacing: 0.08em !important;
}
.LandingPage .opacity-0 {
  opacity: 0 !important;
}
.LandingPage .opacity-1 {
  opacity: 1 !important;
}
.LandingPage [data-aos='img-skewed-item-left'],
.LandingPage [data-aos='img-skewed-item-right'] {
  opacity: 0;
  transition-property: opacity, transform, -webkit-transform;
  will-change: opacity, transform, -webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.LandingPage [data-aos='img-skewed-item-left'].aos-animate,
.LandingPage [data-aos='img-skewed-item-right'].aos-animate {
  opacity: 1;
}
.LandingPage [data-aos='img-skewed-item-left'] {
  -webkit-transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0);
  transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0);
}
.LandingPage [data-aos='img-skewed-item-left'].aos-animate {
  -webkit-transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0);
  transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0);
}
.LandingPage [data-aos='img-skewed-item-right'] {
  -webkit-transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0);
  transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0);
}
.LandingPage [data-aos='img-skewed-item-right'].aos-animate {
  -webkit-transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0);
  transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0);
}
.LandingPage .avatar {
  position: relative;
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 0.625rem;
}
.LandingPage .avatar:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg);
}
.LandingPage .avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.LandingPage .avatar-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #abbcd5;
  color: #ffffff;
}
.LandingPage .avatar-online::before,
.LandingPage .avatar-offline::before {
  content: '';
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border-radius: 50%;
}
.LandingPage .avatar-online .avatar-img,
.LandingPage .avatar-offline .avatar-img {
  -webkit-mask-image: url(../img/masks/avatar-status.svg);
  mask-image: url(../img/masks/avatar-status.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.LandingPage .avatar-online::before {
  background-color: #42ba96;
}
.LandingPage .avatar-offline::before {
  background-color: #abbcd5;
}
.LandingPage .avatar-xs {
  width: 1rem;
  height: 1rem;
  font-size: 0.33333rem;
}
.LandingPage .avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.5rem;
}
.LandingPage .avatar-lg {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem;
}
.LandingPage .avatar-xl {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .LandingPage .avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem;
  }
}
.LandingPage .avatar-xxl {
  width: 4rem;
  height: 4rem;
  font-size: 1.33333rem;
}
@media (min-width: 768px) {
  .LandingPage .avatar-xxl {
    width: 5rem;
    height: 5rem;
    font-size: 1.66667rem;
  }
}
.LandingPage .avatar.avatar-4by3 {
  width: 2.5rem;
}
.LandingPage .avatar-xs.avatar-4by3 {
  width: 1.33333rem;
}
.LandingPage .avatar-sm.avatar-4by3 {
  width: 2rem;
}
.LandingPage .avatar-lg.avatar-4by3 {
  width: 3rem;
}
.LandingPage .avatar-xl.avatar-4by3 {
  width: 5.33333rem;
}
.LandingPage .avatar-xxl.avatar-4by3 {
  width: 6.66667rem;
}
.LandingPage .avatar-group {
  display: -webkit-inline-box;
  display: inline-flex;
}
.LandingPage .avatar-group .avatar + .avatar {
  margin-left: -0.46875rem;
}
.LandingPage .avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.25rem;
}
.LandingPage .avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.375rem;
}
.LandingPage .avatar-group .avatar-lg + .avatar-lg {
  margin-left: -0.5625rem;
}
.LandingPage .avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1rem;
}
.LandingPage .avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -1.25rem;
}
.LandingPage .avatar-group .avatar:not(:last-child) {
  -webkit-mask-image: url(../img/masks/avatar-group.svg);
  mask-image: url(../img/masks/avatar-group.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.LandingPage .avatar-group .avatar:hover {
  -webkit-mask-image: none;
  mask-image: none;
  z-index: 1;
}
.LandingPage .avatar-group .avatar:hover + .avatar {
  -webkit-mask-image: url(../img/masks/avatar-group-hover.svg);
  mask-image: url(../img/masks/avatar-group-hover.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.LandingPage .avatar-group .avatar:hover + .avatar:last-child {
  -webkit-mask-image: url(../img/masks/avatar-group-hover-last.svg);
  mask-image: url(../img/masks/avatar-group-hover-last.svg);
}
.LandingPage .choices::after {
  display: none;
}
.LandingPage .choices .dropdown-menu {
  width: 100%;
}
.LandingPage .choices .form-control-xs ~ .dropdown-menu,
.LandingPage .choices .custom-select-xs ~ .dropdown-menu {
  width: auto;
  min-width: 100%;
  padding: 1rem 1.25rem;
}
.LandingPage .collapse-chevron {
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.LandingPage [aria-expanded='true'] .collapse-chevron {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.LandingPage .device {
  position: relative;
}
.LandingPage .device > .img-fluid {
  position: relative;
}
.LandingPage .device::before {
  content: '';
  background-color: #f9fbfd;
}
.LandingPage .device::before,
.LandingPage .device-screen {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
}
.LandingPage .device-iphonex::before,
.LandingPage .device-iphonex > .device-screen {
  top: 7.784431138%;
  left: 16.4021164%;
  width: 66.137566137%;
  height: 80.838323353%;
}
.LandingPage .device-macbook::before,
.LandingPage .device-macbook > .device-screen {
  top: 11.53846154%;
  left: 13.38709677%;
  width: 73.548387096%;
  height: 73.076923076%;
}
.LandingPage .device-combo {
  position: relative;
}
.LandingPage .device-combo > .device {
  position: absolute;
}
.LandingPage .device-combo-iphonex-iphonex {
  padding-bottom: 130.250482%;
}
.LandingPage .device-combo-iphonex-iphonex > .device-iphonex:first-child {
  bottom: 0;
  left: 0;
  width: 65.5260116%;
  z-index: 1;
}
.LandingPage .device-combo-iphonex-iphonex > .device-iphonex:last-child {
  top: 0;
  right: 0;
  width: 72.8323699%;
}
.LandingPage .device-combo-macbook-iphonex,
.LandingPage .device-combo-iphonex-macbook {
  padding-bottom: 62.4260355%;
}
.LandingPage .device-combo-macbook-iphonex > .device-macbook,
.LandingPage .device-combo-iphonex-macbook > .device-macbook {
  width: 91.7159763%;
}
.LandingPage .device-combo-macbook-iphonex > .device-iphonex,
.LandingPage .device-combo-iphonex-macbook > .device-iphonex {
  width: 27.9585799%;
  z-index: 1;
}
.LandingPage .device-combo-macbook-iphonex > .device-macbook {
  top: 0;
  left: 0;
}
.LandingPage .device-combo-iphonex-macbook > .device-macbook {
  top: 0;
  right: 0;
}
.LandingPage .device-combo-macbook-iphonex > .device-iphonex {
  bottom: 0;
  right: 0;
}
.LandingPage .device-combo-iphonex-macbook > .device-iphonex {
  bottom: 0;
  left: 0;
}
.LandingPage .dropzone {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.LandingPage .dz-message {
  padding: 5rem 1rem;
  background-color: #ffffff;
  border: 1px dashed #c6d3e6;
  border-radius: 0.375rem;
  text-align: center;
  color: #869ab8;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-ordinal-group: 0;
  order: -1;
  cursor: pointer;
  z-index: 999;
}
.LandingPage .dz-message:hover {
  border-color: #869ab8;
  color: #161c2d;
}
.LandingPage .dz-button {
  background: none;
  border: 0;
  font-size: inherit;
  color: inherit;
}
.LandingPage .dz-drag-hover .dz-message {
  border-color: #335eea;
  color: #335eea;
}
.LandingPage .dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.LandingPage .dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(22, 28, 45, 0.9);
  color: white;
  opacity: 0;
}
.LandingPage .dropzone-single.dz-max-files-reached .dz-message:hover {
  opacity: 1;
}
.LandingPage .dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.375rem;
}
.LandingPage .dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.375rem;
}
.LandingPage .dz-preview-img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
}
.LandingPage .dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.LandingPage [data-dz-size] strong {
  font-weight: 400;
}
.LandingPage body.compensate-for-scrollbar {
  margin-right: 0 !important;
}
.LandingPage .fancybox-container .fancybox-bg {
  background-color: rgba(255, 255, 255, 0.97);
}
.LandingPage .fe-lg {
  font-size: 1.3125rem;
}
@media (min-width: 768px) {
  .LandingPage .fe-lg {
    font-size: 1.125rem;
  }
}
.LandingPage .flickity-button {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: calc(1.6em + 1.625rem + 2px);
  height: calc(1.6em + 1.625rem + 2px);
  background-color: #335eea;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05);
  color: #ffffff;
}
.LandingPage .flickity-button::before,
.LandingPage .flickity-button::after {
  font-family: 'Feather';
}
.LandingPage .flickity-button:hover,
.LandingPage .flickity-button:focus {
  background-color: #335eea;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
}
.LandingPage .flickity-button.previous {
  left: 0;
  -webkit-transform: translate(calc(-50% + 10px), -50%);
  transform: translate(calc(-50% + 10px), -50%);
}
@media (min-width: 768px) {
  .LandingPage .flickity-button.previous {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.LandingPage .flickity-button.previous::before {
  content: '\e910';
}
.LandingPage .flickity-button.next {
  right: 0;
  -webkit-transform: translate(calc(50% - 10px), -50%);
  transform: translate(calc(50% - 10px), -50%);
}
@media (min-width: 768px) {
  .LandingPage .flickity-button.next {
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
  }
}
.LandingPage .flickity-button.next::before {
  content: '\e912';
}
.LandingPage .flickity-button-icon {
  display: none;
}
.LandingPage .flickity-button-white .flickity-button {
  background-color: #ffffff;
  color: #335eea;
}
.LandingPage .flickity-button-bottom .flickity-button {
  top: auto;
  bottom: 1.5rem;
  -webkit-transform: none;
  transform: none;
}
.LandingPage .flickity-button-bottom .flickity-button.previous {
  left: auto;
  right: 6.375rem;
}
.LandingPage .flickity-button-bottom .flickity-button.next {
  left: auto;
  right: 2.5rem;
}
.LandingPage .flickity-button-inset .flickity-button {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.LandingPage .flickity-button-inset .flickity-button.previous {
  left: 1.5rem;
}
.LandingPage .flickity-button-inset .flickity-button.next {
  right: 1.5rem;
}
.LandingPage .flickity-viewport-visible .flickity-viewport {
  overflow: visible;
}
.LandingPage [data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.LandingPage .bg-dark .footer-brand {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.LandingPage .hljs {
  background-color: transparent;
}
.LandingPage .icon > svg {
  width: 3rem;
  height: 3rem;
}
.LandingPage .icon[class*='text-'] > svg [fill]:not([fill='none']) {
  fill: currentColor !important;
}
.LandingPage .icon-xs > svg {
  width: 1.5rem;
  height: 1.5rem;
}
.LandingPage .icon-sm > svg {
  width: 2.25rem;
  height: 2.25rem;
}
.LandingPage .icon-lg > svg {
  width: 4rem;
  height: 4rem;
}
.LandingPage .icon-xl > svg {
  width: 5rem;
  height: 5rem;
}
.LandingPage .icon-circle {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 4.1875rem;
  height: 4.1875rem;
  border-radius: 50%;
}
.LandingPage .icon-circle > .fe {
  font-size: 1.875rem;
}
.LandingPage .list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.LandingPage .list-item + .list-item {
  margin-top: 0.35rem;
}
.LandingPage .list-link {
  display: -webkit-box;
  display: flex;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.9375rem;
  color: #506690;
}
.LandingPage .list-link:hover,
.LandingPage .list-link:focus {
  text-decoration: none;
  color: #335eea;
}
.LandingPage .ql-container {
  font-family: 'HKGroteskPro', serif;
}
.LandingPage .ql-toolbar {
  position: relative;
  padding: 0.8125rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem 0.375rem 0 0;
  color: #161c2d;
}
.LandingPage .ql-toolbar + .ql-container {
  margin-top: -1px;
}
.LandingPage .ql-toolbar + .ql-container .ql-editor {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.LandingPage .ql-editor {
  min-height: 6.8rem;
  display: block;
  width: 100%;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  color: #161c2d;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .ql-editor {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .ql-editor::-ms-expand {
  background-color: transparent;
  border: 0;
}
.LandingPage .ql-editor:focus {
  color: #161c2d;
  background-color: #ffffff;
  border-color: #a7b9f6;
  outline: 0;
}
.LandingPage .ql-hidden {
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.LandingPage .ql-editor.ql-blank::before {
  top: 0.8125rem;
  left: 1.25rem;
  font-style: normal;
  color: #869ab8;
}
.LandingPage .ql-editor:focus::before {
  display: none;
}
.LandingPage .ql-formats {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.LandingPage .ql-formats:first-child {
  padding-left: 0;
}
.LandingPage .ql-formats:last-child {
  padding-right: 0;
}
.LandingPage .ql-toolbar button {
  padding: 0 0.25rem;
  background: none;
  border: none;
  color: #161c2d;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.LandingPage .ql-toolbar button:hover {
  color: #335eea;
}
.LandingPage .ql-toolbar button:first-child {
  margin-left: -0.25rem;
}
.LandingPage .ql-toolbar .ql-active {
  color: #335eea;
}
.LandingPage .ql-toolbar button svg {
  height: 1.1875rem;
  width: 1.1875rem;
}
.LandingPage .ql-toolbar .ql-stroke {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.LandingPage .ql-toolbar .ql-thin {
  stroke-width: 1;
}
.LandingPage .ql-toolbar .ql-fill {
  fill: currentColor;
}
.LandingPage .ql-toolbar input.ql-image {
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.LandingPage .ql-tooltip {
  position: absolute;
  z-index: 1070;
  display: -webkit-box;
  display: flex;
  flex-wrap: nowrap;
  width: 18.5rem;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  padding: 0.8125rem 1.25rem;
  margin-top: 0.6rem;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
}
.LandingPage .ql-tooltip:before,
.LandingPage .ql-tooltip:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.LandingPage .ql-tooltip:before {
  border-bottom: 0.6rem solid transparent;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
}
.LandingPage .ql-tooltip:after {
  border-bottom: 0.5rem solid #ffffff;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}
.LandingPage .ql-container .ql-tooltip:hover {
  display: -webkit-box !important;
  display: flex !important;
}
.LandingPage .ql-tooltip .ql-preview {
  width: 100%;
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .ql-tooltip .ql-preview {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .ql-tooltip.ql-editing .ql-preview {
  display: none;
}
.LandingPage .ql-tooltip input {
  display: none;
  width: 100%;
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.6;
  border: none;
  color: #161c2d;
}
.LandingPage .ql-tooltip input:focus {
  outline: none;
}
.LandingPage .ql-tooltip.ql-editing input {
  display: block;
}
.LandingPage .ql-tooltip .ql-action,
.LandingPage .ql-tooltip .ql-remove {
  margin-left: 0.25rem;
}
.LandingPage .ql-tooltip .ql-action::before,
.LandingPage .ql-tooltip .ql-remove::before {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .LandingPage .ql-tooltip .ql-action::before,
  .LandingPage .ql-tooltip .ql-remove::before {
    -webkit-transition: none;
    transition: none;
  }
}
.LandingPage .ql-tooltip .ql-action::before:hover,
.LandingPage .ql-tooltip .ql-action::before:focus,
.LandingPage .ql-tooltip .ql-remove::before:hover,
.LandingPage .ql-tooltip .ql-remove::before:focus {
  text-decoration: none;
}
.LandingPage .ql-tooltip .ql-action::before:focus,
.LandingPage .ql-tooltip .ql-action::before.focus,
.LandingPage .ql-tooltip .ql-remove::before:focus,
.LandingPage .ql-tooltip .ql-remove::before.focus {
  outline: 0;
  box-shadow: none;
}
.LandingPage .ql-tooltip .ql-action::before,
.LandingPage .ql-tooltip.ql-editing .ql-action::before {
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
  box-shadow: none;
}
.LandingPage .ql-tooltip .ql-action::before:hover,
.LandingPage .ql-tooltip.ql-editing .ql-action::before:hover {
  color: #ffffff;
  background-color: #1746e0;
  border-color: #1643d4;
}
.LandingPage .ql-tooltip .ql-action::before:focus,
.LandingPage .ql-tooltip .ql-action::before.focus,
.LandingPage .ql-tooltip.ql-editing .ql-action::before:focus,
.LandingPage .ql-tooltip.ql-editing .ql-action::before.focus {
  color: #ffffff;
  background-color: #1746e0;
  border-color: #1643d4;
  box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5);
}
.LandingPage .ql-tooltip .ql-action::before.disabled,
.LandingPage .ql-tooltip .ql-action::before:disabled,
.LandingPage .ql-tooltip.ql-editing .ql-action::before.disabled,
.LandingPage .ql-tooltip.ql-editing .ql-action::before:disabled {
  color: #ffffff;
  background-color: #335eea;
  border-color: #335eea;
}
.LandingPage .ql-tooltip .ql-action::before:not(:disabled):not(.disabled):active,
.LandingPage .ql-tooltip .ql-action::before:not(:disabled):not(.disabled).active,
.show > .LandingPage .ql-tooltip .ql-action::before.dropdown-toggle,
.LandingPage .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled):active,
.LandingPage .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled).active,
.show > .LandingPage .ql-tooltip.ql-editing .ql-action::before.dropdown-toggle {
  color: #ffffff;
  background-color: #1643d4;
  border-color: #153fc9;
}
.LandingPage .ql-tooltip .ql-action::before:not(:disabled):not(.disabled):active:focus,
.LandingPage .ql-tooltip .ql-action::before:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .ql-tooltip .ql-action::before.dropdown-toggle:focus,
.LandingPage .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled):active:focus,
.LandingPage .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .ql-tooltip.ql-editing .ql-action::before.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5);
}
.LandingPage .ql-tooltip .ql-action::before {
  content: 'Edit';
}
.LandingPage .ql-tooltip.ql-editing .ql-action::before {
  content: 'Save';
}
.LandingPage .ql-tooltip .ql-remove::before {
  color: #1b2a4e;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: none;
  content: 'Remove';
  border-color: #d9e2ef;
}
.LandingPage .ql-tooltip .ql-remove::before:hover {
  color: #1b2a4e;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.LandingPage .ql-tooltip .ql-remove::before:focus,
.LandingPage .ql-tooltip .ql-remove::before.focus {
  color: #1b2a4e;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5);
}
.LandingPage .ql-tooltip .ql-remove::before.disabled,
.LandingPage .ql-tooltip .ql-remove::before:disabled {
  color: #1b2a4e;
  background-color: #ffffff;
  border-color: #ffffff;
}
.LandingPage .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled):active,
.LandingPage .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled).active,
.show > .LandingPage .ql-tooltip .ql-remove::before.dropdown-toggle {
  color: #1b2a4e;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.LandingPage .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled):active:focus,
.LandingPage .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled).active:focus,
.show > .LandingPage .ql-tooltip .ql-remove::before.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5);
}
.LandingPage .ql-tooltip.ql-editing .ql-remove::before {
  display: none;
}
.LandingPage .ql-editor blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.LandingPage .ql-editor img {
  max-width: 100%;
  height: auto;
}
.LandingPage .screenshot {
  border-radius: 0.625rem;
  box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1), 16px 40px 75px -40px rgba(0, 0, 0, 0.2);
}
.LandingPage .section-border {
  position: relative;
}
.LandingPage .section-border::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-width: 2px 0 0 0;
  border-style: solid;
  border-color: inherit;
  content: '';
}
.LandingPage .shape {
  position: absolute;
  pointer-events: none;
}
.LandingPage .shape > * {
  display: block;
}
.LandingPage .shape:not([class*='shape-blur']) {
  overflow: hidden;
}
.LandingPage .shape:not([class*='shape-blur']) > * {
  -webkit-transform: scale(2);
  transform: scale(2);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .LandingPage .shape > svg {
    display: none;
  }
}
.LandingPage .shape-top {
  top: 0;
  right: 0;
  left: 0;
}
.LandingPage .shape-top > * {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}
.LandingPage .shape-right {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.LandingPage .shape-right > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  right: inherit;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.LandingPage .shape-bottom {
  right: 0;
  bottom: 0;
  left: 0;
}
.LandingPage .shape-bottom > * {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
.LandingPage .shape-bottom-100 {
  right: 0;
  bottom: 100%;
  left: 0;
}
.LandingPage .shape-bottom-100 > * {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
.LandingPage .shape-left {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.LandingPage .shape-left > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  left: inherit;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.LandingPage .shape-fluid-x > * {
  width: 100%;
  height: auto;
}
.LandingPage .shape-fluid-y > * {
  width: auto;
  height: 100%;
}
.LandingPage .shape-flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.LandingPage .shape-blur-1 {
  top: 0;
  right: 0;
  left: 0;
}
@media (min-width: 768px) {
  .LandingPage .shape-blur-1 {
    bottom: 0;
    left: 50%;
    right: auto;
  }
}
.LandingPage .shape-blur-1 > * {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .LandingPage .shape-blur-1 > * {
    width: auto;
    height: 100%;
  }
}
.LandingPage .shape-blur-2 {
  top: 0;
  right: 0;
  left: 0;
}
@media (min-width: 768px) {
  .LandingPage .shape-blur-2 {
    bottom: 0;
    right: 50%;
    left: auto;
  }
}
.LandingPage .shape-blur-2 > * {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .LandingPage .shape-blur-2 > * {
    width: auto;
    height: 100%;
    float: right;
  }
}
.LandingPage .shape-blur-3 {
  top: 0;
  right: 0;
  left: 0;
}
.LandingPage .shape-blur-3 > * {
  width: 100%;
  height: auto;
}
.LandingPage .shape-blur-4 {
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%) scale(1.3);
  transform: translateX(-50%) scale(1.3);
}
.LandingPage .shape-blur-4 > * {
  width: auto;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .LandingPage .sidenav {
    position: fixed;
    top: 84px;
    height: calc(100vh - 84px);
    overflow: auto;
  }
}
@media (min-width: 992px) {
  .LandingPage .sidenav-left {
    left: 0;
  }
}
@media (min-width: 992px) {
  .LandingPage .sidenav-right {
    right: 0;
  }
}
