.sigCanvas {
  border: 1px solid #ccc;
  border-radius: 5px;
}

@font-face {
  font-family: 'HKGroteskPro';
  font-weight: 400;
  src: url(./assets/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff2) format('woff2'), url(./assets/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff) format('woff');
}

@font-face {
  font-family: 'HKGroteskPro';
  font-weight: 600;
  src: url(./assets/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff2) format('woff2'), url(./assets/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff) format('woff');
}

@font-face {
  font-family: 'HKGroteskPro';
  font-weight: 700;
  src: url(./assets/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Bold.woff2) format('woff2'), url(./assets/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Bold.woff) format('woff');
}

body {
  font-family: 'HKGroteskPro';
}

.noteFile {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
  padding: 5px;
  margin: 5px 0;
  width: min-content;
  white-space: nowrap;
  cursor: pointer;
}
